import React, { useState, useEffect, useRef } from 'react';

interface TypingEffectProps {
    message: string;
    tweetType: string;
}

const TypingEffect: React.FC<TypingEffectProps> = ({ message, tweetType }) => {
    const [lines, setLines] = useState<string[]>([]);
    const [text, setText] = useState('');
    const [typing, setTyping] = useState(true);
    const typingSpeed = 10;
    const intervalRef = useRef<number | undefined>();

    useEffect(() => {
        let currentText = '';
        let currentIndex = 0;

        const updateText = () => {
            if (currentIndex < message.length) {
                currentText += message.charAt(currentIndex);

                if (tweetType === 'tweet-thread') {
                    // const newLines = currentText.split(/\d+\//).filter((line) => line.trim() !== '');
                    // const newLines = currentText.split(/\d+\//).filter((line) => line.trim() !== '');
                    const newLines = currentText.split('\n');
                    setLines(newLines);
                } else if (tweetType === 'long-tweet') {
                    const newLines = currentText.split('\n');
                    setLines(newLines);
                } else {
                    setText(currentText);
                }

                currentIndex += 1;
            } else {
                setTyping(false);
                clearInterval(intervalRef.current);
            }
        };

        setTyping(true);
        intervalRef.current = window.setInterval(updateText, typingSpeed);

        // Cleanup the interval when the component unmounts or when the message prop changes
        return () => {
            clearInterval(intervalRef.current);
        };
    }, [message, tweetType]);

    useEffect(() => {
        // Add a small delay before resetting text to allow the component to render
        const delayTimeout = window.setTimeout(() => {
            if (tweetType === 'tweet-thread' || tweetType === 'long-tweet') {
                setLines([]);
            } else {
                setText('');
            }
            setTyping(true);
        }, 500); // Adjust the delay as needed

        // Cleanup the delay timeout when the component unmounts or when the message prop changes
        return () => {
            clearTimeout(delayTimeout);
        };
    }, [message, tweetType]);

    return (
        <div className='generated-tweet-text'>
            {tweetType === 'tweet-thread' || tweetType === 'long-tweet' ? (
                lines.map((line, index) => <div key={index}>{line}</div>)
            ) : (
                <div>{text}</div>
            )}
        </div>
    );
};

export default TypingEffect;