import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const NotificationPermissionModal = ({ show, onEnable, onCancel }) => {
    return (
        <Modal className='notification-permission-modal' show={show} onHide={onCancel} backdrop="static" keyboard={false} centered>
            <Modal.Header closeButton>
                <Modal.Title>Stay Updated!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Would you like to enable notifications to stay updated with the latest content?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onCancel}>
                    Later
                </Button>
                <Button className='enable-notification-btn' onClick={onEnable}>
                    Enable Notifications
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default NotificationPermissionModal;
