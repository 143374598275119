import React, { createContext, useContext, useState, ReactNode, useCallback } from 'react';
import { useAccount } from 'wagmi'
import toast, { Toaster } from 'react-hot-toast';

type DepositType = {
    deposit_amount: number;
    deposited_on: string;
    transaction_hash: string;
    // Add any other properties relevant to a deposit
};

type PublicMetricsType = {
    followers_count:number;
}

type WatchlistItemType = {
    data: string;
    watchlist_id: string;
    name:string;
    profile_image_url:string;
    public_metrics: PublicMetricsType;
    token_name:string;
    token_symbol:string;
    token_sniffer_url:string;
    dexscreen_url:string;
    image_url:string;
    username:string;
    id:string;
};

// Define a type for the user data
type UserType = {
    // Add properties according to your user data structure
    wallet_id: string;
    joined_on: string;
    credit: number;
    active: boolean;
    deposit: Array<DepositType>;
    telegram_id: string;
    admin:boolean;
    // etc...
};

// Define a type for the context
type UserContextType = {
    user: UserType | null;
    setUser: (user: UserType | null) => void;
    addToWatchlist: (type: string, tokenId: string, wallet_address: string) => Promise<void>;
    removeFromWatchlist: (type: string, watchlistId: string, wallet_address: string) => Promise<void>;
    fetchWatchlist: (type: string, wallet_address: string) => Promise<void>;
    watchlistToken: WatchlistItemType[]; // Updated to be an array
    setWatchlistToken: (items: WatchlistItemType[]) => void; // Updated for an array
    watchlistInfluencer: WatchlistItemType[]; // Updated to be an array
    setWatchlistInfluencer: (items: WatchlistItemType[]) => void; // Updated for an array
};


// Create the context with a default value and type
const UserContext = createContext<UserContextType | null>(null);

export const useUser = () => {
    const context = useContext(UserContext);
    if (context === null) {
        throw new Error('useUser must be used within a UserProvider');
    }
    return context;
};

type UserProviderProps = {
    children: ReactNode;
};

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
    const { address, isConnected } = useAccount()
    const [user, setUser] = useState<UserType | null>(null);
    const [watchlistToken, setWatchlistToken] = useState<WatchlistItemType[]>([]);
    const [watchlistInfluencer, setWatchlistInfluencer] = useState<WatchlistItemType[]>([]);


    const fetchWatchlist = async (type: string, wallet_address: string) => {

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}watchlist/?wallet_id=${wallet_address}&type=${type}`);
            if (!response.ok) throw new Error('Failed to fetch the watchlist');

            const watchlistData = await response.json();

            if (type == 'Influencer') {
                setWatchlistInfluencer(watchlistData['data'])
            } else {
                setWatchlistToken(watchlistData['data'])
            }

        } catch (error) {
            console.error("Error fetching watchlist:", error);
        }
    };



    const addToWatchlist = useCallback(async (type: string, data: string, wallet_address: string) => {
        const addWatchlistPromise = fetch(`${process.env.REACT_APP_API_URL}watchlist/?wallet_id=${wallet_address}&type=${type}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                data: data, // Assuming your API expects the token symbol in the request body
            }),
        })
            .then(response => {
                if (!response.ok) {
                    // Convert non-2xx HTTP responses into errors
                    throw new Error('Failed to add item to watchlist');
                }
                return response.json(); // Parse JSON response
            });

        toast.promise(
            addWatchlistPromise,
            {
                loading: 'Adding item to watchlist...',
                success: 'Item successfully added to watchlist🎉',
                error: (err) => `Failed to add item to watchlist: ${err.toString()}`,
            }, {
            style: {
                background: 'rgba(0, 29, 255, 0.20)',
                color: '#fff'
            }
        }
        );

        // Optionally, handle the promise's result for further actions
        addWatchlistPromise.then(() => {
            // Handle success, e.g., fetch updated watchlist
            fetchWatchlist(type, wallet_address);
        }).catch(error => {
            // Error handling if needed, though toast.promise handles the display
            console.error("Error in addToWatchlist:", error);
        });

    }, []);




    const removeFromWatchlist = useCallback((type: string, watchlistId: string, wallet_address: string): Promise<void> => {
        // Create a promise for the fetch operation
        const removePromise = fetch(`${process.env.REACT_APP_API_URL}watchlist/?wallet_id=${wallet_address}&watchlist_id=${watchlistId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                // Include other headers as required
            },
            // No need to send a body with a DELETE request
        })
            .then(response => {
                if (!response.ok) {
                    // Convert non-2xx HTTP responses into errors
                    throw new Error('Failed to remove item from watchlist');
                }
                return response.json(); // Assuming DELETE returns a JSON response, adjust as needed
            });

        // Use toast.promise to handle the promise and show appropriate toasts
        return toast.promise(
            removePromise,
            {
                loading: 'Removing item from watchlist...',
                success: 'Item successfully removed from watchlist🗑️',
                error: (err) => `Failed to remove item from watchlist: ${err.toString()}`,
            }, {
                style: {
                    background: 'rgba(0, 29, 255, 0.20)',
                    color: '#fff'
                }
        }
        ).then(() => {
            // Optionally handle further success actions, such as fetching the updated watchlist
            return fetchWatchlist(type, wallet_address);
        })
        // No need for a catch here, as toast.promise handles the rejection by displaying the error toast
    }, []);


    const value = { user, setUser, watchlistToken, setWatchlistToken, watchlistInfluencer, setWatchlistInfluencer, addToWatchlist, removeFromWatchlist, fetchWatchlist };

    return <UserContext.Provider value={value}>  <Toaster />{children}</UserContext.Provider>;

};

export default UserProvider;