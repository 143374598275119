import React, { useState, useRef, useEffect } from 'react';
import { Row, Col, Card, Form, Table, Spinner, OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import toast, { Toaster } from 'react-hot-toast';
import { useAccount } from 'wagmi'
import * as Icon from 'react-bootstrap-icons';
import Select, { ActionMeta, MultiValue } from 'react-select';

interface Influencers {
    wallet_id: string;
    user_id: string;
    tag_ids: string[];
    group_ids: string[];
    created_on: string;
}


interface FormData {
    names: string[];
    tag_ids: string[];
    group_ids: string[];
}


interface SelectUserGroupOption {
    value: string;
    label: string;
}

interface SelectUserTagOption {
    value: string;
    label: string;
}

interface SelectInfluencerOption {
    value: string;
    label: string;
}


const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return isMobile;
};



export const BotInfluencers = () => {

    const { address, isConnected } = useAccount()
    const isMobile = useIsMobile();
    const [allInfluencerloader, setAllInfluencerloader] = useState(false);
    const [allInfluencers, setAllInfluencers] = useState<Influencers[]>([]);
    // const [userGroups, setUserGroups] = useState<SelectUserGroupOption[]>([]);
    const [showInfluencerCreateModal, setShowInfluencerCreateModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState<FormData>({
        names: [],
        tag_ids: [],
        group_ids: []
    });
    const [userGroupName, setUserGroupName] = useState('');
    const [userTagName, setUserTagName] = useState('');
    const [showAddInfluencerForm, setShowAddInfluencerForm] = useState(true);
    const [showAddUserGroupForm, setShowAddUserGroupForm] = useState(false);
    const [showAddUserTagForm, setShowAddUserTagForm] = useState(false);
    const [optionsUserGroup, setOptionsUserGroup] = useState<SelectUserGroupOption[]>([]);
    const [optionsUserTag, setOptionsUserTag] = useState<SelectUserTagOption[]>([]);
    const [optionsInfluencers, setOptionsInfluencers] = useState<SelectInfluencerOption[]>([]);



    const get_all_influencer = async () => {
        setAllInfluencerloader(true);
        setAllInfluencers([]);
        const walletIdParam = isConnected ? `&wallet_id=${address}` : '';

        const url = `${process.env.REACT_APP_API_URL}shill/users?wallet_id=${walletIdParam}`;

        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    // Include any other headers if needed
                }),
            });

            if (response.ok) {
                const data = await response.json();
                setAllInfluencers(data['data']);
            } else {
                setAllInfluencers([]);
            }
        } catch (e) {
            console.log(e);
            setAllInfluencerloader(false);
            setAllInfluencers([]);
        } finally {
            setAllInfluencerloader(false);
        }
    };

    const get_all_token_filter = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "home/filters?time_window=1month", {
                method: "GET",
                mode: "cors",
                headers: new Headers({ "content-type": "application/json" })
            });
            if (response.status === 200) {
                const data = await response.json();
                 // setUserGroups(data['data'])
                 const formattedOptions = data['filters']['influencer'].map((influencer: any) => {
                    return {
                        value: influencer.user_username,
                        label: influencer.user_username,
                    };
                });
                setOptionsInfluencers(formattedOptions)
            } else {
                console.log(response.status);
            }
        } catch (e: any) {
            console.log(e);
        }
    }

    const get_user_groups = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "/shill/user-groups?wallet_id=" + address, {
                method: "GET",
                mode: "cors",
                headers: new Headers({ "content-type": "application/json" })
            });
            if (response.status === 200) {
                const data = await response.json();
                // setUserGroups(data['data'])
                const formattedOptions = data['data'].map((user_group: any) => {

                    return {
                        value: user_group.id,
                        label: user_group.name,
                    };
                });
                // Prepend the "empty field" option
                setOptionsUserGroup([{ value: '', label: 'Select a user group' }, ...formattedOptions]);
            } else {
                console.log(response.status);
            }
        } catch (e: any) {
            console.log(e);
        }
    }

    const get_user_tags = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "/shill/user-tags?wallet_id=" + address, {
                method: "GET",
                mode: "cors",
                headers: new Headers({ "content-type": "application/json" })
            });
            if (response.status === 200) {
                const data = await response.json();
                // setUserGroups(data['data'])
                const formattedOptions = data['data'].map((user_tag: any) => {

                    return {
                        value: user_tag.id,
                        label: user_tag.name,
                    };
                });
                // Prepend the "empty field" option
                setOptionsUserTag([{ value: '', label: 'Select a user tag' }, ...formattedOptions]);
            } else {
                console.log(response.status);
            }
        } catch (e: any) {
            console.log(e);
        }
    }

    const handleNewInfluencerFormChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { id, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [id]: value
        }));
    };


    const edit_influencer = () => {
    }

    const delete_influencer = () => {
    }

    const addNewUserGroup = () => {
        setShowAddInfluencerForm(false)
        setShowAddUserTagForm(false)
        setShowAddUserGroupForm(true)
    }

    const addNewUserTag = () => {
        setShowAddInfluencerForm(false)
        setShowAddUserGroupForm(false)
        setShowAddUserTagForm(true)
    }

    const handleCreateNewInfluencerClick = () => {
        setLoading(false)
        setFormData({
            names: [],
            tag_ids: [],
            group_ids: []
        })
        setShowAddUserGroupForm(false);
        setShowAddUserTagForm(false);
        setShowAddInfluencerForm(true);
        setShowInfluencerCreateModal(true);
    }

    const handleCreateInfluencer = () => {
        if (formData) {
            setLoading(true);
            const url = `${process.env.REACT_APP_API_URL}shill/users?wallet_id=${address}`;
            const payload = {
                ...formData
            };

            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    return response.json();
                })
                .then((data) => {
                    console.log('Success:', data);
                    setLoading(false);
                    setShowInfluencerCreateModal(false);
                    toast.success('Influencer created successfully', {
                        style: {
                            background: 'rgba(0, 29, 255, 0.20)',
                            color: '#fff',
                        },
                    });
                    get_all_influencer();
                })
                .catch((error) => {
                    console.error('Error:', error);
                    setLoading(false);
                    if (error.message.includes('500')) {
                        toast.error('Internal Server Error (500): Failed to create influencer', {
                            style: {
                                background: 'rgba(255, 0, 0, 0.20)',
                                color: '#fff',
                            },
                        });
                    } else {
                        toast.error('Failed to create influencer', {
                            style: {
                                background: 'rgba(255, 0, 0, 0.20)',
                                color: '#fff',
                            },
                        });
                    }
                });
        }
    }

    const handleCreateUserGroup = () => {

        if (userGroupName) {
            setLoading(true);
            const url = `${process.env.REACT_APP_API_URL}shill/user-groups?wallet_id=${address}`;
            const payload = {
                "name": userGroupName
            };

            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    return response.json();
                })
                .then((data) => {
                    console.log('Success:', data);
                    setLoading(false);
                    setShowAddUserGroupForm(false)
                    setShowAddUserTagForm(false)
                    setShowAddInfluencerForm(true)
                    toast.success('User Group created successfully', {
                        style: {
                            background: 'rgba(0, 29, 255, 0.20)',
                            color: '#fff',
                        },
                    });
                    get_user_groups();
                })
                .catch((error) => {
                    console.error('Error:', error);
                    setLoading(false);
                    if (error.message.includes('500')) {
                        toast.error('Internal Server Error (500): Failed to create user group', {
                            style: {
                                background: 'rgba(255, 0, 0, 0.20)',
                                color: '#fff',
                            },
                        });
                    } else {
                        toast.error('Failed to create user group', {
                            style: {
                                background: 'rgba(255, 0, 0, 0.20)',
                                color: '#fff',
                            },
                        });
                    }
                });
        }

    }

    const handleCreateUserTag = () => {
        if (userTagName) {
            setLoading(true);
            const url = `${process.env.REACT_APP_API_URL}shill/user-tags?wallet_id=${address}`;
            const payload = {
                "name": userTagName
            };

            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    return response.json();
                })
                .then((data) => {
                    console.log('Success:', data);
                    setLoading(false);
                    setShowAddUserGroupForm(false)
                    setShowAddUserTagForm(false)
                    setShowAddInfluencerForm(true)
                    toast.success('User Tag created successfully', {
                        style: {
                            background: 'rgba(0, 29, 255, 0.20)',
                            color: '#fff',
                        },
                    });
                    get_user_tags();
                })
                .catch((error) => {
                    console.error('Error:', error);
                    setLoading(false);
                    if (error.message.includes('500')) {
                        toast.error('Internal Server Error (500): Failed to create user tag', {
                            style: {
                                background: 'rgba(255, 0, 0, 0.20)',
                                color: '#fff',
                            },
                        });
                    } else {
                        toast.error('Failed to create user tag', {
                            style: {
                                background: 'rgba(255, 0, 0, 0.20)',
                                color: '#fff',
                            },
                        });
                    }
                });
        }
    }

    const handleUserNameChange = (selectedOptions: MultiValue<{ value: string, label: string }>) => {
        const names = selectedOptions ? selectedOptions.map(option => option.value) : [];
        setFormData(prevState => ({
            ...prevState,
            names: names, // Update form data with selected group IDs
        }));
    };

    const handleUserGroupChange = (selectedOptions: MultiValue<{ value: string, label: string }>) => {
        const groupIds = selectedOptions ? selectedOptions.map(option => option.value) : [];
        setFormData(prevState => ({
            ...prevState,
            group_ids: groupIds, // Update form data with selected group IDs
        }));
    };

    const handleUserTagChange = (selectedOptions: MultiValue<{ value: string, label: string }>) => {
        const tagIds = selectedOptions ? selectedOptions.map(option => option.value) : [];
        setFormData(prevState => ({
            ...prevState,
            tag_ids: tagIds, // Update form data with selected group IDs
        }));
    };


    useEffect(() => {
        get_all_influencer()
        get_user_groups()
        get_user_tags()
        get_all_token_filter()
    }, []);

    return (
        <>
            <div className="container-fluid">
                <Toaster />
                <section className="section-padding position-relative mt-4">
                    <div className="container-fluid">
                        <Row>
                            <Col sm={12}>
                                <div className='me-auto'>
                                    <div className="s-heading">
                                        Influencer List
                                    </div>

                                </div>
                            </Col>
                            <Col sm={3} className="mt-3">
                                <div className='ctw-btn mt-2' onClick={handleCreateNewInfluencerClick}>

                                    Create Influencer
                                </div>
                            </Col>

                            <Col sm={12} className='mt-3'>

                                {allInfluencerloader &&
                                    <Spinner animation="border" role="status" variant="light" >
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                }
                                {!allInfluencerloader &&
                                    <>
                                        <div className={isMobile ? 'atw-table-container table-responsive-md' : 'atw-table-container'}>
                                            <Table className='atw-table'>
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Wallet Id</th>
                                                        <th>User Id</th>
                                                        <th>Tag Ids</th>
                                                        <th>Group Ids</th>
                                                        <th>Created On</th>
                                                        <th></th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {allInfluencers.length > 0 ? (
                                                        allInfluencers.map((influencer, index) => (
                                                            <tr key={index} className={allInfluencers.length === 1 ? 'custom-single-row-height' : ''}>
                                                                <td width={'2%'}>
                                                                    <div className='d-flex align-items-center gap-2'>
                                                                        {index + 1}
                                                                    </div>
                                                                </td>
                                                                <td width={'30%'} className='cursor-pointer'>
                                                                    {influencer['wallet_id']}
                                                                </td>
                                                                <td width={'10%'}>{influencer['user_id']}</td>
                                                                <td width={'18%'}>
                                                                    {influencer['tag_ids']}
                                                                </td>
                                                                <td width={'10%'}>
                                                                    {influencer['group_ids']}
                                                                </td>
                                                                <td width={'10%'}>
                                                                    {influencer['created_on']}
                                                                </td>
                                                                <td width={'5%'}>
                                                                    <div className="ctw-btn" onClick={() => edit_influencer()}>
                                                                        Edit
                                                                    </div>
                                                                </td>
                                                                <td width={'5%'}>
                                                                    <div className="ctw-btn" onClick={() => delete_influencer()}>
                                                                        Delete
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        // Render this row if allTokenTweets is empty
                                                        <tr>
                                                            <td colSpan={7} style={{ textAlign: 'center' }}>No data available</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </Table>
                                        </div>





                                        <Modal className='tweet-modal' show={showInfluencerCreateModal} onHide={() => setShowInfluencerCreateModal(false)} centered>
                                            <Modal.Header className="justify-content-between">
                                                <div className='update-modal-heading'>
                                                    {showAddInfluencerForm &&
                                                        "Create New Influencer"
                                                    }
                                                    {showAddUserGroupForm &&
                                                        "Create New User Group"
                                                    }
                                                    {showAddUserTagForm &&
                                                        "Create New User Tag"
                                                    }
                                                </div>
                                                <Icon.XCircle size={30} className='modal-close-icon' onClick={() => setShowInfluencerCreateModal(false)}></Icon.XCircle>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <div className="row">
                                                    {showAddInfluencerForm &&
                                                        <div className="col-md-12">
                                                            <label className="token-description-label">Name:</label>
                                                            <Select
                                                                id="user_name"
                                                                name="user_name"
                                                                options={optionsInfluencers}
                                                                isMulti
                                                                value={optionsInfluencers.filter(option => formData.names.includes(option.value)) || null}
                                                                onChange={handleUserNameChange}
                                                                onFocus={() => {
                                                                    // Clear the selected values on focus
                                                                    handleUserNameChange([]);
                                                                }}
                                                                className="ticker-text-box"
                                                                classNamePrefix="admin-select"
                                                            />
                                                            {/* <input
                                                                id="name"
                                                                className="form-control mt-2 ticker-text-box"
                                                                value={formData.names}
                                                                onChange={handleNewInfluencerFormChange}
                                                            /> */}
                                                            <div className='d-flex align-items-center mt-2'>
                                                                <label className='token-description-label mt-2' htmlFor="password">User Group:</label>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-sm btn-secondary ms-2 mt-3 mb-2"
                                                                    onClick={() => addNewUserGroup()}
                                                                >
                                                                    Add New User Group
                                                                </button>
                                                            </div>
                                                            <Select
                                                                id="user_group"
                                                                name="user_group"
                                                                options={optionsUserGroup}
                                                                isMulti
                                                                value={optionsUserGroup.filter(option => formData.group_ids.includes(option.value)) || null}
                                                                onChange={handleUserGroupChange}
                                                                onFocus={() => {
                                                                    // Clear the selected values on focus
                                                                    handleUserGroupChange([]);
                                                                }}
                                                                className="ticker-text-box"
                                                                classNamePrefix="admin-select"
                                                            />

                                                            <div className='d-flex align-items-center mt-2'>
                                                                <label className='token-description-label mt-2' htmlFor="mail">User Tag:</label>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-sm btn-secondary ms-2 mt-3 mb-2"
                                                                    onClick={() => addNewUserTag()}
                                                                >
                                                                    Add New User Tag
                                                                </button>
                                                            </div>
                                                            <Select
                                                                id="user_tag"
                                                                name="user_tag"
                                                                options={optionsUserTag}
                                                                isMulti
                                                                value={optionsUserTag.filter(option => formData.tag_ids.includes(option.value)) || null}
                                                                onChange={handleUserTagChange}
                                                                onFocus={() => {
                                                                    // Clear the selected values on focus
                                                                    handleUserTagChange([]);
                                                                }}
                                                                className="ticker-text-box"
                                                                classNamePrefix="admin-select"
                                                            />
                                                        </div>
                                                    }
                                                    {showAddUserGroupForm &&
                                                        <div className="col-md-12">
                                                            <label className="token-description-label">User Group Name:</label>
                                                            <input
                                                                id="name"
                                                                className="form-control mt-2 ticker-text-box"
                                                                value={userGroupName}
                                                                onChange={(event) => setUserGroupName(event.target.value)}
                                                            />
                                                        </div>
                                                    }
                                                    {showAddUserTagForm &&
                                                        <div className="col-md-12">
                                                            <label className="token-description-label">User Tag Name:</label>
                                                            <input
                                                                id="name"
                                                                className="form-control mt-2 ticker-text-box"
                                                                value={userTagName}
                                                                onChange={(event) => setUserTagName(event.target.value)}
                                                            />
                                                        </div>
                                                    }
                                                </div>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                {showAddInfluencerForm &&
                                                    <>
                                                        <button className="btn btn-primary" onClick={handleCreateInfluencer} disabled={loading}>
                                                            {loading ? <Spinner animation="border" size="sm" /> : 'Create Influencer'}
                                                        </button>
                                                        <button className="btn btn-secondary" onClick={() => setShowInfluencerCreateModal(false)}>Close</button>
                                                    </>
                                                }
                                                {showAddUserGroupForm &&
                                                    <>
                                                        <button className="btn btn-primary" onClick={handleCreateUserGroup} disabled={loading}>
                                                            {loading ? <Spinner animation="border" size="sm" /> : 'Create User Group'}
                                                        </button>
                                                        <button className="btn btn-secondary" onClick={() => {setShowAddUserGroupForm(false); setShowAddInfluencerForm(true); }}>Back</button>
                                                    </>
                                                }
                                                {showAddUserTagForm &&
                                                    <>
                                                        <button className="btn btn-primary" onClick={handleCreateUserTag} disabled={loading}>
                                                            {loading ? <Spinner animation="border" size="sm" /> : 'Create User Tag'}
                                                        </button>
                                                        <button className="btn btn-secondary" onClick={() => {setShowAddUserTagForm(false); setShowAddInfluencerForm(true);}}>Back</button>
                                                    </>
                                                }


                                            </Modal.Footer>
                                        </Modal>

                                    </>}
                            </Col>
                        </Row>
                    </div>
                </section >
            </div >
        </>
    )
}

export default BotInfluencers;