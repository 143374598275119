import React, { useState, useRef, useEffect } from 'react';
import { Row, Col, Card, Form, Table, Spinner, OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);


    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return isMobile;
};

export const DashboardBot = () => {
    const isMobile = useIsMobile();
    const navigate = useNavigate();
    const [allBotloader, setAllBotloader] = useState(false);

    const handleListBotClick = () => {
          navigate("/bot-agent/bot-list");
    }
    return (
        <>
            <div className="container-fluid">
                <Toaster />
                <section className="section-padding position-relative mt-4">
                    <div className="container-fluid">
                        
                    </div>
                </section >
            </div >
        </>
    )
};
export default DashboardBot;