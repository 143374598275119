import React, { useState, useRef, useEffect } from 'react';
import { Row, Col, Card, Form, Table, Spinner, OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import toast, { Toaster } from 'react-hot-toast';
import { useAccount } from 'wagmi'
import * as Icon from 'react-bootstrap-icons';

const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return isMobile;
};


interface Bots {
    username: string;
    mail: string;
    createdBy: string;
    phone: string;
    id: string;
    createdAt: string;
    status: string;
    profile: {
        id: string;
        createdAt: string;
        updatedAt: string;
        state: string;
        bio: string;
        name: string;
        banner: string;
        avatar: string;
        website: string;
    };

}

interface FormData {
    username: string;
    password: string;
    mail: string;
    appAuthSecret: string;
    mailPassword: string;
    phone: string;
    tag: string;
}

export const BotList = () => {
    const { address, isConnected } = useAccount()
    const isMobile = useIsMobile();
    const [allBotloader, setAllBotloader] = useState(false);
    const [allBots, setAllBots] = useState<Bots[]>([]);
    const [showBotCreateModal, setShowBotCreateModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [redirectUrl, setRedirectUrl] = useState<string | null>(null);

    const [formData, setFormData] = useState<FormData>({
        username: '',
        password: '',
        mail: '',
        appAuthSecret: '',
        mailPassword: '',
        phone: '',
        tag: ''
    });


    const fetchCallbackUrl = async () => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/oauth/generate_callback?wallet_id=${address}&shill_account_name=Sparqy`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            setRedirectUrl(data.data.redirect_url);
        } catch (error) {
            console.error('Error fetching the callback URL:', error);
        }
    };

    const handleOAuth = () => {
        if (redirectUrl) {
            window.location.href = redirectUrl;
        }
    };

    const handleNewBotFormChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { id, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [id]: value
        }));
    };

    const handleCreateNewBotClick = () => {
        setLoading(false)
        setFormData({
            username: '',
            password: '',
            mail: '',
            appAuthSecret: '',
            mailPassword: '',
            phone: '',
            tag: ''
        })
        setShowBotCreateModal(true);
    }

    const edit_bot = () => {
    }

    const delete_bot = () => {
    }

    const get_all_bot = async () => {
        setAllBotloader(true);
        setAllBots([]);
        const walletIdParam = isConnected ? `&wallet_id=${address}` : '';

        const url = `${process.env.REACT_APP_API_URL}shill/bots?wallet_id=${walletIdParam}`;

        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    // Include any other headers if needed
                }),
            });

            if (response.ok) {
                const data = await response.json();
                setAllBots(data['data']);
            } else {
                setAllBots([]);
            }
        } catch (e) {
            console.log(e);
            setAllBotloader(false);
            setAllBots([]);
        } finally {
            setAllBotloader(false);
        }
    };

    const handleCreateBotToken = () => {
        if (formData) {
            setLoading(true);
            const url = `${process.env.REACT_APP_API_URL}shill/bots?wallet_id=${address}`;
            const payload = {
                ...formData
            };

            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    return response.json();
                })
                .then((data) => {
                    console.log('Success:', data);
                    setLoading(false);
                    setShowBotCreateModal(false);
                    toast.success('Bot created successfully', {
                        style: {
                            background: 'rgba(0, 29, 255, 0.20)',
                            color: '#fff',
                        },
                    });
                    get_all_bot();
                })
                .catch((error) => {
                    console.error('Error:', error);
                    setLoading(false);
                    if (error.message.includes('500')) {
                        toast.error('Internal Server Error (500): Failed to update ticker', {
                            style: {
                                background: 'rgba(255, 0, 0, 0.20)',
                                color: '#fff',
                            },
                        });
                    } else {
                        toast.error('Failed to update ticker', {
                            style: {
                                background: 'rgba(255, 0, 0, 0.20)',
                                color: '#fff',
                            },
                        });
                    }
                });
        }
    };


    useEffect(() => {
        get_all_bot();
    }, []);

    return (
        <>
            <div className="container-fluid">
                <Toaster />
                <section className="section-padding position-relative mt-4">
                    <div className="container-fluid">
                        <Row>
                            <Col sm={12}>
                                <div className='me-auto'>
                                    <div className="s-heading">
                                        Bot List
                                    </div>

                                </div>
                            </Col>
                            <Col sm={3} className="mt-3">
                                <div className='ctw-btn mt-2' onClick={handleCreateNewBotClick}>

                                    Create Bot
                                </div>

                              

                            </Col>

                            <Col sm={12} className='mt-3'>

                                {allBotloader &&
                                    <Spinner animation="border" role="status" variant="light" >
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                }
                                {!allBotloader &&
                                    <>
                                        <div className={isMobile ? 'atw-table-container table-responsive-md' : 'atw-table-container'}>
                                            <Table className='atw-table'>
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Id</th>
                                                        <th>Username</th>
                                                        <th>Mail</th>
                                                        <th>Phone</th>
                                                        <th>Created By</th>
                                                        <th>Status</th>
                                                        <th></th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {allBots.length > 0 ? (
                                                        allBots.map((bots, index) => (
                                                            <tr key={index} className={allBots.length === 1 ? 'custom-single-row-height' : ''}>
                                                                <td width={'2%'}>
                                                                    <div className='d-flex align-items-center gap-2'>
                                                                        {index + 1}
                                                                    </div>
                                                                </td>
                                                                <td width={'30%'} className='cursor-pointer'>
                                                                    {bots['id']}
                                                                </td>
                                                                <td width={'10%'}>{bots['username']}</td>
                                                                <td width={'18%'}>
                                                                    {bots['mail']}
                                                                </td>
                                                                <td width={'10%'}>
                                                                    {bots['phone']}
                                                                </td>
                                                                <td width={'10%'}>
                                                                    {bots['createdBy']}
                                                                </td>
                                                                <td width={'10%'}>
                                                                    {bots['status']}
                                                                </td>
                                                                <td width={'5%'}>
                                                                    <div className="ctw-btn" onClick={() => edit_bot()}>
                                                                        Edit
                                                                    </div>
                                                                </td>
                                                                <td width={'5%'}>
                                                                    <div className="ctw-btn" onClick={() => delete_bot()}>
                                                                        Delete
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        // Render this row if allTokenTweets is empty
                                                        <tr>
                                                            <td colSpan={7} style={{ textAlign: 'center' }}>No data available</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </Table>
                                        </div>





                                        <Modal className='tweet-modal' show={showBotCreateModal} onHide={() => setShowBotCreateModal(false)} centered>
                                            <Modal.Header className="justify-content-between">
                                                <div className='update-modal-heading'>Create New Bot</div>
                                                <Icon.XCircle size={30} className='modal-close-icon' onClick={() => setShowBotCreateModal(false)}></Icon.XCircle>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        {/* Add input fields and other UI components here */}
                                                        <label className="token-description-label">Username:</label>
                                                        <input
                                                            id="username"
                                                            className="form-control mt-2 ticker-text-box"
                                                            value={formData.username}
                                                            onChange={handleNewBotFormChange}
                                                        />
                                                        <label className='token-description-label mt-2' htmlFor="password">Password:</label>
                                                        <input
                                                            id="password"
                                                            className="form-control mt-2 ticker-text-box"
                                                            value={formData.password}
                                                            onChange={handleNewBotFormChange}
                                                        />
                                                        <label className='token-description-label mt-2' htmlFor="mail">Mail:</label>
                                                        <input
                                                            id="mail"
                                                            className="form-control mt-2 ticker-text-box"
                                                            value={formData.mail}
                                                            onChange={handleNewBotFormChange}
                                                        />
                                                        <label className='token-description-label mt-2' htmlFor="mailPassword">Mail Password:</label>
                                                        <input
                                                            id="mailPassword"
                                                            className="form-control mt-2 ticker-text-box"
                                                            value={formData.mailPassword}
                                                            onChange={handleNewBotFormChange}
                                                        />
                                                        <label className='token-description-label mt-2' htmlFor="appAuthSecret">App Auth Secret:</label>
                                                        <input
                                                            id="appAuthSecret"
                                                            className="form-control mt-2 ticker-text-box"
                                                            value={formData.appAuthSecret}
                                                            onChange={handleNewBotFormChange}
                                                        />

                                                        <label className='token-description-label mt-2' htmlFor="phone">Phone:</label>
                                                        <input
                                                            id="phone"
                                                            className="form-control mt-2 ticker-text-box"
                                                            value={formData.phone}
                                                            onChange={handleNewBotFormChange}
                                                        />
                                                        <label className='token-description-label mt-2' htmlFor="tag">Tag:</label>
                                                        <input
                                                            id="tag"
                                                            className="form-control mt-2 ticker-text-box"
                                                            value={formData.tag}
                                                            onChange={handleNewBotFormChange}
                                                        />


                                                    </div>

                                                </div>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <button className="btn btn-primary" onClick={handleCreateBotToken} disabled={loading}>
                                                    {loading ? <Spinner animation="border" size="sm" /> : 'Create Bot'}
                                                </button>
                                                <button className="btn btn-secondary" onClick={() => setShowBotCreateModal(false)}>Close</button>

                                            </Modal.Footer>
                                        </Modal>

                                    </>}
                            </Col>
                        </Row>
                    </div>
                </section >
            </div >
        </>
    )
};
export default BotList;