import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Card, Button, Table, Spinner, OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import { useUser } from '../components/UserContext';
import { useAccount } from 'wagmi'
import { Tweet } from 'react-tweet';
import toast, { Toaster } from 'react-hot-toast';
import { formatDistanceToNow, parseISO, format } from 'date-fns';
import * as Icon from 'react-bootstrap-icons';
import bit_coin_symbol_img from "../assets/images/bit-coin-symbol.png"
import star_fill_img from "../assets/images/Star.png"
import star_table_img from "../assets/icons/Star 3.png"
import link_icon from "../assets/icons/link_icon.png"
import no_img from "../assets/images/no-image-icon.png"
import './new-token.css'

interface Tweet {
    user: number;
    tweet_id: string;
    created_at: string;
    cryptocurrency: string;
    crypto_symbol: string;
    classification: string;
    TLDR: string;
    category: string;
    keyword: string;
    user_profile_image_url: string;
    user_username: string;
    user_name: string;
    dexscreen_url: string;
    token_sniffer_url: string;
    image_url: string;
    id: string;
    price_usd: number;
    user_tag: string;
}

interface ViewTweet {
    complete_text: string;
    tweet_id: string;
    publishable: boolean;
    user_profile_image_url: string;
    user_name: string;
    username: string;
    created_at: string;
}

type TweetsByDate = {
    [key: string]: Tweet[];
};


interface CryptoTableRowProps {
    tweets: Tweet[];
}







const Avatars: React.FC<CryptoTableRowProps> = ({ tweets }) => {
    const { address, isConnected } = useAccount()
    const maxAvatars = 5;
    const remainingCount = tweets.length - maxAvatars;
    const [searchTerm, setSearchTerm] = useState("");
    const [isSortedByDate, setIsSortedByDate] = useState(true);
    const [showTweetModal, setShowTweetModal] = useState(false);
    const [viewTweetId, setViewTweetId] = useState<string | null>(null);
    const [showDetailModal, setShowDetailModal] = useState(false);
    const [selectedTweet, setSelectedTweet] = useState<ViewTweet | null>(null);
    const [userSelectedTweet, setUserSelectedTweet] = useState<ViewTweet | null>(null);
    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };
    const filteredTweets = tweets
        .filter(tweet =>
            tweet.user_username?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            tweet.user_name?.toLowerCase().includes(searchTerm.toLowerCase())
        )
        .sort((a, b) => {
            if (isSortedByDate) {
                return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
            }
            return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
        });

    const open_tweet_link = (tweet_id: string) => {
        window.open("https://twitter.com/CryptoNikyous/status/" + tweet_id, "_blank")
    }

    const handleSortByDate = () => {
        setIsSortedByDate(!isSortedByDate);
    };


    const convertToLocalTime = (isoDate: string | undefined) => {
        if (!isoDate) {
            return 'Invalid date';
        }

        try {
            // Add 'Z' at the end of the ISO date to indicate UTC time
            const date = parseISO(isoDate + 'Z');
            return format(date, "h:mm a · MMM d, yyyy");
        } catch (error) {
            console.error("Error parsing date:", error);
            return 'Invalid date';
        }
    };


    const handleViewTweet = async (id: string) => {


        const url = `${process.env.REACT_APP_API_URL}search/tweet?wallet_id=${address}`;
        const payload = {
            tweet_ids: [id]
        };

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(payload)
            });

            if (response.ok) {
                const data = await response.json();
                const tweetData = data.tweets[id];
                if (tweetData.publishable) {
                    setUserSelectedTweet({
                        complete_text: tweetData.tweet.complete_text,
                        tweet_id: tweetData.tweet.id,
                        publishable: true,
                        user_profile_image_url: tweetData.tweet.user_profile_picture_url,
                        username: tweetData.tweet.username,
                        user_name: tweetData.tweet.user_name,
                        created_at: tweetData.tweet.created_at
                    });
                } else {
                    setUserSelectedTweet({
                        complete_text: tweetData.tweet.complete_text,
                        tweet_id: tweetData.tweet.id,
                        publishable: false,
                        user_profile_image_url: tweetData.tweet.user_profile_picture_url,
                        username: tweetData.tweet.username,
                        user_name: tweetData.tweet.user_name,
                        created_at: tweetData.tweet.created_at
                    });
                }
                setViewTweetId(id);
                // setShowDetailModal(true);
            } else {
                console.error("Failed to fetch publishable status", response.status);
            }
        } catch (error) {
            console.error("Error fetching publishable status", error);
        }

    };

    const handleBack = () => {
        setViewTweetId(null);
    };


    const handleAvatarClick = async (tweet: Tweet) => {
        console.log(tweet)
        setViewTweetId(null);
        setUserSelectedTweet(null);
        const url = `${process.env.REACT_APP_API_URL}search/tweet?wallet_id=${address}`;
        const payload = {
            tweet_ids: [tweet.tweet_id]
        };

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(payload)
            });

            if (response.ok) {
                const data = await response.json();
                const tweetData = data.tweets[tweet.tweet_id];
                console.log(tweetData)
                if (tweetData.publishable) {
                    setSelectedTweet({
                        complete_text: tweetData.tweet.complete_text,
                        tweet_id: tweetData.tweet.id,
                        publishable: true,
                        user_profile_image_url: tweetData.tweet.user_profile_picture_url,
                        username: tweetData.tweet.username,
                        user_name: tweetData.tweet.user_name,
                        created_at: tweetData.tweet.created_at
                    });
                } else {
                    setSelectedTweet({
                        complete_text: tweetData.tweet.complete_text,
                        tweet_id: tweetData.tweet.id,
                        publishable: false,
                        user_profile_image_url: tweetData.tweet.user_profile_picture_url,
                        username: tweetData.tweet.username,
                        user_name: tweetData.tweet.user_name,
                        created_at: tweetData.tweet.created_at
                    });
                }
                setShowDetailModal(true);
            } else {
                console.error("Failed to fetch publishable status", response.status);
            }
        } catch (error) {
            console.error("Error fetching publishable status", error);
        }
    };

    return (
        <div>
            <div className="stacked-avatars">
                {tweets.slice(0, maxAvatars).map((tweet, index) => (
                    <div key={index} className="avatars__item" style={{ zIndex: tweets.length - index }} onClick={() => handleAvatarClick(tweet)}>
                        <img className="avatar-img" src={tweet.user_profile_image_url || no_img} alt="user-avatar" />
                    </div>
                ))}
                {remainingCount > 0 && (
                    <div className="avatars__item avatars__more" style={{ zIndex: 0 }} onClick={() => setShowTweetModal(true)}>
                        +{remainingCount}
                    </div>
                )}
            </div>

            <Modal className='tweet-modal modal-lg' show={showTweetModal} onHide={() => setShowTweetModal(false)} centered>
                <Modal.Header className="justify-content-between">
                    <div className='h-tweet-modal-h'>Tweets</div>
                    <Icon.XCircle size={30} className='modal-close-icon' onClick={() => setShowTweetModal(false)}></Icon.XCircle>
                </Modal.Header>
                <Modal.Body>
                    <div className="app-container">
                        {viewTweetId && userSelectedTweet ? (
                            <div>
                                <button className="back-btn" onClick={handleBack}>Back To Tweets</button>
                                <div data-theme="dark" className='modal-tweet-container'>

                                    {userSelectedTweet && userSelectedTweet.publishable ? (
                                        <Tweet id={userSelectedTweet.tweet_id} />
                                    ) : (
                                        <div className="not-found-tweet-container">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <img src={userSelectedTweet.user_profile_image_url} className="t-d-user-img" alt="User" />
                                                </div>
                                                <div>
                                                    <div className="ms-1 ticker-name">{userSelectedTweet.user_name}</div>
                                                    <div className="ms-1 ticker-symbol">@{userSelectedTweet.username}</div>
                                                </div>
                                            </div>
                                            <div className="tweet-complete-text">{userSelectedTweet.complete_text}</div>
                                            <div className='token-detail-timestamp'>
                                                {
                                                    convertToLocalTime(userSelectedTweet.created_at)
                                                }
                                            </div>
                                            <Button className='go-to-tweet-btn mt-2' variant="secondary" onClick={() => open_tweet_link(userSelectedTweet.tweet_id)}>
                                                Go To Tweet
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <>
                                <div className="search-sort-container">
                                    <input
                                        type="text"
                                        placeholder="Search by Influencer..."
                                        className="search-bar"
                                        value={searchTerm}
                                        onChange={handleSearchChange}
                                    />
                                    <button className="sort-btn" onClick={handleSortByDate}>
                                        Sort by Date {isSortedByDate ? '(Descending)' : '(Ascending)'}
                                    </button>
                                </div>
                                <div className="investor-list">
                                    {filteredTweets.map((tweet, index) => (
                                        <div key={index} className="investor-item">
                                            <img className="avatar-img-small" src={tweet.user_profile_image_url || no_img} alt="user-avatar" />

                                            <div className="investor-info">
                                                <div className="investor-name">{tweet.user_name}</div>
                                                <div className="investor-type">{tweet.user_username}</div>
                                                {tweet?.user_tag &&
                                                    <div className='influencer-home-tag'>{tweet?.user_tag}</div>
                                                }
                                                <div className="tweet-date">
                                                    {new Date(tweet.created_at).toLocaleDateString('en-GB', {
                                                        day: '2-digit',
                                                        month: '2-digit',
                                                        year: 'numeric',
                                                    })}{' '}
                                                    {new Date(tweet.created_at).toLocaleTimeString('en-US', {
                                                        hour: '2-digit',
                                                        minute: '2-digit',
                                                        second: '2-digit',
                                                        hour12: true,
                                                    })}
                                                </div>
                                            </div>
                                            <button className="view-tweet-btn-modal" onClick={() => handleViewTweet(tweet.tweet_id)} >View Tweet</button>
                                        </div>
                                    ))}
                                </div>
                            </>)}
                    </div>
                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </Modal>

            {selectedTweet && (
                <Modal className='tweet-modal' show={showDetailModal} onHide={() => setShowDetailModal(false)} centered>
                    <Modal.Header className="justify-content-between">
                        <div className='h-tweet-modal-h'>Tweet Details</div>
                        <Icon.XCircle size={30} className='modal-close-icon' onClick={() => setShowDetailModal(false)} />
                    </Modal.Header>
                    <Modal.Body>
                        <div data-theme="dark" className='modal-tweet-container'>
                            {selectedTweet.publishable ? (
                                <Tweet id={selectedTweet.tweet_id} />
                            ) : (
                                <div className="not-found-tweet-container">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <img src={selectedTweet.user_profile_image_url} className="t-d-user-img" alt="User" />
                                        </div>
                                        <div>
                                            <div className="ms-1 ticker-name">{selectedTweet.user_name}</div>
                                            <div className="ms-1 ticker-symbol">@{selectedTweet.username}</div>
                                        </div>
                                    </div>
                                    <div className="tweet-complete-text">{selectedTweet.complete_text}</div>
                                    <div className='token-detail-timestamp'>
                                        {
                                            convertToLocalTime(selectedTweet.created_at)
                                        }
                                    </div>
                                    <Button className='go-to-tweet-btn mt-2' variant="secondary" onClick={() => open_tweet_link(selectedTweet.tweet_id)}>
                                        Go To Tweet
                                    </Button>
                                </div>
                            )}
                        </div>
                    </Modal.Body>
                </Modal>
            )}

        </div>
    );
};

const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return isMobile;
};



export const NewToken = () => {
    const isMobile = useIsMobile();
    const navigate = useNavigate();
    const { user, addToWatchlist, removeFromWatchlist, watchlistToken } = useUser();
    const [activeTrendingTweetsTimeWindow, setActiveTrendingTweetsTimeWindow] = useState('1month')
    const [activeCoingeckoTimeWindow, setActiveCoingeckoTimeWindow] = useState('1month')
    const [allCoingeckoloader, setAllCoingeckoloader] = useState(false);
    const [allCoingeckoToken, setAllCoingeckoToken] = useState([]);

    const [allDefinedloader, setAllDefinedloader] = useState(false);
    const [allDefinedToken, setAllDefinedToken] = useState([]);


    const get_all_coingecko_token = async () => {
        setAllCoingeckoloader(true)

        const url = `${process.env.REACT_APP_API_URL}new-tokens/screener?get_from=cg`;

        try {
            const response = await fetch(url, {
                method: "GET",
                mode: "cors",
                headers: new Headers({ "content-type": "application/json" })
            });
            if (response.status === 200) {
                const data = await response.json();
                setAllCoingeckoToken(data['data']);

                setAllCoingeckoloader(false)
            } else {
                console.log(response.status);
                setAllCoingeckoloader(false)
            }
        } catch (e) {
            console.log(e);
            setAllCoingeckoloader(false)
        }
    };

    const get_all_defined_token = async () => {
        setAllDefinedloader(true)

        const url = `${process.env.REACT_APP_API_URL}new-tokens/screener?get_from=gt`;

        try {
            const response = await fetch(url, {
                method: "GET",
                mode: "cors",
                headers: new Headers({ "content-type": "application/json" })
            });
            if (response.status === 200) {
                const data = await response.json();
                setAllDefinedToken(data['data']);

                setAllDefinedloader(false)
            } else {
                console.log(response.status);
                setAllDefinedloader(false)
            }
        } catch (e) {
            console.log(e);
            setAllDefinedloader(false)
        }
    };

    const checkIfExistsOnWatchList = (tokenId: string): string | null => {
        if (watchlistToken?.length > 0) {
            const item = watchlistToken.find(item => item.data === tokenId);
            return item ? item.watchlist_id : null;
        } else {
            return null
        }

    }

    const handleWatchlistToggle = async (tokenId: any) => {
        // Check if the token is currently in the watchlist
        const isInWatchlist = checkIfExistsOnWatchList(tokenId);
        if (user?.wallet_id) {
            if (isInWatchlist) {
                removeFromWatchlist('Token', isInWatchlist, user?.wallet_id)
            } else {
                addToWatchlist('Token', tokenId, user?.wallet_id)
            }
        }


        // Optionally, refresh the watchlist data to reflect the update
    };


    const open_token_detail = (token: any) => {
        window.open("/token-detail/" + token, "_blank");
    }

    const back_generate_tweet = (token: any) => {
        if (user) {
            if (user?.credit > 0) {
                navigate("/generate-tweet/" + token);
            } else {
                toast.error('Your credit is low. please add balance to your account', {
                    style: {
                        background: 'rgba(0, 29, 255, 0.20)',
                        color: '#fff'
                    }
                });
            }

        } else {
            toast.error('Please connect your wallat', {
                style: {
                    background: 'rgba(0, 29, 255, 0.20)',
                    color: '#fff'
                }
            });
        }

    }

    const open_coingecko_link = (coin_id: any) => {
        if (!coin_id) {
            return
        }
        // window.open("https://twitter.com/search?q=%24" + query, "_blank")
        window.open("https://www.coingecko.com/en/coins/" + coin_id, "_blank")
    }

    const open_link = (url: any) => {
        window.open(url, "_blank")
    }

    const threshold = 1e-6;

    const formatNumber = (num: any) => {
        // Convert the input to a number if it's not already a number
        if (typeof num === 'string') {
            num = parseFloat(num);
        }

        // Ensure the input is a number
        if (typeof num !== 'number' || isNaN(num)) {
            console.log('not number');
            return `$ ${num}`;
        }

        // Check if the number is greater than or equal to the threshold
        if (num >= threshold) {
            console.log('not formatting');
            return `$ ${num}`;
        }

        let formattedNumber = num.toFixed(36);

        // Split the number to handle the fractional part separately
        let [integerPart, fractionalPart] = formattedNumber.split('.');

        // Format the fractional part to match the desired style
        const highlightedDigit = fractionalPart.slice(6, 7); // the first non-zero digit after six zeros
        const restOfDigits = fractionalPart.slice(7);

        return `<span class="chakra-text custom-0" title="$ ${formattedNumber}">$ ${integerPart}.0<span class="chakra-text custom-subscript">6</span>${highlightedDigit}${restOfDigits}</span>`;
    };


    const timeAgo = (dateString: any) => {
        const tokenDate = parseISO(dateString);
        return formatDistanceToNow(tokenDate, { addSuffix: true });
    };

    useEffect(() => {
        get_all_coingecko_token();
        get_all_defined_token();
    }, []);


    const formatValue = (value: any) => {
        if (value === null || value === '') {
            return "";
        }

        try {
            value = parseFloat(value);
        } catch (error) {
            return "Invalid Value";
        }

        if (Math.abs(value) >= 1.0e9) {
            return `${(value / 1.0e9).toFixed(2)} B`;
        } else if (Math.abs(value) >= 1.0e6) {
            return `${(value / 1.0e6).toFixed(2)} M`;
        } else if (Math.abs(value) >= 1.0e3) {
            return `${(value / 1.0e3).toFixed(2)} K`;
        } else {
            return value.toFixed(2);
        }
    };



    function formatPrice(price: any) {
        const value = Number(price);

        if (isNaN(value)) {
            return `$0.00`; // Handle non-numeric input gracefully
        }

        if (value < 0.000000001) {
            return `$${value.toExponential(2)}`; // Use scientific notation for very small numbers
        } else {
            const formattedValue = value.toFixed(8); // Fixed to 8 decimal places for consistent formatting
            const parts = formattedValue.split('.');
            const integerPart = parts[0];
            const decimalPart = parts[1];

            if (parseInt(integerPart) > 10) {
                // For numbers greater than 10, return formatted value without HTML enhancements
                return `$${parseFloat(value.toFixed(8))}`;
            } else {
                let zeroCount = 0;
                let leadingZeros = '';
                let remainingDigits = '';

                // Count leading zeros and find the significant digits
                for (let i = 0; i < decimalPart.length; i++) {
                    if (decimalPart[i] === '0') {
                        zeroCount++;
                        leadingZeros += '0';
                    } else {
                        remainingDigits = decimalPart.slice(i);
                        break;
                    }
                }

                // Return formatted string with or without HTML embellishments based on zero count
                if (zeroCount > 4) {
                    return `
                        <div class="price">
                            <span class="chakra-text" title="$${formattedValue}">
                                $${integerPart}.0
                                <span class="chakra-text custom-subscript">${zeroCount}</span>${remainingDigits}
                            </span>
                        </div>
                    `;
                } else {
                    return `$${value}`;
                }
            }
        }
    }



    return (
        <div className="container-fluid">
            <Toaster />
            <section className="first-section section-padding position-relative">
                <div className="container-fluid">
                    <Row>

                        <Col sm={12} className={isMobile ? '' : 'd-flex'}>
                            <div className="s-heading me-auto">
                                New Coins Trending in COINGECKO
                            </div>
                            {/* <div className='time-window-container d-flex'>
                                <div onClick={() => setActiveCoingeckoTimeWindow('15min')} className={`time-window ${activeCoingeckoTimeWindow == '15min' ? 'active' : ''}`}>
                                    15 min
                                </div>
                                <div onClick={() => setActiveCoingeckoTimeWindow('1hr')} className={`time-window ${activeCoingeckoTimeWindow == '1hr' ? 'active' : ''}`}>
                                    1h
                                </div>
                                <div onClick={() => setActiveCoingeckoTimeWindow('4hr')} className={`time-window ${activeCoingeckoTimeWindow == '4hr' ? 'active' : ''}`}>
                                    4h
                                </div>
                                <div onClick={() => setActiveCoingeckoTimeWindow('12hr')} className={`time-window ${activeCoingeckoTimeWindow == '12hr' ? 'active' : ''}`}>
                                    12h
                                </div>
                                <div onClick={() => setActiveCoingeckoTimeWindow('1day')} className={`time-window ${activeCoingeckoTimeWindow == '1day' ? 'active' : ''}`}>
                                    1d
                                </div>
                                <div onClick={() => setActiveCoingeckoTimeWindow('1week')} className={`time-window ${activeCoingeckoTimeWindow == '1week' ? 'active' : ''}`}>
                                    7d
                                </div>
                                <div onClick={() => setActiveCoingeckoTimeWindow('1month')} className={`time-window ${activeCoingeckoTimeWindow == '1month' ? 'active' : ''}`}>
                                    30d
                                </div>
                            </div> */}
                        </Col>
                    </Row>
                </div>
            </section>
            <section className="section-padding position-relative mt-4">
                <div className="container-fluid">

                    <Row>
                        <Col sm={12} className='mt-3'>

                            {allCoingeckoloader &&
                                <Spinner animation="border" role="status" variant="light" >
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            }
                            {!allCoingeckoloader &&
                                <>
                                    <div className={isMobile ? 'atw-table-container table-responsive-md' : 'atw-table-container'}>
                                        <Table className='atw-table'>
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th></th>
                                                    <th>Tokens</th>
                                                    <th>Price</th>
                                                    <th>Token age</th>
                                                    <th>Tweet Link</th>
                                                    <th>
                                                        Coingecko Link
                                                    </th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {allCoingeckoToken.length > 0 ? (
                                                    allCoingeckoToken.map((token, index) => (
                                                        <tr key={index}>
                                                            <td width={'2%'}>{index + 1}</td>
                                                            <td width={'3%'}>
                                                                <img
                                                                    src={checkIfExistsOnWatchList(token['id']) ? star_fill_img : star_table_img}
                                                                    className="cursor-pointer"
                                                                    onClick={() => handleWatchlistToggle(token['id'])}
                                                                    alt="Watchlist Toggle"
                                                                />
                                                            </td>
                                                            <td width={'20%'} className='cursor-pointer'>
                                                                <div className='d-flex align-items-center' onClick={() => open_token_detail(token['id'])}>
                                                                    <div>
                                                                        <img src={token['image_url'] ? token['image_url'] : no_img} className="ticker-img" />
                                                                    </div>
                                                                    <div>
                                                                        <div className="ms-1 ticker-name">{token['name']}</div>
                                                                        <div className="ms-1 ticker-symbol">{token['symbol']}</div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td width={'15%'}>
                                                                {/* <div className="price">
                                                                    {token['price_usd'] ? (
                                                                        <span dangerouslySetInnerHTML={{ __html: formatNumber(token['price_usd']) }} />
                                                                    ) : (
                                                                        'N/A'
                                                                    )}
                                                                </div> */}
                                                                {/* {token['price_usd'] ? token['price_usd'] : ''} */}
                                                                <div className="price">
                                                                    {token['price_usd'] ? (
                                                                        <span dangerouslySetInnerHTML={{ __html: formatPrice(token['price_usd']) }} />
                                                                    ) : (
                                                                        'N/A'
                                                                    )}
                                                                </div>
                                                            </td>
                                                            <td width={'10%'}>
                                                                {timeAgo(token['token_created_at'])}
                                                            </td>
                                                            <td width={'30%'}>
                                                                <Avatars tweets={token['tweets']} />
                                                            </td>

                                                            <td width={'5%'}>
                                                                {token['coin_id'] &&
                                                                    <div className="position-relative" onClick={() => open_coingecko_link(token['coin_id'])}> {/* Add this wrapper */}
                                                                        <img className='table-link-icon' src={link_icon} />
                                                                    </div>
                                                                }
                                                            </td>
                                                            <td width={'15%'}>
                                                                <div className="ctw-btn" onClick={() => back_generate_tweet(token['id'])}>
                                                                    Create Tweet
                                                                </div>
                                                            </td>

                                                        </tr>
                                                    ))
                                                ) : (
                                                    // Render this row if allTokenTweets is empty
                                                    <tr>
                                                        <td colSpan={7} style={{ textAlign: 'center' }}>No data available</td>
                                                    </tr>
                                                )}

                                            </tbody>
                                        </Table>
                                    </div>
                                </>
                            }
                        </Col>
                    </Row>
                </div>
            </section>
            <section className="first-section section-padding position-relative mt-5">
                <div className="container-fluid">
                    <Row>

                        <Col sm={12} className={isMobile ? '' : 'd-flex'}>
                            <div className="s-heading me-auto">
                                New Coins Trending in Geckoterminal
                            </div>
                            {/* <div className='time-window-container d-flex'>
                                <div onClick={() => setActiveCoingeckoTimeWindow('15min')} className={`time-window ${activeCoingeckoTimeWindow == '15min' ? 'active' : ''}`}>
                                    15 min
                                </div>
                                <div onClick={() => setActiveCoingeckoTimeWindow('1hr')} className={`time-window ${activeCoingeckoTimeWindow == '1hr' ? 'active' : ''}`}>
                                    1h
                                </div>
                                <div onClick={() => setActiveCoingeckoTimeWindow('4hr')} className={`time-window ${activeCoingeckoTimeWindow == '4hr' ? 'active' : ''}`}>
                                    4h
                                </div>
                                <div onClick={() => setActiveCoingeckoTimeWindow('12hr')} className={`time-window ${activeCoingeckoTimeWindow == '12hr' ? 'active' : ''}`}>
                                    12h
                                </div>
                                <div onClick={() => setActiveCoingeckoTimeWindow('1day')} className={`time-window ${activeCoingeckoTimeWindow == '1day' ? 'active' : ''}`}>
                                    1d
                                </div>
                                <div onClick={() => setActiveCoingeckoTimeWindow('1week')} className={`time-window ${activeCoingeckoTimeWindow == '1week' ? 'active' : ''}`}>
                                    7d
                                </div>
                                <div onClick={() => setActiveCoingeckoTimeWindow('1month')} className={`time-window ${activeCoingeckoTimeWindow == '1month' ? 'active' : ''}`}>
                                    30d
                                </div>
                            </div> */}
                        </Col>
                    </Row>
                </div>
            </section>

            <section className="section-padding position-relative mt-4">
                <div className="container-fluid">

                    <Row>
                        <Col sm={12} className='mt-3'>

                            {allDefinedloader &&
                                <Spinner animation="border" role="status" variant="light" >
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            }
                            {!allDefinedloader &&
                                <>
                                    <div className={isMobile ? 'atw-table-container table-responsive-md' : 'atw-table-container'}>
                                        <Table className='atw-table'>
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th></th>
                                                    <th>Tokens</th>
                                                    <th>Price</th>
                                                    <th>Token age</th>
                                                    <th>Tweet Link</th>
                                                    <th>
                                                        Links
                                                    </th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {allDefinedToken.length > 0 ? (
                                                    allDefinedToken.map((token, index) => (
                                                        <tr key={index}>
                                                            <td width={'2%'}>{index + 1}</td>
                                                            <td width={'3%'}>
                                                                <img
                                                                    src={checkIfExistsOnWatchList(token['id']) ? star_fill_img : star_table_img}
                                                                    className="cursor-pointer"
                                                                    onClick={() => handleWatchlistToggle(token['id'])}
                                                                    alt="Watchlist Toggle"
                                                                />
                                                            </td>
                                                            <td width={'20%'} className='cursor-pointer'>
                                                                <div className='d-flex align-items-center' onClick={() => open_token_detail(token['id'])}>
                                                                    <div>
                                                                        <img src={token['image_url'] ? token['image_url'] : no_img} className="ticker-img" />
                                                                    </div>
                                                                    <div>
                                                                        <div className="ms-1 ticker-name">{token['name']}</div>
                                                                        <div className="ms-1 ticker-symbol">{token['symbol']}</div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td width={'15%'}>
                                                                {/* {token['price_usd'] ? '$ ' + token['price_usd'] : ''} */}
                                                                <div className="price">
                                                                    {token['price_usd'] ? (
                                                                        <span dangerouslySetInnerHTML={{ __html: formatPrice(token['price_usd']) }} />
                                                                    ) : (
                                                                        'N/A'
                                                                    )}
                                                                </div>
                                                            </td>
                                                            <td width={'10%'}>
                                                                {timeAgo(token['token_created_at'])}
                                                            </td>
                                                            <td width={'30%'}>
                                                                <div className='new-token-tweet-link'>
                                                                    <Avatars tweets={token['tweets']} />
                                                                </div>
                                                            </td>

                                                            <td width={'5%'}>
                                                                {token['coin_id'] &&
                                                                    <div className="position-relative" onClick={() => open_coingecko_link(token['coin_id'])}> {/* Add this wrapper */}
                                                                        <img className='table-link-icon' src={link_icon} />
                                                                    </div>
                                                                }
                                                            </td>
                                                            <td width={'15%'}>
                                                                <div className="ctw-btn" onClick={() => back_generate_tweet(token['id'])}>
                                                                    Create Tweet
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    // Render this row if allTokenTweets is empty
                                                    <tr>
                                                        <td colSpan={7} style={{ textAlign: 'center' }}>No data available</td>
                                                    </tr>
                                                )}

                                            </tbody>
                                        </Table>
                                    </div>
                                </>
                            }
                        </Col>
                    </Row>
                </div>
            </section>
        </div>
    )
}

export default NewToken;