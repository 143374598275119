import React, { useState } from "react";
import { Row, Col, Card } from "react-bootstrap";
// import profile from '../assets/images/Group (3).png';
import './account.css';
import './home.css';

const SubscriptionDashboard: React.FC = () => {
  const [imageSrc, setImageSrc] = useState<string | null>(null);

  const [historyData, setHistoryData] = useState([
    { planType: "Silver", startDate: "Jan 30 2023", endDate: "Feb 28 2023", status: "Active" },
    { planType: "Gold", startDate: "Mar 1 2023", endDate: "Apr 30 2023", status: "Expired" },
    // Add more entries as needed
  ]);

  const planDetails = {
    planType: "Gold",
    startDate: "Jan 30 2023",
    endDate: "Jan 30 2023",
    status: "Active",
  };

  return (
    <div className="container-fluid">
      <section className="first-section section-padding position-relative">
        <div className="container">
          <Row className="justify-content-center">
            <Col sm={12}>
              <div className="a-button mt-3">
                <button className="m">Back</button>
              </div>

              <div className="a-heading  mt-1">
                <h1>Profile</h1>
              </div>




              <Row className="justify-content-center">
                {/* Profile Card */}
                <Col xs={12} sm={12} md={6} lg={4} className="mb-3">
                  <Card className="t-w-card text-center">
                    <Card.Body>
                      <div className="tw-row-1 d-flex justify-content-center align-items-center">
                        <div className="d-flex align-items-center">
                          {/* <img src={profile} className="ticker-img" alt="Profile" /> */}
                        </div>
                      </div>
                      <div className="tw-row mt-4">
                        <Row>
                          <Col sm={6} className="border-end pe-3">
                            <div className="tw-label">Wallet ID</div>
                            <div className="tw-text">Mti54d...Yk</div>
                          </Col>
                          <Col sm={6}>
                            <div className="tw-label">Date Joined</div>
                            <div className="tw-text">Jan 30 2023</div>
                          </Col>
                        </Row>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>


                {/* Subscription Card */}
                <Col xs={12} sm={12} md={6} lg={8}>
                  <Card className="t-w-card p-4 text-center"> {/* Use lower case 'p-4' for padding */}
                    <Card.Body>
                      {/* Plan Details */}
                      <div className="tw-row-mt-4">
                        <div className="ts-text">Plan Details</div>
                      </div>
                      <div className="tw-row mt-4">
                        <Row>
                          <Col sm={3}>
                            <div className="tw-label">Plan Type</div>
                            <div className="tw-text">{planDetails.planType}</div>
                          </Col>
                          <Col sm={3}>
                            <div className="tw-label">Start Date</div>
                            <div className="tw-text">{planDetails.startDate}</div>
                          </Col>
                          <Col sm={3}>
                            <div className="tw-label">End Date</div>
                            <div className="tw-text">{planDetails.endDate}</div>
                          </Col>
                          <Col sm={3}>
                            <div className="tw-label">Status</div>
                            <div className="tw-text">{planDetails.status}</div>
                          </Col>
                        </Row>
                      </div>

                      {/* History */}
                      <div>
                        <div className="tw-row mt-4">
                          <div className="ts-text">History</div>
                        </div>

                        <div className="tw-row mt-4">
                          <Row>
                            <Col sm={2}>
                              <div className="tw-label">#</div>
                            </Col>
                            <Col sm={3}>
                              <div className="tw-label">Plan Type</div>
                            </Col>
                            <Col sm={2}>
                              <div className="tw-label">Start Date</div>
                            </Col>
                            <Col sm={2}>
                              <div className="tw-label">End Date</div>
                            </Col>
                            <Col sm={2}>
                              <div className="tw-label">Status</div>
                            </Col>
                          </Row>
                        </div>

                        <hr className="tw-line" style={{ color: 'white', height: '2px', backgroundColor: 'white' }} />

                        {historyData.map((entry, index) => (
                          <div className="tw-row mt-4" key={index}>
                            <Row>
                              <Col sm={2}>
                                <div className="tw-text">{index + 1}</div>
                              </Col>
                              <Col sm={3}>
                                <div className="tw-text">{entry.planType}</div>
                              </Col>
                              <Col sm={2}>
                                <div className="tw-text">{entry.startDate}</div>
                              </Col>
                              <Col sm={2}>
                                <div className="tw-text">{entry.endDate}</div>
                              </Col>
                              <Col sm={2}>
                                <div className="tw-text">{entry.status}</div>
                              </Col>
                            </Row>
                          </div>
                        ))}
                      </div>

                    </Card.Body>
                  </Card>
                </Col>

              </Row>
            </Col>
          </Row>
        </div>
      </section>
    </div>
  );
};

export default SubscriptionDashboard;
