import { useState, useRef, useEffect } from 'react';
import { Row, Col, Card, Form, Table, Spinner, Modal, Button, Overlay, Popover, Tooltip } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import * as Icon from 'react-bootstrap-icons';
import { Tweet } from 'react-tweet';
import DatePicker from "react-datepicker";
// import TokenFilter from '../components/TokenFilter';
import TokenFilter from '../components/TokenFilterNew';
import InfluencerFilter from '../components/InfluencerFilter';
import EcoSystemFilter from '../components/EcosystemFilter';
import CategoryFilter from '../components/CategoryFilter';
import TokenFilterForHome from '../components/TokenFilterForHome';
import KeywordFilter from '../components/KeywordFilter';
import { transformInfluencersToCategories, transformTokensToCategories } from '../components/utils';
import { Influencer, InfluencerCategory, Token, TokenCategory } from '../components/filterTypes';
import { useAccount } from 'wagmi'
import toast, { Toaster } from 'react-hot-toast';
import { format, parseISO } from 'date-fns';
import './daily-summary.css'
import "react-datepicker/dist/react-datepicker.css";
import copy_icon from "../assets/icons/File copy.svg"
import link_icon from "../assets/icons/link_icon.png"




interface Summary {
    crypto_symbol: string[];
}

interface CryptoSymbolsProps {
    summary: Summary;
}

const CryptoSymbols: React.FC<CryptoSymbolsProps> = ({ summary }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const toggleExpanded = () => setIsExpanded(!isExpanded);

    // Determine the number of symbols to display initially
    const initialCount = 4;
    const symbolsToShow = isExpanded ? summary.crypto_symbol : summary.crypto_symbol.slice(0, initialCount);

    return (
        <div className="crypto-symbols-container">
            {symbolsToShow.map((symbol, index) => (
                <div key={index} className="crypto-symbol">{symbol}</div>
            ))}
            {summary.crypto_symbol.length > initialCount && (
                <div>
                    <button onClick={toggleExpanded} className="view-more-btn btn btn-link p-0">
                        {isExpanded ? 'View Less' : 'View More'}
                    </button>
                </div>
            )}
        </div>
    );
};

interface TokenOption {
    value: string;
    // ... other properties, if they exist
}

interface TweetSummaryData {

    tldr: string;
    user_name: string;
    tweet_created_at: string;
    user_username: string;
    user_verified: boolean;
    user_profile_image_url: string;
    tweet_id: string;
    crypto_symbol: string[];
    user_tag: string;
    // ... other properties
}

interface InfluencerFilterData {
    user_name: string;
    user_username: string;
    user_verified: boolean;
    user_profile_image_url: string;
    // ... other properties
}

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return isMobile;
};

// interface Token {
//     token: string;
//     symbol: string;
// }

interface InfluencerProfile {
    description: string;
    username: string;
    name: string;
    public_metrics: {
        followers_count: number;
        following_count: number;
        tweet_count: number;
        listed_count: number;
        like_count: number;
    },
    entities: {
        url: {
            urls: Array<{
                start: number;
                end: number;
                url: string;
                expanded_url: string;
                display_url: string;
            }>
        },
        description: {
            urls: Array<{
                start: number;
                end: number;
                url: string;
                expanded_url: string;
                display_url: string;
            }>,
            mentions: Array<{
                start: number;
                end: number;
                username: string;
            }>
        }
    },
    url: string;
    location: string;
    created_at: string;
    verified: boolean;
    id: string;
    profile_image_url: string;
    pinned_tweet_id: string;
    protected: boolean;
    category: string;
}


interface Filters {
    tokens: Token[];
    keywords: string[];
    influencer: Influencer[];
}

export const DailySummary = () => {
    const isMobile = useIsMobile();
    const dropdownRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();
    const [keywordFilter, setKeywordFilter] = useState<string[]>([]);
    const [influencerFilter, setInfluencerFilter] = useState<InfluencerCategory[]>([]);
    const [influencerFilterWithoutCategory, setInfluencerFilterWithoutCategory] = useState<Influencer[]>([]);
    // const [tokenFilter, setTokenFilter] = useState<TokenCategory[]>([]);
    const [tokenFilter, setTokenFilter] = useState<string[]>([]);
    const [categoryFilter, setCategoryFilter] = useState<string[]>([]);
    const [tokenFilterNew, setTokenFilterNew] = useState<Token[]>([]);
    const [activeAllTweetsTimeWindow, setActiveAllTweetsTimeWindow] = useState('4hr')
    const [tweetSummaryLoader, setTweetSummaryLoader] = useState(false);
    const [tweetSummary, SetTweetSummary] = useState<TweetSummaryData[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [copySuccess, setCopySuccess] = useState(false);
    const [copySuccessInd, setCopySuccessInd] = useState<{ [key: number]: boolean }>({});
    const [selectedTweets, setSelectedTweets] = useState<{ [key: number]: boolean }>({});
    const [isInfluencerWatchlistChecked, setIsInfluencerWatchlistChecked] = useState(false);
    const [isTokenWatchlistChecked, setIsTokenWatchlistChecked] = useState(false);
    const [isTokenOnlyChecked, setIsTokenOnlyChecked] = useState(false);

    const { address, isConnected } = useAccount()

    const [showTweetModal, setShowTweetModal] = useState(false);
    const [viewTweetId, setViewTweetId] = useState();
    const [userSelectedTweet, setUserSelectedTweet] = useState<any | null>(null);

    const [showTweetSelectModal, setShowTweetSelectModal] = useState(false);


    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);


    // Get Today's date
    const today = new Date();
    const [pastToDate, setPastToDate] = useState<Date | null>(today);

    // Get yesterday's date
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const [pastDate, setPastDate] = useState<Date | null>(yesterday);
    // const handlePastDateChange = (newValue: Date | [Date, Date] | null) => {
    //     setPastDate(newValue);
    // };


    // const handleDatePickerChange = (newValue: Date | [Date, Date] | null | undefined) => {
    //     // Handle undefined case here, or provide a default value
    //     if (newValue === undefined) {
    //         // Set a default value or handle as needed
    //         setPastDate(yesterday);
    //     } else {
    //         handlePastDateChange(newValue);
    //     }
    // };


    //Filter
    const [filters, setFilters] = useState<Filters>({ tokens: [], keywords: [], influencer: [] });
    const [filteredTokens, setFilteredTokens] = useState<string[]>([]);
    const [filteredKeywords, setFilteredKeywords] = useState<string[]>([]);
    const [selectedInfluencers, setSelectedInfluencers] = useState<string[]>([]);

    const handleTokenSelectionChange = (selectedValues: string[]) => {
        setFilteredTokens(selectedValues)
    };

    const handleSelectedItemsChange = (selectedItems: string[]) => {
        setSelectedInfluencers(selectedItems);
    };

    const handleKeywordSelectionChange = (selectedValues: string[]) => {
        setFilteredKeywords(selectedValues)
    };




    const [selectedOption, setSelectedOption] = useState<any | null>(null);
    const [selectedInfluencerOption, setSelectedInfluencerOption] = useState<any | null>(null);
    const [selectedKeywordsOption, setSelectedKeywordsOption] = useState<any | null>(null);



    const get_all_token_filter = async (timeWindow: any) => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "home/filters?time_window=" + timeWindow, {
                method: "GET",
                mode: "cors",
                headers: new Headers({ "content-type": "application/json" })
            });
            if (response.status === 200) {
                const data = await response.json();

                setFilters(data.filters);
                // setTokenFilterNew(data['filters']['tokens'])
                // const categoriesWithTokens = transformTokensToCategories(data['filters']['tokens']);
                // setTokenFilter(categoriesWithTokens);
                // Define the desired order
                const desiredOrder = ["Ethereum", "Solana", "Base", "Arbitrum One", "Optimism", "Polygon POS", "Polygon zkEVM", "TON", "Avalanche", "BNB Smart Chain", "zkSync", "Aptos", "Sui", "Fantom", "Cosmos", "Hyperliquid", "Linea", "Arbitrum Nova",  "BNB Beacon Chain"];

                // Sort the ecosystem array
                const sortedEcosystem = data['filters']['ecosystem'].sort((a: string, b: string) => {
                    const indexA = desiredOrder.indexOf(a);
                    const indexB = desiredOrder.indexOf(b);

                    // If either index is -1, move it to the end of the sorted array
                    if (indexA === -1) return 1;
                    if (indexB === -1) return -1;

                    return indexA - indexB;
                });

                // Set the state with the formatted data
                setTokenFilter(sortedEcosystem);
                setInfluencerFilterWithoutCategory(data['filters']['influencer'])

                setKeywordFilter(data['filters']['keywords']);
                setCategoryFilter(data['filters']['mmt_categories'])
                const categoriesWithInfluencers = transformInfluencersToCategories(data['filters']['influencer']);
                // Set the state with the formatted data
                setInfluencerFilter(categoriesWithInfluencers);
            } else {
                console.log(response.status);
            }
        } catch (e: any) {
            console.log(e);
        }
    }


    const get_tweet_summary = async (token: any, keyword: any, influencer: any, start_date: any, end_date: any, time_window: any, page: any) => {
        setIsLoading(true);

        const walletIdParam = isConnected ? `&wallet_id=${address}` : '';
        const watchlistParam = isInfluencerWatchlistChecked ? '&influencer_watchlist=True' : '&influencer_watchlist=False';
        const tokenWatchlistParam = isTokenWatchlistChecked ? '&token_watchlist=True' : '&token_watchlist=False';
        const tokenOnlyParam = isTokenOnlyChecked ? '&only_tokens=True' : '&only_tokens=False';


        let startDateParam;
        let endDateParam;
        let timeWindowParam;
        if (selectedFilter === 'Today') {
            startDateParam = '';
            endDateParam = '';
            timeWindowParam = time_window ? `&time_window=${time_window}` : '';
        } else {
            startDateParam = start_date ? `&start_date=${start_date}` : '';
            endDateParam = end_date ? `&end_date=${end_date}` : '';
            timeWindowParam = '';
        }

        // Constructing requestBody based on the provided inputs
        const requestBody = {
            token: token ? token.join(',') : null,
            keyword: keyword ? keyword.join(',') : null,
            influencer: influencer ? influencer.join(',') : null,
            mmt_category: (!token || token.length === 0) && filteredCategories ? filteredCategories.join(',') : null,
            ecosystem: (!token || token.length === 0) && filteredEcosystems ? filteredEcosystems.join(',') : null
        };

        const url = `${process.env.REACT_APP_API_URL}detail/tweets-summary?${startDateParam}${endDateParam}${timeWindowParam}&page=${page}&wallet_id=${walletIdParam}${watchlistParam}${tokenWatchlistParam}${tokenOnlyParam}`;


        try {
            const response = await fetch(url, {
                method: "POST",
                mode: "cors",
                headers: new Headers({ "Content-Type": "application/json" }),
                body: JSON.stringify(requestBody) // Sending requestBody as JSON string
            });

            if (response.status === 200) {
                const data = await response.json();
                SetTweetSummary(data['data'])
                setTotalPages(data['paging']['total_pages']);
                setCurrentPage(data['paging']['current_page']);
            } else {
                console.log(response.status);
                SetTweetSummary([]);
            }
        } catch (e) {
            console.log(e);
            SetTweetSummary([]);
        } finally {
            setIsLoading(false);
        }
    };





    const renderPageNumbers = () => {
        const pages = [];
        for (let i = 1; i <= totalPages; i++) {
            // Always show the first and last page numbers
            if (i === 1 || i === totalPages) {
                pages.push(
                    <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
                        <a onClick={() => setCurrentPage(i)} className="page-link" href="#">
                            {i}
                        </a>
                    </li>
                );
            }
            // Show ... when there is a gap
            else if (i === 2 && currentPage > 3) {
                pages.push(<li key="start-ellipsis" className="page-item disabled"><span className="page-link">...</span></li>);
            }
            // Show the current page and the two pages before and after it
            else if (i >= currentPage - 1 && i <= currentPage + 1) {
                pages.push(
                    <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
                        <a onClick={() => setCurrentPage(i)} className="page-link" href="#">
                            {i}
                        </a>
                    </li>
                );
            }
            // Show ... when there is a gap
            else if (i === totalPages - 1 && currentPage < totalPages - 2) {
                pages.push(<li key="end-ellipsis" className="page-item disabled"><span className="page-link">...</span></li>);
            }
        }
        return pages;
    };


    const [selectedFilter, setSelectedFilter] = useState('Today');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    // Handle radio button change
    const handleFilterChange = (event: any) => {
        const newFilter = event.target.value;
        setSelectedFilter(newFilter);
    };

    const handlePastDateChange = (date: Date | null) => {
        setPastDate(date);
        const endDate = date ? new Date(date) : yesterday;
        endDate.setDate(endDate.getDate() + 1);
        setStartDate(formatDate(date));
        // setEndDate(formatDate(endDate));
    };

    const handlePastToDateChange = (date: Date | null) => {
        setPastToDate(date);
        const endDate = date ? new Date(date) : yesterday;
        endDate.setDate(endDate.getDate() + 1);
        // setStartDate(formatDate(date || yesterday));
        setEndDate(formatDate(date));
    };


    const formatDate = (date: any) => {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${month}-${day}-${year}`;
    };

    const formatDateToDisplay = (dateString: any) => {
        const date = new Date(dateString);

        // Options for time
        const timeOptions = {
            hour: 'numeric' as const,
            minute: 'numeric' as const,
            hour12: true
        };
        const formattedTime = date.toLocaleTimeString('en-US', timeOptions);

        // Options for date
        const dateOptions = {
            month: 'short' as const,
            day: '2-digit' as const,
            year: 'numeric' as const
        };
        const formattedDate = date.toLocaleDateString('en-US', dateOptions);

        return `${formattedTime} · ${formattedDate}`;
    };

    const convertToLocalTime = (isoDate: string) => {
        // Add 'Z' at the end of the ISO date to indicate UTC time
        isoDate = isoDate + 'Z';
        const date = parseISO(isoDate);
        return format(date, "h:mm a · MMM d, yyyy");
    };


    const copyIndSummaryToClipboard = (summary: any, index: any) => {
        // Prepare tokens mentioned in a comma-separated format
        const tokensMentioned = summary['crypto_symbol'].join(', ');
    
        // Build the individual summary text with tokens mentioned and the reference URL
        const summaryText = `${summary['user_username']}: ${summary['tldr']}\nTokens Mentioned: ${tokensMentioned}\nReference - https://twitter.com/${summary['user_username']}/status/${summary['tweet_id']}\nPowered By: Makemytweet.com`;
    
        navigator.clipboard.writeText(summaryText).then(() => {
            console.log('Copied to clipboard successfully!');
            setCopySuccessInd(prevState => ({ ...prevState, [index]: true }));
            setTimeout(() => setCopySuccessInd(prevState => ({ ...prevState, [index]: false })), 2000);
        }, (err) => {
            console.error('Failed to copy: ', err);
        });
    };
    

    const copyAllSummaryToClipboard = () => {
        let summaryText = ''; // Initialize summary text
        tweetSummary.forEach((summary) => {
            // Prepare tokens mentioned in a comma-separated format
            const tokensMentioned = summary['crypto_symbol'].join(', ');
    
            // Build the summary line with tokens mentioned before the reference
            summaryText += `${summary['user_username']}: ${summary['tldr']}\nTokens Mentioned: ${tokensMentioned}\nReference - https://twitter.com/${summary['user_username']}/status/${summary['tweet_id']}\n\n`;
        });
    
        // Append the Powered By message at the end
        summaryText += 'Powered By: Makemytweet.com';
    
        navigator.clipboard.writeText(summaryText).then(() => {
            console.log('Copied to clipboard successfully!');
            setCopySuccess(true);
            setTimeout(() => setCopySuccess(false), 2000);
        }, (err) => {
            console.error('Failed to copy: ', err);
        });
    };
    
    const handleCopy = () => {
        const selectedSummaries = tweetSummary
            .filter((_, index) => selectedTweets[index])
            .map(summary => `${summary.user_username}: ${summary.tldr}\nReference - https://twitter.com/${summary.user_username}/status/${summary.tweet_id}`)
            .join('\n\n');

        navigator.clipboard.writeText(selectedSummaries).then(() => {
            console.log('Copied to clipboard successfully!');
            setCopySuccess(true);
            setTimeout(() => setCopySuccess(false), 2000);
        }, (err) => {
            console.error('Failed to copy: ', err);
        });
    };

    const handleCheckboxChange = (index: any) => {
        setSelectedTweets(prevState => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };
    // Handle checkbox change
    const handleInfluencerCheckboxChange = (e: any) => {
        if (!isConnected) {
            // Optionally: Provide feedback to the user
            toast.error('Please connect your account to use this feature.', {
                style: {
                    background: 'rgba(0, 29, 255, 0.20)',
                    color: '#fff'
                }
            });
            return; // Prevent checkbox from being toggled
        }
        setIsInfluencerWatchlistChecked(e.target.checked);
    };

    const handleTokenCheckboxChange = (e: any) => {
        if (!isConnected) {
            // Optionally: Provide feedback to the user
            toast.error('Please connect your account to use this feature.', {
                style: {
                    background: 'rgba(0, 29, 255, 0.20)',
                    color: '#fff'
                }
            });
            return; // Prevent checkbox from being toggled
        }
        setIsTokenWatchlistChecked(e.target.checked);
    };

    const handleTokenOnlyCheckboxChange = (e: any) => {
        if (!isConnected) {
            // Optionally: Provide feedback to the user
            toast.error('Please connect your account to use this feature.', {
                style: {
                    background: 'rgba(0, 29, 255, 0.20)',
                    color: '#fff'
                }
            });
            return; // Prevent checkbox from being toggled
        }
        setIsTokenOnlyChecked(e.target.checked);
    };


    const view_tweet = async (tweet_id: any) => {

        const url = `${process.env.REACT_APP_API_URL}search/tweet?wallet_id=${address}`;
        const payload = {
            tweet_ids: [tweet_id]
        };

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(payload)
            });

            if (response.ok) {
                const data = await response.json();
                const tweetData = data.tweets[tweet_id];
                if (tweetData.publishable) {
                    setUserSelectedTweet({
                        complete_text: tweetData.tweet.complete_text,
                        tweet_id: tweetData.tweet.id,
                        publishable: true
                    });
                } else {
                    setUserSelectedTweet({
                        complete_text: tweetData.tweet.complete_text,
                        tweet_id: tweetData.tweet.id,
                        publishable: false
                    });
                }
                setViewTweetId(tweet_id);
                setShowTweetModal(true)
            } else {
                console.error("Failed to fetch publishable status", response.status);
            }
        } catch (error) {
            console.error("Error fetching publishable status", error);
        }
    }

    const [profileDetails, setProfileDetails] = useState<InfluencerProfile | null>(null);
    const [show, setShow] = useState(false);
    const [target, setTarget] = useState(null);
    const [loadingProfile, setLoadingProfile] = useState(false);
    const [isPopoverHovered, setIsPopoverHovered] = useState(false);
    const ref = useRef(null);

    const fetchProfileDetails = async (username: any) => {
        setLoadingProfile(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}influencer/info?influencer=${username}`, {
                method: 'GET',
            });
            const data = await response.json();
            setProfileDetails(data['data']);
        } catch (error) {
            console.error("Error fetching profile details:", error);
        } finally {
            setLoadingProfile(false);
        }
    };

    const handleMouseEnter = (event: any, username: any) => {
        setTarget(event.target);
        setShow(true);
        fetchProfileDetails(username);
    };

    const handleMouseLeave = () => {
        setTimeout(() => {
            if (!isPopoverHovered) {
                setShow(false);
                setProfileDetails(null);
            }
        }, 100);
    };

    const handlePopoverMouseEnter = () => {
        setIsPopoverHovered(true);
    };

    const handlePopoverMouseLeave = () => {
        setIsPopoverHovered(false);
        setShow(false);
    };

    const handleLinkClick = (event: any) => {
        event.stopPropagation();
    };

    //Ecosystem and Category Filter Group
    const ecosystemFilterRef = useRef<{ resetFilter: () => void }>(null);
    const categoryFilterRef = useRef<{ resetFilter: () => void }>(null);
    const tokenFilterRef = useRef<{ resetFilter: () => void }>(null);
    const influencerFilterRef = useRef<{ resetFilter: () => void }>(null);
    const keywordFilterRef = useRef<{ resetFilters: () => void }>(null);
    const [selectedFilterType, setSelectedFilterType] = useState('all');
    const [isEcosystemFilterApplied, setIsEcosystemFilterApplied] = useState(false);
    const [isCategoryFilterApplied, setIsCategoryFilterApplied] = useState(false);
    const [filteredCategories, setFilteredCategories] = useState<string[]>([]);
    const [filteredEcosystems, setFilteredEcosystems] = useState<string[]>([]);


    const handleResetClick = () => {
        if (tokenFilterRef.current) {
            tokenFilterRef.current.resetFilter();
        }
    };

    const handleResetEcosystemFilters = () => {
        if (ecosystemFilterRef.current) {
            ecosystemFilterRef.current.resetFilter();
        }
    };

    const handleResetCategoryFilters = () => {
        if (categoryFilterRef.current) {
            categoryFilterRef.current.resetFilter();
        }
    };

    const handleResetAllFilters = () => {
        if (ecosystemFilterRef.current) {
            ecosystemFilterRef.current.resetFilter();
        }
        if (categoryFilterRef.current) {
            categoryFilterRef.current.resetFilter();
        }
        if (tokenFilterRef.current) {
            tokenFilterRef.current.resetFilter();
        }
        if (keywordFilterRef.current) {
            keywordFilterRef.current.resetFilters();
        }
        if (influencerFilterRef.current) {
            influencerFilterRef.current.resetFilter();
        }
        // Additional reset logic if required
    };

    const handleFilterTypeRadioChange = (event: any) => {
        setSelectedFilterType(event.target.value);
    };

    const handleEcosystemSelectionChange = (selectedValues: string[]) => {
        setFilteredEcosystems(selectedValues)
        setIsEcosystemFilterApplied(selectedValues.length > 0);
        handleResetClick();
    };

    const handleCategorySelectionChange = (selectedValues: string[]) => {
        setIsCategoryFilterApplied(selectedValues.length > 0);
        setFilteredCategories(selectedValues)
        handleResetClick();
    };

    const fetch_token_based_on_ecosystem_and_mmtcategory = async (category: any, ecosystem: any) => {
        const url = `${process.env.REACT_APP_API_URL}home/filters-pinpoint`;

        const payload = JSON.stringify({
            mmt_categories: category ? category.join(',') : null,
            ecosystem: ecosystem ? ecosystem.join(',') : null
        });

        const headers = {
            'Content-Type': 'application/json'
        };

        try {
            const res = await fetch(url, {
                method: 'POST',
                headers: headers,
                body: payload
            });

            const data = await res.json();
            setTokenFilterNew(data['data']);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    /**  */

    useEffect(() => {
        const today = new Date();
        let start;
        let end;

        if (selectedFilter === 'Today') {
            // start = new Date(); // Today
            // end = new Date();
            // end.setDate(end.getDate() + 1); // Tomorrow
            setStartDate('');
            setEndDate('');

        } else if (selectedFilter === 'Past') {
            start = new Date();
            start.setDate(start.getDate() - 1); // Yesterday
            setPastDate(start)
            end = new Date(); // Today
            setStartDate(formatDate(start));
            setEndDate(formatDate(end));
        }



    }, [selectedFilter]);


    useEffect(() => {
        console.log(startDate, endDate)
        get_tweet_summary(filteredTokens, filteredKeywords, selectedInfluencers, startDate, endDate, activeAllTweetsTimeWindow, currentPage)
    }, [filteredTokens, filteredKeywords, selectedInfluencers, startDate, endDate, activeAllTweetsTimeWindow, currentPage, isInfluencerWatchlistChecked, isTokenWatchlistChecked, isTokenOnlyChecked]);


    useEffect(() => {
        if (selectedFilterType == 'all') {
            get_tweet_summary(filteredTokens, filteredKeywords, selectedInfluencers, startDate, endDate, activeAllTweetsTimeWindow, currentPage)
        }
        if (selectedFilterType == 'token') {
            fetch_token_based_on_ecosystem_and_mmtcategory(filteredCategories, filteredEcosystems)
        }
    }, [filteredCategories, filteredEcosystems]);


    useEffect(() => {
        fetch_token_based_on_ecosystem_and_mmtcategory(filteredCategories, filteredEcosystems)
    }, []);

    useEffect(() => {
        get_all_token_filter('1month');
    }, []);

    const open_tweet_link = (tweet_id: string) => {
        console.log(tweet_id)
        window.open("https://twitter.com/CryptoNikyous/status/" + tweet_id, "_blank")
    }

    const open_influencer_detail = (id: any) => {
        window.open("/influencer-detail/" + id, "_blank")
    }


    return (
        <div className="container-fluid">
            <Toaster />
            <section className="first-section section-padding position-relative">
                {isLoading && (
                    <div className="disabled-overlay">
                        Fetching Data...
                    </div>
                )}
                <div className="container-fluid">
                    <Row>
                        <Col sm={12}>
                            <div className='d-flex'>
                                <div className='me-auto'>
                                    <div className="s-heading">
                                        Daily Tweets Summary
                                    </div>

                                </div>
                            </div>
                        </Col>
                        <Col sm={12} className="mt-3">
                            <Card className="t-w-card">
                                <Card.Body>
                                    <Row>

                                        <Col sm={12} md={12} lg={12}>
                                            <Card className="mb-3 filter-group-home">
                                                {/* <Card.Header>Filter Group</Card.Header> */}
                                                <Card.Body>
                                                    <Row className="mb-3">
                                                        <Col>
                                                            <Form>
                                                                <div className="d-inline-block me-5">
                                                                    <Form.Check
                                                                        type="radio"
                                                                        label="Search by Ecosystem/Category"
                                                                        name="filterGroup"
                                                                        value="all"
                                                                        checked={selectedFilterType === 'all'}
                                                                        onChange={handleFilterTypeRadioChange}
                                                                        className='filter-label'
                                                                    />
                                                                </div>
                                                                <div className="d-inline-block">
                                                                    <Form.Check
                                                                        type="radio"
                                                                        label="Search Token"
                                                                        name="filterGroup"
                                                                        value="token"
                                                                        checked={selectedFilterType === 'token'}
                                                                        onChange={handleFilterTypeRadioChange}
                                                                        className='filter-label'
                                                                    />
                                                                </div>
                                                            </Form>
                                                        </Col>
                                                        <Col className="d-flex justify-content-end">
                                                            <div className="reset-all-filter-btn" onClick={handleResetAllFilters}>
                                                                Reset All Filters
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>

                                                        <Col sm={12} md={4}>
                                                            <div className="filter-label">Filter by Ecosystem</div>
                                                            <EcoSystemFilter ref={ecosystemFilterRef} ecosystems={tokenFilter} onSelectionChange={handleEcosystemSelectionChange} />
                                                        </Col>
                                                        <Col sm={12} md={4}>
                                                            <div className="filter-label">Filter by Category</div>
                                                            <CategoryFilter ref={categoryFilterRef} categories={categoryFilter} onSelectionChange={handleCategorySelectionChange} />
                                                        </Col>
                                                        {selectedFilterType === 'token' && (
                                                            <Col sm={12} md={4}>
                                                                <div className="filter-label">Filter by Token</div>

                                                                {(isEcosystemFilterApplied || isCategoryFilterApplied) && (
                                                                    <div className="filter-notes">
                                                                        {isEcosystemFilterApplied && <div><Icon.FunnelFill className='me-1'></Icon.FunnelFill>Ecosystem filter applied <Icon.X size={20} className='cursor-pointer' onClick={handleResetEcosystemFilters}></Icon.X></div>}
                                                                        {isCategoryFilterApplied && <div><Icon.FunnelFill className='me-1'></Icon.FunnelFill>Category filter applied <Icon.X size={20} className='cursor-pointer' onClick={handleResetCategoryFilters}></Icon.X></div>}
                                                                    </div>
                                                                )}
                                                                <TokenFilterForHome
                                                                    tokens={tokenFilterNew}
                                                                    onSelectedItemsChange={handleTokenSelectionChange}
                                                                    ref={tokenFilterRef}
                                                                />
                                                            </Col>
                                                        )}
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </Col>


                                        {/* <Col sm={3}>
                                            <div className="filter-label">
                                                Filter by Token
                                            </div>
                                            <TokenFilter tokens={tokenFilterNew} onSelectedItemsChange={handleTokenSelectionChange} />
                                        </Col> */}
                                        <Col sm={3}>
                                            <div className="filter-label">
                                                Filter by Influencer
                                            </div>
                                            <InfluencerFilter ref={influencerFilterRef} influencers={influencerFilter} influencersWithOutCategory={influencerFilterWithoutCategory} onSelectedItemsChange={handleSelectedItemsChange} />
                                        </Col>
                                        <Col sm={3}>
                                            <div className="filter-label">
                                                Filter by Keywords
                                            </div>
                                            <KeywordFilter ref={keywordFilterRef} keywords={keywordFilter} onSelectionChange={handleKeywordSelectionChange} />
                                        </Col>
                                        <Col sm={3}>
                                            <div className="mt-3 h-100">
                                                <Form.Check
                                                    type="checkbox"
                                                    label="Influencer Watchlist"
                                                    className="watchlist-checkbox"
                                                    checked={isInfluencerWatchlistChecked}
                                                    onChange={handleInfluencerCheckboxChange}
                                                    // Disable checkbox if not connected, providing visual feedback as well
                                                    disabled={!isConnected}
                                                />
                                                <Form.Check
                                                    type="checkbox"
                                                    label="Token Watchlist"
                                                    className="watchlist-checkbox"
                                                    checked={isTokenWatchlistChecked}
                                                    onChange={handleTokenCheckboxChange}
                                                    // Disable checkbox if not connected, providing visual feedback as well
                                                    disabled={!isConnected}
                                                />
                                                <Form.Check
                                                    type="checkbox"
                                                    label="Show Token Only Summary"
                                                    className="watchlist-checkbox"
                                                    checked={isTokenOnlyChecked}
                                                    onChange={handleTokenOnlyCheckboxChange}
                                                    // Disable checkbox if not connected, providing visual feedback as well
                                                    disabled={!isConnected}
                                                />
                                            </div>
                                        </Col>
                                        <hr className='mt-4 mb-4 horizontal-line' />
                                        <Col sm={8}>

                                            <div className={isMobile ? 'align-items-center' : 'd-flex align-items-center'}>
                                                <div className='filter-label'>
                                                    Filter by Today/Past Summary:
                                                </div>
                                                <div className={isMobile ? 'switches-container ms-3 mt-3' : 'switches-container ms-3'}>

                                                    <input
                                                        type="radio"
                                                        id="switchMonthly"
                                                        name="switchPlan"
                                                        value="Today"
                                                        checked={selectedFilter === 'Today'}
                                                        onChange={handleFilterChange}
                                                    />
                                                    <input
                                                        type="radio"
                                                        id="switchYearly"
                                                        name="switchPlan"
                                                        value="Past"
                                                        checked={selectedFilter === 'Past'}
                                                        onChange={handleFilterChange}
                                                    />
                                                    <label htmlFor="switchMonthly">Today</label>
                                                    <label htmlFor="switchYearly">Past</label>
                                                    <div className="switch-wrapper">
                                                        <div className="switch">
                                                            <div>Today</div>
                                                            <div>Past</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {selectedFilter === 'Past' &&
                                                    <div className={isMobile ? 'd-flex mt-3' : 'mx-4 d-flex'}>
                                                        <div>
                                                            <label className='filter-label'>
                                                                From :
                                                            </label>
                                                            <DatePicker
                                                                selected={pastDate}
                                                                onChange={(date) => handlePastDateChange(date)}
                                                                dateFormat="dd/MM/yyyy"
                                                                maxDate={yesterday}
                                                            />
                                                        </div>
                                                        <div className='mx-3'>
                                                            <label className='filter-label'>
                                                                To:
                                                            </label>
                                                            <DatePicker
                                                                selected={pastToDate}
                                                                onChange={(date) => handlePastToDateChange(date)}
                                                                dateFormat="dd/MM/yyyy"
                                                                minDate={pastDate}
                                                                maxDate={today}

                                                            />
                                                        </div>
                                                        {/* <DatePicker selected={startDate} onChange={(date:any) => setStartDate(date)} /> */}
                                                    </div>
                                                }
                                                {selectedFilter === 'Today' &&
                                                    <div className={isMobile ? 'time-window-container d-flex mt-3' : 'time-window-container ms-4 d-flex'} >
                                                        <div onClick={() => setActiveAllTweetsTimeWindow('15min')} className={`time-window ${activeAllTweetsTimeWindow == '15min' ? 'active' : ''}`}>
                                                            15 min
                                                        </div>
                                                        <div onClick={() => setActiveAllTweetsTimeWindow('1hr')} className={`time-window ${activeAllTweetsTimeWindow == '1hr' ? 'active' : ''}`}>
                                                            1h
                                                        </div>
                                                        <div onClick={() => setActiveAllTweetsTimeWindow('4hr')} className={`time-window ${activeAllTweetsTimeWindow == '4hr' ? 'active' : ''}`}>
                                                            4h
                                                        </div>
                                                        <div onClick={() => setActiveAllTweetsTimeWindow('12hr')} className={`time-window ${activeAllTweetsTimeWindow == '12hr' ? 'active' : ''}`}>
                                                            12h
                                                        </div>
                                                        <div onClick={() => setActiveAllTweetsTimeWindow('1day')} className={`time-window ${activeAllTweetsTimeWindow == '1day' ? 'active' : ''}`}>
                                                            1d
                                                        </div>
                                                        {/* <div onClick={() => setActiveAllTweetsTimeWindow('1month')} className={`time-window ${activeAllTweetsTimeWindow == '1month' ? 'active' : ''}`}>
                                                        30d
                                                    </div> */}
                                                    </div>
                                                }
                                            </div>
                                        </Col>
                                        <Col sm={4}>
                                            <div className=''>

                                                <div className={isMobile ? 'mt-3' : 'float-end d-flex'} >
                                                    <div className="ctw-btn" onClick={() => copyAllSummaryToClipboard()}>
                                                        {copySuccess ? <Icon.Check2 size={15} /> : <Icon.Copy className='cursor-pointer' size={15} />} Copy All Summary
                                                    </div>
                                                    <div className="ctw-btn" onClick={() => setShowTweetSelectModal(true)}>
                                                        <Icon.Copy className='cursor-pointer' size={15} /> Select & Copy
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </section>
            <section className="section-padding position-relative mt-4">
                <div className="container-fluid">
                    {/* <Row>
                        <Col sm={12} className="">
                            <Card className="t-w-card">
                                <Card.Body>
                                    <Row>
                                        <Col sm={1}>
                                        </Col>
                                        <Col sm={2}>
                                            <div className='table-heading-text'>
                                                Tokens
                                            </div>
                                        </Col>
                                        <Col sm={1}>
                                            <div className='table-heading-text'>
                                                Total Tweets
                                            </div>
                                        </Col>
                                        <Col sm={5}>
                                            <div className='table-heading-text'>
                                                Tweet Link
                                            </div>
                                        </Col>
                                        <Col sm={2}>
                                            <div className='table-heading-text'>
                                                Links
                                            </div>
                                        </Col>
                                        <Col sm={1}>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row> */}
                    <Row>
                        <Col sm={12} className='mt-3'>
                            <>
                                {!isLoading && (
                                    <>
                                        <div className={isMobile ? 'atw-table-container table-responsive-md' : 'atw-table-container'}>

                                            <Table className='atw-table'>
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th>Influencer handle</th>
                                                        <th>Token List</th>
                                                        <th>Tldr</th>
                                                        <th> </th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {tweetSummary.length > 0 ? (
                                                        tweetSummary.map((summary, index) => (
                                                            <tr key={index}>
                                                                <td width={'2%'}>{index + 1}</td>
                                                                <td width={'28%'} className='cursor-pointer'
                                                                    onClick={() => open_influencer_detail(summary['user_username'])}
                                                                >
                                                                    <div className='d-flex align-items-center'
                                                                    >
                                                                        <div onMouseEnter={(e) => handleMouseEnter(e, summary['user_username'])}
                                                                            onMouseLeave={handleMouseLeave}>
                                                                            <img src={summary['user_profile_image_url']} className="t-d-user-img" />
                                                                        </div>
                                                                        <div>
                                                                            <div className="ms-1 ticker-name">{summary['user_name']}</div>
                                                                            <div className="ms-1 ticker-symbol">@{summary['user_username']}</div>
                                                                            {summary['user_tag'] &&
                                                                                <div className='influencer-tag'>{summary['user_tag']}</div>
                                                                            }
                                                                        </div>
                                                                    </div>

                                                                    <Overlay target={target} show={show} placement="top" container={ref.current}>
                                                                        <Popover id="popover-basic" className="popover-custom" onMouseEnter={handlePopoverMouseEnter} onMouseLeave={handlePopoverMouseLeave}>
                                                                            <Popover.Header as="h3">Profile Details</Popover.Header>
                                                                            <Popover.Body>
                                                                                {loadingProfile ? (
                                                                                    <div>Loading...</div>
                                                                                ) : (
                                                                                    <div>
                                                                                        <p><strong>Bio:</strong> {profileDetails?.description}</p>
                                                                                        {profileDetails?.url && (
                                                                                            <p>
                                                                                                <strong>URL:</strong> <a href={profileDetails.url} onClick={handleLinkClick} target="_blank" rel="noopener noreferrer">{profileDetails.url}</a>
                                                                                            </p>
                                                                                        )}
                                                                                        {profileDetails?.entities?.description?.mentions && profileDetails.entities.description.mentions.length > 0 && (
                                                                                            <p>
                                                                                                <strong>Mentions:</strong>{' '}
                                                                                                {profileDetails.entities.description.mentions.map((mention, index) => (
                                                                                                    <span key={index}>
                                                                                                        <a href={`https://twitter.com/${mention.username}`} target="_blank" rel="noopener noreferrer">
                                                                                                            @{mention.username}
                                                                                                        </a>
                                                                                                        {index < profileDetails.entities.description.mentions.length - 1 && ', '}
                                                                                                    </span>
                                                                                                ))}
                                                                                            </p>
                                                                                        )}
                                                                                    </div>
                                                                                )}
                                                                            </Popover.Body>
                                                                        </Popover>
                                                                    </Overlay>
                                                                </td>
                                                                <td width={'20%'}>
                                                                    <CryptoSymbols summary={summary} />
                                                                </td>
                                                                <td width={'40%'}>
                                                                    {summary['tldr']} <span className='view-tweet-link' onClick={() => view_tweet(summary['tweet_id'])}>View Tweet</span>
                                                                    <div className="tweet-date">
                                                                        {convertToLocalTime(summary['tweet_created_at'])}
                                                                    </div>
                                                                </td>
                                                                <td width={'10%'}>
                                                                    <div className='copy-summary-icon cursor-pointer' onClick={() => copyIndSummaryToClipboard(summary, index)}>
                                                                        {copySuccessInd[index] ? <Icon.Check2 size={15} /> : <Icon.Copy className='cursor-pointer' size={15} />}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        // Render this row if allTokenTweets is empty
                                                        <tr>
                                                            <td colSpan={4} style={{ textAlign: 'center' }}>No data available</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </Table>

                                            <Modal className='tweet-modal modal-lg' show={showTweetSelectModal} onHide={() => setShowTweetSelectModal(false)} centered>
                                                <Modal.Header className="justify-content-between">
                                                    <div></div>
                                                    <Icon.XCircle size={30} className='modal-close-icon' onClick={() => setShowTweetSelectModal(false)}></Icon.XCircle>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <div className="copy-modal-body-scrollable">
                                                        {tweetSummary.map((summary, index) => (
                                                            <div key={index} className="tweet-summary">
                                                                <input
                                                                    type="checkbox"
                                                                    checked={!!selectedTweets[index]}
                                                                    onChange={() => handleCheckboxChange(index)}
                                                                />
                                                                <label>
                                                                    {summary.user_username}: {summary.tldr}
                                                                </label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <div onClick={handleCopy} className="ctw-btn mt-3">
                                                        {copySuccess ? <Icon.Check2 size={15} /> : <Icon.Copy className='cursor-pointer' size={15} />} Copy Selected Summary
                                                    </div>
                                                </Modal.Body>
                                            </Modal>

                                            <Modal className='tweet-modal' show={showTweetModal} onHide={() => setShowTweetModal(false)} centered>
                                                <Modal.Header className="justify-content-between">
                                                    <div></div>
                                                    <Icon.XCircle size={30} className='modal-close-icon' onClick={() => setShowTweetModal(false)}></Icon.XCircle>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    {viewTweetId &&
                                                        <div data-theme="dark" className='modal-tweet-container'>
                                                            {userSelectedTweet && userSelectedTweet.publishable == true ? (

                                                                <Tweet id={userSelectedTweet.tweet_id} />
                                                            ) : (
                                                                <p className='tweet-complete-text'>{userSelectedTweet?.complete_text}</p>
                                                            )}
                                                        </div>
                                                    }
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    {viewTweetId &&
                                                        <Button className='go-to-tweet-btn' variant="secondary" onClick={() => open_tweet_link(viewTweetId)}>
                                                            Go To Tweet
                                                        </Button>
                                                    }
                                                </Modal.Footer>
                                            </Modal>
                                        </div>
                                        <div>
                                            {totalPages > 1 &&
                                                <nav aria-label="Page navigation" className='d-flex justify-content-end'>
                                                    <ul className="pagination">
                                                        {currentPage > 1 && (
                                                            <li className="page-item">
                                                                <a className="page-link" onClick={() => setCurrentPage(currentPage - 1)} href="#" aria-label="Previous">
                                                                    <span aria-hidden="true">Previous</span>
                                                                </a>
                                                            </li>
                                                        )}
                                                        {renderPageNumbers()}
                                                        {currentPage < totalPages && (
                                                            <li className="page-item">
                                                                <a className="page-link" onClick={() => setCurrentPage(currentPage + 1)} href="#" aria-label="Next">
                                                                    <span aria-hidden="true">Next</span>
                                                                </a>
                                                            </li>
                                                        )}
                                                    </ul>
                                                </nav>
                                            }
                                        </div>
                                    </>
                                )}

                                {isLoading && (
                                    <div className='mx-auto'>
                                        <Spinner animation="border" role="status" variant="light">
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner>
                                    </div>

                                )}
                            </>
                        </Col>
                    </Row>
                </div>
            </section>
        </div>
    )
}
export default DailySummary;