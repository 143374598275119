import React, { useState, useRef, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AppRoutes } from "./app-routes";
import { createWeb3Modal } from '@web3modal/wagmi1/react';
import { walletConnectProvider, EIP6963Connector } from '@web3modal/wagmi1';
import { WagmiConfig, configureChains, createConfig } from 'wagmi';
import { publicProvider } from 'wagmi/providers/public';
import { mainnet, polygonMumbai } from 'viem/chains'; // Import polygonMumbai
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet';
import { InjectedConnector } from 'wagmi/connectors/injected';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';


import logo from './logo.svg';
import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";

const projectId = '4b729f385bee17679ee5179cda8ad97e';

// Define Mumbai Testnet information (if not already defined in 'viem/chains')
// const polygonMumbai = {
//   id: 80001,
//   name: 'Polygon Mumbai Testnet',
//   network: 'mumbai',
//   nativeCurrency: {
//     name: 'MATIC',
//     symbol: 'MATIC',
//     decimals: 18,
//   },
//   rpcUrls: {
//     default: 'https://rpc-mumbai.maticvigil.com/',
//   },
//   blockExplorers: {
//     default: { name: 'PolygonScan', url: 'https://mumbai.polygonscan.com' },
//   },
//   testnet: true,
// };

// 2. Create wagmiConfig with Mumbai Testnet
const { chains, publicClient } = configureChains(
  [mainnet, polygonMumbai], // Use polygonMumbai here
  [walletConnectProvider({ projectId }), publicProvider()]
);

const metadata = {
  name: 'Web3Modal',
  description: 'Web3Modal Example',
  url: 'https://web3modal.com',
  icons: ['https://avatars.githubusercontent.com/u/37784886']
};

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: [
    new WalletConnectConnector({ chains, options: { projectId, showQrModal: false, metadata } }),
    new EIP6963Connector({ chains }),
    new InjectedConnector({ chains, options: { shimDisconnect: true } }),
    new CoinbaseWalletConnector({ chains, options: { appName: metadata.name } })
  ],
  publicClient
});

// 3. Create modal
createWeb3Modal({ wagmiConfig, projectId, chains });



function App() {

  return (
    <WagmiConfig config={wagmiConfig}>
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
    </WagmiConfig>
  );
}

export default App;