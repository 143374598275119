import React, { useState, useRef, useEffect } from 'react';
import { Row, Col, Card, Form, Table, Spinner, OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import toast, { Toaster } from 'react-hot-toast';
import { useAccount } from 'wagmi'
import Swal from 'sweetalert2';

const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return isMobile;
};


interface Bots {
    wallet_id: string;
    name: string;
    oauth_token: string;
    id: string;
}

export const Oauth = () => {
    const { address, isConnected } = useAccount()
    const isMobile = useIsMobile();
    const [allBotloader, setAllBotloader] = useState(false);
    const [allBots, setAllBots] = useState<Bots[]>([]);
    const [redirectUrl, setRedirectUrl] = useState<string | null>(null);


    const fetchCallbackUrl = async (name: string) => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/oauth/generate_callback?wallet_id=${address}&shill_account_name=${name}`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            toast.success('Callback URL is fetched and please proceed with Oauth', {
                style: {
                    background: 'rgba(0, 29, 255, 0.20)',
                    color: '#fff',
                },
            });
            const data = await response.json();
            if (data.data.redirect_url) {
                window.location.href = data.data.redirect_url;
            }
        } catch (error) {
            console.error('Error fetching the callback URL:', error);
        }
    };


    const handleProceedClick = () => {
        Swal.fire({
            title: 'Enter Account Name',
            input: 'text',
            inputPlaceholder: 'Enter your account name',
            showCancelButton: true,
            confirmButtonText: 'Proceed',
            preConfirm: (name) => {
                if (!name) {
                    Swal.showValidationMessage('You need to enter an account name!');
                }
                return name;
            },
        }).then((result) => {
            if (result.isConfirmed && result.value) {
                fetchCallbackUrl(result.value);
            }
        });
    };

    const get_all_bot = async () => {
        setAllBotloader(true);
        setAllBots([]);
        const walletIdParam = isConnected ? `&wallet_id=${address}` : '';

        const url = `${process.env.REACT_APP_API_URL}oauth/list?wallet_id=${walletIdParam}`;

        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    // Include any other headers if needed
                }),
            });

            if (response.ok) {
                const data = await response.json();
                setAllBots(data['data']);
            } else {
                setAllBots([]);
            }
        } catch (e) {
            console.log(e);
            setAllBotloader(false);
            setAllBots([]);
        } finally {
            setAllBotloader(false);
        }
    };

    useEffect(() => {
        get_all_bot();
    }, []);


    return (
        <>
            <div className="container-fluid">
                <Toaster />
                <section className="section-padding position-relative mt-4">
                    <div className="container-fluid">
                        <Row>
                            <Col sm={12}>
                                <div className='me-auto'>
                                    <div className="s-heading">
                                        Bot List
                                    </div>

                                </div>
                            </Col>
                            <Col sm={3} className="mt-3">


                                <div>
                                    <div className='ctw-btn mt-2' onClick={handleProceedClick}>Proceed to OAuth</div>

                                </div>

                            </Col>

                            <Col sm={12} className='mt-3'>

                                {allBotloader &&
                                    <Spinner animation="border" role="status" variant="light" >
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                }
                                {!allBotloader &&
                                    <>
                                        <div className={isMobile ? 'atw-table-container table-responsive-md' : 'atw-table-container'}>
                                            <Table className='atw-table'>
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Id</th>
                                                        <th>Name</th>
                                                        <th>Oauth Token</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {allBots.length > 0 ? (
                                                        allBots.map((bots, index) => (
                                                            <tr key={index} className={allBots.length === 1 ? 'custom-single-row-height' : ''}>
                                                                <td width={'2%'}>
                                                                    <div className='d-flex align-items-center gap-2'>
                                                                        {index + 1}
                                                                    </div>
                                                                </td>
                                                                <td className='cursor-pointer'>
                                                                    {bots['id']}
                                                                </td>
                                                                <td >{bots['name']}</td>
                                                                <td>
                                                                    {bots['oauth_token']}
                                                                </td>

                                                            </tr>
                                                        ))
                                                    ) : (
                                                        // Render this row if allTokenTweets is empty
                                                        <tr>
                                                            <td colSpan={7} style={{ textAlign: 'center' }}>No data available</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </>}
                            </Col>
                        </Row>
                    </div>
                </section >
            </div >
        </>
    )
};
export default Oauth;
