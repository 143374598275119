import React, { useState, useEffect } from 'react';
import { Toaster, toast } from 'react-hot-toast';
import { requestPermission, onMessageListener } from '../firebase/firebase';


function Notification() {
    const [notification, setNotification] = useState({ title: '', body: '', link: '', icon: '' });


    useEffect(() => {
        // requestPermission();

        // Setup the message listener with a callback function
        const unsubscribe = onMessageListener((payload) => {
            setNotification({ title: payload?.notification?.title, body: payload?.notification?.body, link: payload?.data?.url, icon: payload?.notification?.icon });
            // ToastDisplay()
            // Display a toast notification for each message
            // playNotificationSound()
            // const clickActionUrl = payload?.data?.url;
            // toast.success(<ToastDisplay />)
            // toast.success(`${payload?.notification?.title}: ${payload?.notification?.body}`, {
            //     duration: 5000,
            //     style: {
            //         background: 'rgba(0, 29, 255, 0.20)',
            //         color: '#fff'
            //     },
            //     onClick: () => {
            //         if (clickActionUrl) {
            //             console.log("ddd")
            //             const anchor = document.createElement('a');
            //             anchor.href = clickActionUrl;
            //             anchor.target = '_blank';
            //             anchor.rel = 'noopener noreferrer';
            //             document.body.appendChild(anchor); // Append to body to ensure it's in the DOM
            //             anchor.click(); // Simulate a click
            //             document.body.removeChild(anchor); // Clean up
            //         }
            //     }
            // });
        });

        // Cleanup the listener when the component unmounts
        return () => unsubscribe;
    }, []);



    useEffect(() => {
        if (notification?.title) {
            toast(<ToastDisplay />, {
                style: {
                    background: 'rgba(0, 29, 255, 0.20)',
                    color: '#fff'
                }
            });
        }
    }, [notification])

    function ToastDisplay() {
        return (
            <div className='foreground-notification d-flex' onClick={() => handleClick(notification.link)}>
                <div>
                    <img src={notification.icon} />
                </div>
                <div>
                    <p><b>{notification.title}</b></p>
                    <p>{notification.body}</p>
                </div>

            </div>
        );
    };


    const handleClick = (link) => {
        window.open(link, '_blank', 'noopener,noreferrer');
        // Your click action here
    };


    const playNotificationSound = () => {
        console.log("play notification")
        const audio = new Audio('../assets/audio/notification-sound.mp3');
        audio.play().catch(error => console.error('Error playing the notification sound:', error));
    };

    return (
        <div>
            <Toaster />
        </div>
    );
}

export default Notification;
