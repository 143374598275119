import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import * as Icon from 'react-bootstrap-icons';
import { Row, Col, Card, Form, Table, Spinner, OverlayTrigger, Button, Modal } from "react-bootstrap";
import { useAccount } from 'wagmi'
import { Tweet } from 'react-tweet';
import TokenFilter from '../components/TokenFilter';
import { transformInfluencersToCategories, transformTokensToCategories } from '../components/utils';
import { Influencer, InfluencerCategory, Token, TokenCategory } from '../components/filterTypes';
import no_img from "../assets/images/no-image-icon.png"
import './admin.css'



interface InfluencerInterface {
    name: string;
    user_verified: boolean;
    user_created_at: string;
    profile_image_url: string;
    user_location: string;
    user_id: string;
    description: string;
    username: string;
    category: string;
    tag: string;
    id: string;
}

const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);


    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return isMobile;
};


export const InfluencerList = () => {
    const isMobile = useIsMobile();
    const navigate = useNavigate();
    const { address, isConnected } = useAccount()


    const [allInfluencers, setAllInfluencers] = useState<InfluencerInterface[]>([]);
    const [allTweetloader, setAllTweetloader] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const [selectedInfluencer, setSelectedInfluencer] = useState<InfluencerInterface | null>(null);
    const [loading, setLoading] = useState(false);
    const [showInfluencerUpdateModal, setShowInfluencerUpdateModal] = useState(false);
    const [tag, setTag] = useState<string>('');
    const [category, setCategory] = useState<string>('');

    const searchBarRef = useRef<HTMLDivElement>(null);
    const [searchQuery, setSearchQuery] = useState('');

    const edit_influencer = (influencer: InfluencerInterface) => {
        setSelectedInfluencer(influencer);
        setTag(influencer.tag)
        setCategory(influencer.category)
        setShowInfluencerUpdateModal(true);
    };


    const handleSaveChanges = () => {
        if (selectedInfluencer) {
            setLoading(true);
            const url = `${process.env.REACT_APP_API_URL}admin/influencer/${selectedInfluencer.id}?wallet_id=${address}`;

            const payload = {
                "tag": tag,
                "category" : category
            };

            fetch(url, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    return response.json();
                })
                .then(data => {
                    console.log('Success:', data);
                    setLoading(false);
                    setShowInfluencerUpdateModal(false);
                    toast.success('Influencer details updated successfully', {
                        style: {
                            background: 'rgba(0, 29, 255, 0.20)',
                            color: '#fff'
                        }
                    });
                    get_all_influencers(currentPage)
                })
                .catch((error) => {
                    console.error('Error:', error);
                    setLoading(false);
                    if (error.message.includes('500')) {
                        toast.error('Internal Server Error (500): Failed to update Influencer details', {
                            style: {
                                background: 'rgba(255, 0, 0, 0.20)',
                                color: '#fff'
                            }
                        });
                    } else {
                        toast.error('Failed to update Influencer details', {
                            style: {
                                background: 'rgba(255, 0, 0, 0.20)',
                                color: '#fff'
                            }
                        });
                    }
                });
        }
    };

    const get_all_influencers = async (page: any) => {
        setAllTweetloader(true);

        const walletIdParam = isConnected ? `&wallet_id=${address}` : '';
        const searchQueryParam = searchQuery.length > 0 ? `&influencer=${searchQuery}` : '';
        const url = `${process.env.REACT_APP_API_URL}admin/influencer?page=${page}&wallet_id=${walletIdParam}${searchQueryParam}`;

        const requestBody = {

        };

        try {
            const response = await fetch(url, {
                method: 'POST',
                mode: 'cors',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    // Include any other headers if needed
                }),
                body: JSON.stringify({ ...requestBody }),
            });

            if (response.ok) {
                const data = await response.json();
                setAllInfluencers(data['data']);
                setTotalPages(data['paging']['total_pages']);
                setCurrentPage(data['paging']['current_page']);
            } else {
                console.log(response.status);
                setAllInfluencers([]);
                setTotalPages(0);
            }
        } catch (e) {
            console.log(e);
            setAllTweetloader(false);
            setAllInfluencers([]);
            setTotalPages(0);
        } finally {
            setAllTweetloader(false);
        }
    };

    const renderPageNumbers = () => {
        const pages = [];
        for (let i = 1; i <= totalPages; i++) {
            // Always show the first and last page numbers
            if (i === 1 || i === totalPages) {
                pages.push(
                    <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
                        <a onClick={() => setCurrentPage(i)} className="page-link" href="#">
                            {i}
                        </a>
                    </li>
                );
            }
            // Show ... when there is a gap
            else if (i === 2 && currentPage > 3) {
                pages.push(<li key="start-ellipsis" className="page-item disabled"><span className="page-link">...</span></li>);
            }
            // Show the current page and the two pages before and after it
            else if (i >= currentPage - 1 && i <= currentPage + 1) {
                pages.push(
                    <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
                        <a onClick={() => setCurrentPage(i)} className="page-link" href="#">
                            {i}
                        </a>
                    </li>
                );
            }
            // Show ... when there is a gap
            else if (i === totalPages - 1 && currentPage < totalPages - 2) {
                pages.push(<li key="end-ellipsis" className="page-item disabled"><span className="page-link">...</span></li>);
            }
        }
        return pages;
    };

    const open_influencer_detail = (id: any) => {
        navigate("/influencer-detail/" + id);
    }

    const handleSearch = async (query: any) => {
        setSearchQuery(query);
    }

    const clearSearch = () => {
        setSearchQuery('');
    };

    useEffect(() => {
        get_all_influencers(currentPage);
    }, [currentPage, searchQuery]);



    return (
        <div className="container-fluid">
            <Toaster />
            <section className="section-padding position-relative mt-4">
                <div className="container-fluid">
                    <Row>

                        <Col sm={12} className="mt-3">
                            <Card className="t-w-card">
                                <Card.Body>
                                    <Row>
                                        <Col sm={12} md={12} lg={4}>
                                            <div className="filter-label">Search by Influencer</div>
                                            <div className="admin-search-bar-container mt-3" ref={searchBarRef}>
                                                <div className="search-bar-input-wrapper">
                                                    <input
                                                        type="text"
                                                        placeholder="Search Influencer"
                                                        value={searchQuery}
                                                        onChange={(e) => handleSearch(e.target.value)}
                                                    />
                                                    {searchQuery && (
                                                        <Icon.XCircle className="clear-icon" onClick={clearSearch} />
                                                    )}
                                                </div>

                                            </div>
                                        </Col>



                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>


                        <Col sm={12} className='mt-3'>

                            {allTweetloader &&
                                <Spinner animation="border" role="status" variant="light" >
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            }
                            {!allTweetloader &&
                                <>
                                    <div className={isMobile ? 'atw-table-container table-responsive-md' : 'atw-table-container'}>
                                        <Table className='atw-table'>
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Influencer</th>
                                                    <th>Description</th>
                                                    <th>Category</th>
                                                    <th>Tag</th>
                                                    <th></th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {allInfluencers.length > 0 ? (
                                                    allInfluencers.map((influencer, index) => (
                                                        <tr key={index} className={allInfluencers.length === 1 ? 'custom-single-row-height' : ''}>
                                                            <td width={'2%'}>{index + 1}</td>
                                                            <td width={'20%'} className='cursor-pointer' onClick={() => open_influencer_detail(influencer['username'])}>
                                                                <div className='d-flex align-items-center'>
                                                                    <div>
                                                                        <img src={influencer['profile_image_url']} className="t-d-user-img" />
                                                                    </div>
                                                                    <div>
                                                                        <div className="ms-1 ticker-name">{influencer['name']}</div>
                                                                        <div className="ms-1 ticker-symbol">@{influencer['username']}</div>
                                                                    </div>
                                                                </div>


                                                            </td>



                                                            <td width={'40%'}>{influencer.description} </td>

                                                            <td width={'20%'}>{influencer.category}</td>

                                                            <td width={'20%'}>{influencer.tag}</td>

                                                            <td>
                                                                <div className="ctw-btn" onClick={() => edit_influencer(influencer)}>
                                                                    Edit
                                                                </div>
                                                            </td>

                                                        </tr>
                                                    ))
                                                ) : (
                                                    // Render this row if allTokenTweets is empty
                                                    <tr>
                                                        <td colSpan={7} style={{ textAlign: 'center' }}>No data available</td>
                                                    </tr>

                                                )}
                                            </tbody>
                                        </Table>
                                    </div>
                                    <div>
                                        {totalPages > 1 &&
                                            <nav aria-label="Page navigation" className='d-flex justify-content-end'>
                                                <ul className="pagination">
                                                    {currentPage > 1 && (
                                                        <li className="page-item">
                                                            <a className="page-link" onClick={() => setCurrentPage(currentPage - 1)} href="#" aria-label="Previous">
                                                                <span aria-hidden="true">Previous</span>
                                                            </a>
                                                        </li>
                                                    )}
                                                    {renderPageNumbers()}
                                                    {currentPage < totalPages && (
                                                        <li className="page-item">
                                                            <a className="page-link" onClick={() => setCurrentPage(currentPage + 1)} href="#" aria-label="Next">
                                                                <span aria-hidden="true">Next</span>
                                                            </a>
                                                        </li>
                                                    )}
                                                </ul>
                                            </nav>
                                        }
                                    </div>
                                    <Modal className='tweet-modal' show={showInfluencerUpdateModal} onHide={() => setShowInfluencerUpdateModal(false)} centered>
                                        <Modal.Header className="justify-content-between">
                                            <div className='update-modal-heading'>Edit Influencer</div>
                                            <Icon.XCircle size={30} className='modal-close-icon' onClick={() => setShowInfluencerUpdateModal(false)}></Icon.XCircle>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className="mt-3">
                                                <label className='form-label ticker-text-label' htmlFor="tag">Tag:</label>
                                                <input
                                                    type="text"
                                                    id="tag"
                                                    name="tag"
                                                    className="form-control ticker-text-box"
                                                    value={tag}
                                                    onChange={(e) => setTag(e.target.value)}
                                                />
                                            </div>
                                            <div className="mt-3">
                                                <label className='form-label ticker-text-label' htmlFor="category">Category:</label>
                                                <input
                                                    type="text"
                                                    id="category"
                                                    name="category"
                                                    className="form-control ticker-text-box"
                                                    value={category}
                                                    onChange={(e) => setCategory(e.target.value)}
                                                />
                                            </div>

                                        </Modal.Body>
                                        <Modal.Footer>
                                            <button className="btn btn-primary" onClick={handleSaveChanges} disabled={loading}>
                                                {loading ? <Spinner animation="border" size="sm" /> : 'Save Changes'}
                                            </button>
                                            <button className="btn btn-secondary" onClick={() => setShowInfluencerUpdateModal(false)}>Close</button>
                                        </Modal.Footer>
                                    </Modal>

                                </>}
                        </Col>
                    </Row>

                </div>
            </section>
        </div>

    )
}

export default InfluencerList;