import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import { Twitter, GraphUpArrow, JournalCheck, Check2Circle, XCircle, Check2, Copy } from 'react-bootstrap-icons';
import { Row, Col, Card, Table, Spinner, Button, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { format, parseISO } from 'date-fns';
import { useAccount } from 'wagmi'
import "react-datepicker/dist/react-datepicker.css";
import './dashboard-kpi.css'

const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return isMobile;
};

interface UpdateDetails {
    fields_to_update?: {};
    old_record?: {};
    // Add more properties as needed
}

export const DashboardKPI = () => {
    const isMobile = useIsMobile();
    const { address, isConnected } = useAccount()
    const [data, setData] = useState({
        number_of_tweets: 0,
        tickers_mapped: 0,
        tweets_curated: 0,
        tickers_verified: 0
    });
    const [fromDate, setFromDate] = useState<Date | null>(new Date());
    const [toDate, setToDate] = useState<Date | null>(new Date());

    const [allLogloader, setAllLogloader] = useState(false);
    const [allLogs, setAllLogs] = useState([]);
    const [logDetails, setLogDetails] = useState([]);
    const [showDetailedLogModal, setShowDetailedLogModal] = useState(false);
    const [showUpdateDetails, setShowUpdateDetails] = useState(false);
    const [updateDetails, setUpdateDetails] = useState<UpdateDetails>({});
    const [copiedAddress, setCopiedAddress] = useState(null);
    const fetchKPIData = async (fromDate: any, toDate: any) => {
        // Format dates to yyyy-MM-dd
        const fromDateString = fromDate.toISOString().split('T')[0];
        const toDateString = toDate.toISOString().split('T')[0];
        const url = `${process.env.REACT_APP_API_URL}dashboard/kpi?wallet_id=${address}&start_date=${fromDateString}&end_date=${toDateString}`;

        const headers = {
            'Content-Type': 'application/json'
        };
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: headers
            });
            const result = await response.json();
            setData({
                number_of_tweets: result.data.number_of_tweets,
                tickers_mapped: result.data.tickers_mapped,
                tweets_curated: result.data.tweets_curated,
                tickers_verified: result.data.tickers_verified
            });
        } catch (error) {
            console.error('Failed to fetch data:', error);
        }
    };

    const fetchLogs = async (fromDate: any, toDate: any) => {
        const fromDateString = fromDate.toISOString().split('T')[0];
        const toDateString = toDate.toISOString().split('T')[0];
        const url = `${process.env.REACT_APP_API_URL}dashboard/logs?wallet_id=${address}&start_date=${fromDateString}&end_date=${toDateString}`;
        const headers = {
            'Content-Type': 'application/json',
        };

        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: headers
            });
            const data = await response.json();
            setAllLogs(data['data']['logs']);  // Assuming the API returns an array of logs
        } catch (error) {
            console.error('Failed to fetch logs:', error);
        }
    };

    const fetchLogDetails = async (walletId: any, logType: any, subType: any, startDate: any, endDate: any) => {

        const fromDateString = startDate.toISOString().split('T')[0];
        const toDateString = endDate.toISOString().split('T')[0];

        const url = `${process.env.REACT_APP_API_URL}dashboard/details?wallet_id=${address}&searching_wallet_id=${walletId}&log_type=${logType}&sub_type=${subType}&start_date=${fromDateString}&end_date=${toDateString}`;
        const headers = {
            'Content-Type': 'application/json'
        };

        try {
            const response = await fetch(url, { method: 'GET', headers });
            const data = await response.json();
            setLogDetails(data['data']);
            setShowUpdateDetails(false)
            setShowDetailedLogModal(true)
            console.log(data); // Or handle the data in a more appropriate way for your app
        } catch (error) {
            console.error('Failed to fetch details:', error);
        }
    };

    function formatTokenAddress(address: any) {
        if (address) {
            const start = address.substring(0, 6); // Takes first 6 characters
            const end = address.substring(address.length - 4); // Takes last 4 characters
            return `${start}....${end}`;
        }

    }


    function viewUpdate(detail: any) {
        setUpdateDetails(detail)
        setShowUpdateDetails(true)
    }

    const convertToLocalTime = (isoDate: string) => {
        // Add 'Z' at the end of the ISO date to indicate UTC time
        isoDate = isoDate + 'Z';
        const date = parseISO(isoDate);
        return format(date, "h:mm a · MMM d, yyyy");
    };


    const copyToClipboard = async (address: any, key: any) => {
        try {
            await navigator.clipboard.writeText(address);
            setCopiedAddress(key); // Use the unique key of the token address that was copied
            setTimeout(() => setCopiedAddress(null), 2000); // Optional: reset after a few seconds
        } catch (error) {
            console.error('Failed to copy to clipboard:', error);
        }
    };

    useEffect(() => {
        fetchKPIData(fromDate, toDate);
    }, [fromDate, toDate]);

    useEffect(() => {
        fetchLogs(fromDate, toDate);
    }, [fromDate, toDate]);

    return (
        <div className="container-fluid">
            <Toaster />
            <section className="section-padding position-relative mt-4">
                <div className="container-fluid">
                    <Row>
                        <Col>

                            <div className={isMobile ? 'd-flex mt-3' : 'mx-4 d-flex'}>
                                <div>
                                    <label className='filter-label'>
                                        From :
                                    </label>
                                    <DatePicker
                                        selected={fromDate}
                                        onChange={date => setFromDate(date)}
                                        dateFormat="dd/MM/yyyy"
                                    />
                                </div>
                                <div className='mx-3'>
                                    <label className='filter-label'>
                                        To:
                                    </label>
                                    <DatePicker
                                        selected={toDate}
                                        onChange={date => setToDate(date)}
                                        dateFormat="dd/MM/yyyy"
                                        minDate={fromDate}

                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="g-4 mt-3">
                        <div className='me-auto'>
                            <div className="s-heading">
                                KPI
                            </div>

                        </div>
                        <Col>
                            <Card className='kpi-card'>
                                <Card.Body>
                                    <Card.Title><Twitter /> Number of Tweets</Card.Title>
                                    <Card.Text>
                                        {data.number_of_tweets}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card className='kpi-card'>
                                <Card.Body>
                                    <Card.Title><GraphUpArrow /> Tickers Mapped</Card.Title>
                                    <Card.Text>
                                        {data.tickers_mapped}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card className='kpi-card'>
                                <Card.Body>
                                    <Card.Title><JournalCheck /> Tweets Curated</Card.Title>
                                    <Card.Text>
                                        {data.tweets_curated}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card className='kpi-card'>
                                <Card.Body>
                                    <Card.Title><Check2Circle /> Tickers Verified</Card.Title>
                                    <Card.Text>
                                        {data.tickers_verified}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row>

                        <Col sm={12} className='mt-3'>
                            <div className='me-auto mb-4'>
                                <div className="s-heading">
                                    User Logs
                                </div>

                            </div>
                            {allLogloader &&
                                <Spinner animation="border" role="status" variant="light" >
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            }
                            {!allLogloader &&
                                <>
                                    <div className={isMobile ? 'atw-table-container table-responsive-md' : 'atw-table-container'}>
                                        <Table className='atw-table'>
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Wallet id</th>
                                                    <th>Name</th>
                                                    <th colSpan={2}>Influencer</th>
                                                    <th colSpan={2}>Tweet</th>
                                                    <th colSpan={4}>Ticker</th>

                                                </tr>
                                                <tr>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th>View</th>
                                                    <th>Update</th>
                                                    <th>View</th>
                                                    <th>Update</th>
                                                    <th>Create</th>
                                                    <th>View</th>
                                                    <th>Update</th>
                                                    <th>delete</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {allLogs.length > 0 ? (
                                                    allLogs.map((log, index) => (
                                                        <tr key={index} className={allLogs.length === 1 ? 'custom-single-row-height' : ''}>
                                                            <td>
                                                                <div className='d-flex align-items-center gap-2'>
                                                                    {index + 1}

                                                                </div>
                                                            </td>
                                                            <td className='cursor-pointer' onClick={() => copyToClipboard(log['wallet_id'], index)}>
                                                                {formatTokenAddress(log['wallet_id'])} {copiedAddress === index ? <Check2 size={12} /> : <Copy className='cursor-pointer' size={12} />}
                                                            </td>
                                                            <td>{log['name']}</td>

                                                            <td>
                                                                {log['influencer']['list']}
                                                                {log['influencer']['list'] > 0 &&
                                                                    <div className='view-log-btn' onClick={() => fetchLogDetails(log['wallet_id'], 'influencer', 'list', fromDate, toDate)}>
                                                                        View
                                                                    </div>
                                                                }
                                                            </td>
                                                            <td>
                                                                {log["influencer"]['update']}
                                                                {log['influencer']['update'] > 0 &&
                                                                    <div className='view-log-btn' onClick={() => fetchLogDetails(log['wallet_id'], 'influencer', 'update', fromDate, toDate)}>
                                                                        View
                                                                    </div>
                                                                }
                                                            </td>
                                                            <td>
                                                                {log['tweet']['list']}
                                                                {log['tweet']['list'] > 0 &&
                                                                    <div className='view-log-btn' onClick={() => fetchLogDetails(log['wallet_id'], 'tweet', 'list', fromDate, toDate)}>
                                                                        View
                                                                    </div>
                                                                }
                                                            </td>
                                                            <td>
                                                                {log['tweet']['update']}
                                                                {log['tweet']['update'] > 0 &&
                                                                    <div className='view-log-btn' onClick={() => fetchLogDetails(log['wallet_id'], 'tweet', 'update', fromDate, toDate)}>
                                                                        View
                                                                    </div>
                                                                }
                                                            </td>
                                                            <td>
                                                                {log['ticker']['create']}
                                                                {log['ticker']['create'] > 0 &&
                                                                    <div className='view-log-btn' onClick={() => fetchLogDetails(log['wallet_id'], 'ticker', 'create', fromDate, toDate)}>
                                                                        View
                                                                    </div>
                                                                }
                                                            </td>
                                                            <td>
                                                                {log['ticker']['list']}
                                                                {log['ticker']['list'] > 0 &&
                                                                    <div className='view-log-btn' onClick={() => fetchLogDetails(log['wallet_id'], 'ticker', 'list', fromDate, toDate)}>
                                                                        View
                                                                    </div>
                                                                }
                                                            </td>
                                                            <td>
                                                                {log['ticker']['update']}
                                                                {log['ticker']['update'] > 0 &&
                                                                    <div className='view-log-btn' onClick={() => fetchLogDetails(log['wallet_id'], 'ticker', 'update', fromDate, toDate)}>
                                                                        View
                                                                    </div>
                                                                }
                                                            </td>
                                                            <td>
                                                                {log['ticker']['delete']}
                                                                {log['ticker']['delete'] > 0 &&
                                                                    <div className='view-log-btn' onClick={() => fetchLogDetails(log['wallet_id'], 'ticker', 'delete', fromDate, toDate)}>
                                                                        View
                                                                    </div>
                                                                }
                                                            </td>


                                                        </tr>
                                                    ))
                                                ) : (
                                                    // Render this row if allTokenTweets is empty
                                                    <tr>
                                                        <td colSpan={10} style={{ textAlign: 'center' }}>No data available</td>
                                                    </tr>

                                                )}
                                            </tbody>
                                        </Table>
                                    </div>
                                </>}

                            <Modal className='tweet-modal  modal-lg' show={showDetailedLogModal} onHide={() => setShowDetailedLogModal(false)} centered>
                                <Modal.Header className="justify-content-between">
                                    <div className='update-modal-heading'>Log Details </div>
                                    <XCircle size={30} className='modal-close-icon' onClick={() => setShowDetailedLogModal(false)}></XCircle>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className='log-modal-content'>
                                        {showUpdateDetails ?
                                            (
                                                <div>
                                                    <button className="back-btn" onClick={() => setShowUpdateDetails(false)}>Back</button>
                                                    <Row>
                                                        <Col sm={6}>
                                                            <div className='json-d-h'>
                                                                Old Record
                                                            </div>
                                                            <pre className='json-display'>{JSON.stringify(updateDetails['old_record'], null, 2)}</pre>
                                                        </Col>
                                                        <Col sm={6}>
                                                            <div className='json-d-h'>
                                                                Updated Record
                                                            </div>
                                                            <pre className='json-display'>{JSON.stringify(updateDetails['fields_to_update'], null, 2)}</pre>
                                                        </Col>
                                                    </Row>


                                                </div>
                                            )
                                            :
                                            (<Table className='atw-table'>
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Wallet id</th>
                                                        <th>Action</th>
                                                        <th>Modified Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {logDetails.length > 0 ? (
                                                        logDetails.map((logdetail, index) => (
                                                            <tr key={index} className={allLogs.length === 1 ? 'custom-single-row-height' : ''}>
                                                                <td>
                                                                    <div className='d-flex align-items-center gap-2'>
                                                                        {index + 1}

                                                                    </div>
                                                                </td>
                                                                <td className='cursor-pointer' onClick={() => copyToClipboard(logdetail['wallet_id'], index)}>
                                                                    {formatTokenAddress(logdetail['wallet_id'])} {copiedAddress === index ? <Check2 size={12} /> : <Copy className='cursor-pointer' size={12} />}
                                                                </td>
                                                                <td>
                                                                    {logdetail['action'] == 'TICKER-LIST' &&
                                                                        "Ticker View"
                                                                    }
                                                                    {logdetail['action'] == 'TWEET-LIST' &&
                                                                        "Tweet View"
                                                                    }
                                                                    {logdetail['action'] == 'INFLUENCER-LIST' &&
                                                                        "Influencer View"
                                                                    }
                                                                    {logdetail['action'] == 'TWEET-UPDATE' &&
                                                                        <div>
                                                                            <div>
                                                                                Tweet Id: {logdetail['old_record']['tweet_id']}
                                                                            </div>
                                                                            <div>
                                                                                cryptocurrency: {logdetail['fields_to_update']['cryptocurrency']}
                                                                            </div>
                                                                            <div>
                                                                                crypto symbol:{logdetail['fields_to_update']['crypto_symbol']}
                                                                            </div>

                                                                            <div className="view-log-btn" onClick={() => viewUpdate(logdetail)}>
                                                                                View
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    {logdetail['action'] == 'TICKER-UPDATE' &&
                                                                        <div>
                                                                            <div>
                                                                                Ticker Name: {logdetail['fields_to_update']['token_name']}
                                                                            </div>
                                                                            <div>
                                                                                Ticker Symbol:{logdetail['fields_to_update']['token_symbol']}
                                                                            </div>
                                                                            <div className="view-log-btn" onClick={() => viewUpdate(logdetail)}>
                                                                                View
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    {logdetail['action'] == 'INFLUENCER-UPDATE' &&
                                                                        <div>
                                                                            <div>
                                                                                Influencer Name: {logdetail['old_record']['name']}
                                                                            </div>
                                                                            <div>
                                                                                Influencer Handle: {logdetail['old_record']['username']}
                                                                            </div>

                                                                            <div className="view-log-btn" onClick={() => viewUpdate(logdetail)}>
                                                                                View
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {convertToLocalTime(logdetail['modified_date'])}
                                                                </td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        // Render this row if allTokenTweets is empty
                                                        <tr>
                                                            <td colSpan={3} style={{ textAlign: 'center' }}>No data available</td>
                                                        </tr>

                                                    )}
                                                </tbody>
                                            </Table>)
                                        }

                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <button className="btn btn-secondary" onClick={() => setShowDetailedLogModal(false)}>Close</button>
                                </Modal.Footer>
                            </Modal>
                        </Col>
                    </Row>
                </div>
            </section>
        </div>
    )
}

export default DashboardKPI;