import { useState, useRef, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Row, Col, Card, Form, Table, Spinner, Button, Modal } from "react-bootstrap";
import * as Icon from 'react-bootstrap-icons';
import { useUser } from '../components/UserContext';
import { useAccount } from 'wagmi'
import Select, { MultiValue } from 'react-select';
import { Tweet } from 'react-tweet';
import { format, parseISO } from 'date-fns';
// import TokenFilter from '../components/TokenFilter';
import TokenFilter from '../components/TokenFilterNew';
import KeywordFilter from '../components/KeywordFilter';
import { Influencer, TokenCategory, Token } from '../components/filterTypes';
import { transformTokensToCategories } from '../components/utils';
import star_table_img from "../assets/icons/Star 3.png"
import star_fill_img from "../assets/images/Star.png"
import link_icon from "../assets/icons/link_icon.png"
import icon_1 from "../assets/icons/icon-1.png"
import icon_2 from "../assets/icons/icon-2.png"
import twiiter_icon from "../assets/icons/twiitter-icon.png"
import no_img from "../assets/images/no-image-icon.png"
import "./influencer-detail.css"


interface Tweet {
    user: number;
    tweet_id: string;
    created_at: string;
    cryptocurrency: string;
    crypto_symbol: string;
    classification: string;
    TLDR: string;
    category: string;
    keyword: string;
    user_profile_image_url: string;
    user_username: string;
    dexscreen_url: string;
    token_sniffer_url: string;
    id: string;
    publishable: boolean;
    user_profile_picture_url: string;
    user_name: string;
    username: string;
    complete_text: string;
}


interface InfluencerTweet {
    tweet_id: string;
    tweet_created_at: string;
    user_username: string;
}

type TweetsByDate = {
    [key: string]: Tweet[];
};

interface CryptoTableRowProps {
    tweets: Tweet[];
}

interface ViewTweet {
    complete_text: string;
    tweet_id: string;
    publishable: boolean;
    user_profile_image_url: string;
    user_name: string;
    username: string;
    created_at: string;
}




const Avatars: React.FC<CryptoTableRowProps> = ({ tweets }) => {
    const { address, isConnected } = useAccount()
    const maxAvatars = 5;
    const remainingCount = tweets.length - maxAvatars;
    const [searchTerm, setSearchTerm] = useState("");
    const [isSortedByDate, setIsSortedByDate] = useState(true);
    const [showTweetModal, setShowTweetModal] = useState(false);
    const [viewTweetId, setViewTweetId] = useState<string | null>(null);
    const [showDetailModal, setShowDetailModal] = useState(false);
    const [selectedTweet, setSelectedTweet] = useState<ViewTweet | null>(null);
    const [userSelectedTweet, setUserSelectedTweet] = useState<ViewTweet | null>(null);
    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const filteredTweets = tweets
        .filter(tweet => tweet.cryptocurrency?.toLowerCase().includes(searchTerm.toLowerCase()))
        .sort((a, b) => {
            if (isSortedByDate) {
                return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
            }
            return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
        });

    const open_tweet_link = (tweet_id: string) => {
        window.open("https://twitter.com/CryptoNikyous/status/" + tweet_id, "_blank")
    }

    const handleSortByDate = () => {
        setIsSortedByDate(!isSortedByDate);
    };


    const convertToLocalTime = (isoDate: string | undefined) => {
        if (!isoDate) {
            return 'Invalid date';
        }

        try {
            // Add 'Z' at the end of the ISO date to indicate UTC time
            const date = parseISO(isoDate + 'Z');
            return format(date, "h:mm a · MMM d, yyyy");
        } catch (error) {
            console.error("Error parsing date:", error);
            return 'Invalid date';
        }
    };


    const handleViewTweet = async (id: string) => {


        const url = `${process.env.REACT_APP_API_URL}search/tweet?wallet_id=${address}`;
        const payload = {
            tweet_ids: [id]
        };

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(payload)
            });

            if (response.ok) {
                const data = await response.json();
                const tweetData = data.tweets[id];
                if (tweetData.publishable) {
                    setUserSelectedTweet({
                        complete_text: tweetData.tweet.complete_text,
                        tweet_id: tweetData.tweet.id,
                        publishable: true,
                        user_profile_image_url: tweetData.tweet.user_profile_picture_url,
                        username: tweetData.tweet.username,
                        user_name: tweetData.tweet.user_name,
                        created_at: tweetData.tweet.created_at
                    });
                } else {
                    setUserSelectedTweet({
                        complete_text: tweetData.tweet.complete_text,
                        tweet_id: tweetData.tweet.id,
                        publishable: false,
                        user_profile_image_url: tweetData.tweet.user_profile_picture_url,
                        username: tweetData.tweet.username,
                        user_name: tweetData.tweet.user_name,
                        created_at: tweetData.tweet.created_at
                    });
                }
                setViewTweetId(id);
                // setShowDetailModal(true);
            } else {
                console.error("Failed to fetch publishable status", response.status);
            }
        } catch (error) {
            console.error("Error fetching publishable status", error);
        }

    };

    const handleBack = () => {
        setViewTweetId(null);
    };


    const handleAvatarClick = async (tweet: Tweet) => {
        console.log(tweet)
        setViewTweetId(null);
        setUserSelectedTweet(null);
        const url = `${process.env.REACT_APP_API_URL}search/tweet?wallet_id=${address}`;
        const payload = {
            tweet_ids: [tweet.tweet_id]
        };

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(payload)
            });

            if (response.ok) {
                const data = await response.json();
                const tweetData = data.tweets[tweet.tweet_id];
                console.log(tweetData)
                if (tweetData.publishable) {
                    setSelectedTweet({
                        complete_text: tweetData.tweet.complete_text,
                        tweet_id: tweetData.tweet.id,
                        publishable: true,
                        user_profile_image_url: tweetData.tweet.user_profile_picture_url,
                        username: tweetData.tweet.username,
                        user_name: tweetData.tweet.user_name,
                        created_at: tweetData.tweet.created_at
                    });
                } else {
                    setSelectedTweet({
                        complete_text: tweetData.tweet.complete_text,
                        tweet_id: tweetData.tweet.id,
                        publishable: false,
                        user_profile_image_url: tweetData.tweet.user_profile_picture_url,
                        username: tweetData.tweet.username,
                        user_name: tweetData.tweet.user_name,
                        created_at: tweetData.tweet.created_at
                    });
                }
                setShowDetailModal(true);
            } else {
                console.error("Failed to fetch publishable status", response.status);
            }
        } catch (error) {
            console.error("Error fetching publishable status", error);
        }
    };

    return (
        <div>
            <div className="stacked-avatars">
                {tweets.slice(0, maxAvatars).map((tweet, index) => (
                    <div key={index} className="avatars__item" style={{ zIndex: tweets.length - index }} onClick={() => handleAvatarClick(tweet)}>
                        <img className="avatar-img" src={tweet.user_profile_image_url || no_img} alt="user-avatar" />
                    </div>
                ))}
                {remainingCount > 0 && (
                    <div className="avatars__item avatars__more" style={{ zIndex: 0 }} onClick={() => setShowTweetModal(true)}>
                        +{remainingCount}
                    </div>
                )}
            </div>

            <Modal className='tweet-modal modal-lg' show={showTweetModal} onHide={() => setShowTweetModal(false)} centered>
                <Modal.Header className="justify-content-between">
                    <div className='h-tweet-modal-h'>Tweets</div>
                    <Icon.XCircle size={30} className='modal-close-icon' onClick={() => setShowTweetModal(false)}></Icon.XCircle>
                </Modal.Header>
                <Modal.Body>
                    <div className="app-container">
                        {viewTweetId && userSelectedTweet ? (
                            <div>
                                <button className="back-btn" onClick={handleBack}>Back To Tweets</button>
                                <div data-theme="dark" className='modal-tweet-container'>

                                    {userSelectedTweet && userSelectedTweet.publishable ? (
                                        <Tweet id={userSelectedTweet.tweet_id} />
                                    ) : (
                                        <div className="not-found-tweet-container">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <img src={userSelectedTweet.user_profile_image_url} className="t-d-user-img" alt="User" />
                                                </div>
                                                <div>
                                                    <div className="ms-1 ticker-name">{userSelectedTweet.user_name}</div>
                                                    <div className="ms-1 ticker-symbol">@{userSelectedTweet.username}</div>
                                                </div>
                                            </div>
                                            <div className="tweet-complete-text">{userSelectedTweet.complete_text}</div>
                                            <div className='token-detail-timestamp'>
                                                {
                                                    convertToLocalTime(userSelectedTweet.created_at)
                                                }
                                            </div>
                                            <Button className='go-to-tweet-btn mt-2' variant="secondary" onClick={() => open_tweet_link(userSelectedTweet.tweet_id)}>
                                                Go To Tweet
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <>
                                <div className="search-sort-container">
                                    <input
                                        type="text"
                                        placeholder="Search by Token..."
                                        className="search-bar"
                                        value={searchTerm}
                                        onChange={handleSearchChange}
                                    />
                                    <button className="sort-btn" onClick={handleSortByDate}>
                                        Sort by Date {isSortedByDate ? '(Descending)' : '(Ascending)'}
                                    </button>
                                </div>
                                <div className="investor-list">
                                    {filteredTweets.map((tweet, index) => (
                                        <div key={index} className="investor-item">
                                            <img className="avatar-img-small" src={tweet.user_profile_image_url || no_img} alt="user-avatar" />

                                            <div className="investor-info">
                                                <div className="investor-name">{tweet.cryptocurrency}</div>
                                                <div className="investor-type">{tweet.crypto_symbol}</div>

                                                <div className="tweet-date">
                                                    {new Date(tweet.created_at).toLocaleDateString('en-GB', {
                                                        day: '2-digit',
                                                        month: '2-digit',
                                                        year: 'numeric',
                                                    })}{' '}
                                                    {new Date(tweet.created_at).toLocaleTimeString('en-US', {
                                                        hour: '2-digit',
                                                        minute: '2-digit',
                                                        second: '2-digit',
                                                        hour12: true,
                                                    })}
                                                </div>
                                            </div>
                                            <button className="view-tweet-btn-modal" onClick={() => handleViewTweet(tweet.tweet_id)} >View Tweet</button>
                                        </div>
                                    ))}
                                </div>
                            </>)}
                    </div>
                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </Modal>

            {selectedTweet && (
                <Modal className='tweet-modal' show={showDetailModal} onHide={() => setShowDetailModal(false)} centered>
                    <Modal.Header className="justify-content-between">
                        <div className='h-tweet-modal-h'>Tweet Details</div>
                        <Icon.XCircle size={30} className='modal-close-icon' onClick={() => setShowDetailModal(false)} />
                    </Modal.Header>
                    <Modal.Body>
                        <div data-theme="dark" className='modal-tweet-container'>
                            {selectedTweet.publishable ? (
                                <Tweet id={selectedTweet.tweet_id} />
                            ) : (
                                <div className="not-found-tweet-container">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <img src={selectedTweet.user_profile_image_url} className="t-d-user-img" alt="User" />
                                        </div>
                                        <div>
                                            <div className="ms-1 ticker-name">{selectedTweet.user_name}</div>
                                            <div className="ms-1 ticker-symbol">@{selectedTweet.username}</div>
                                        </div>
                                    </div>
                                    <div className="tweet-complete-text">{selectedTweet.complete_text}</div>
                                    <div className='token-detail-timestamp'>
                                        {
                                            convertToLocalTime(selectedTweet.created_at)
                                        }
                                    </div>
                                    <Button className='go-to-tweet-btn mt-2' variant="secondary" onClick={() => open_tweet_link(selectedTweet.tweet_id)}>
                                        Go To Tweet
                                    </Button>
                                </div>
                            )}
                        </div>
                    </Modal.Body>
                </Modal>
            )}

        </div>
    );
};



interface TokenOption {
    value: string;
    // ... other properties, if they exist
}

// interface Token {
//     token: string;
//     symbol: string;
// }


interface Keyword {
    label: string;
    value: string;
}

interface Filters {
    tokens: string[];
    keywords: string[];
    influencer: Influencer[];
}

export const InfluencerDetail = () => {
    const navigate = useNavigate();
    const { influencer } = useParams();
    const { user, addToWatchlist, removeFromWatchlist, watchlistToken } = useUser();
    const { address, isConnected } = useAccount()
    const [activeAllTweetsTimeWindow, setActiveAllTweetsTimeWindow] = useState(process.env.REACT_APP_DEFAULT_TIME_WINDOW)
    const [isTokenWatchlistChecked, setIsTokenWatchlistChecked] = useState(false);
    const [allInfluencersTimeWindow, setAllInfluencersTimeWindow] = useState(process.env.REACT_APP_DEFAULT_TIME_WINDOW)
    const [keywordFilter, setKeywordFilter] = useState<string[]>([]);
    const [tokenFilter, setTokenFilter] = useState<TokenCategory[]>([]);
    const [tokenFilterNew, setTokenFilterNew] = useState<Token[]>([]);
    const [allInfluencerTweet, setAllInfluencerTweet] = useState<InfluencerTweet[]>([]);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [allTweetloader, setAllTweetloader] = useState(false);
    const [visibleLinkPopup, setVisibleLinkPopup] = useState(null);

    const [allTokenTweets, setAllTokenTweets] = useState([]);
    const [totalTokenPages, setTotalTokenPages] = useState(0);
    const [currentTokenPage, setCurrentTokenPage] = useState(1);
    const [allTokenTweetloader, setAllTokenTweetloader] = useState(false);
    const [followersCount, setFollowersCount] = useState('')

    const [userName, setUserName] = useState('')
    const [userHandle, setUserHandle] = useState('')
    const [userProfileImage, setUserProfileImage] = useState('')

    const [tweets, setTweets] = useState<Tweet[]>([]);
    const [loadingInfluencerTweets, setLoadingInfluencerTweets] = useState(false);

    //Filter
    const [filters, setFilters] = useState<Filters>({ tokens: [], keywords: [], influencer: [] });
    const [filteredTokens, setFilteredTokens] = useState<string[]>([]);
    const [filteredKeywords, setFilteredKeywords] = useState<string[]>([]);

    const handleTokenSelectionChange = (selectedValues: string[]) => {
        console.log(selectedValues)
        setFilteredTokens(selectedValues)
    };

    const handleKeywordSelectionChange = (selectedValues: string[]) => {
        setFilteredKeywords(selectedValues)
    };


    const renderPageNumbers = () => {
        const pages = [];
        for (let i = 1; i <= totalPages; i++) {
            // Always show the first and last page numbers
            if (i === 1 || i === totalPages) {
                pages.push(
                    <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
                        <a onClick={() => setCurrentPage(i)} className="page-link" href="#">
                            {i}
                        </a>
                    </li>
                );
            }
            // Show ... when there is a gap
            else if (i === 2 && currentPage > 3) {
                pages.push(<li key="start-ellipsis" className="page-item disabled"><span className="page-link">...</span></li>);
            }
            // Show the current page and the two pages before and after it
            else if (i >= currentPage - 1 && i <= currentPage + 1) {
                pages.push(
                    <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
                        <a onClick={() => setCurrentPage(i)} className="page-link" href="#">
                            {i}
                        </a>
                    </li>
                );
            }
            // Show ... when there is a gap
            else if (i === totalPages - 1 && currentPage < totalPages - 2) {
                pages.push(<li key="end-ellipsis" className="page-item disabled"><span className="page-link">...</span></li>);
            }
        }
        return pages;
    };

    const renderTokenPageNumbers = () => {
        const pages = [];
        for (let i = 1; i <= totalTokenPages; i++) {
            // Always show the first and last page numbers
            if (i === 1 || i === totalTokenPages) {
                pages.push(
                    <li key={i} className={`page-item ${currentTokenPage === i ? 'active' : ''}`}>
                        <a onClick={() => setCurrentTokenPage(i)} className="page-link" href="#">
                            {i}
                        </a>
                    </li>
                );
            }
            // Show ... when there is a gap
            else if (i === 2 && currentTokenPage > 3) {
                pages.push(<li key="start-ellipsis" className="page-item disabled"><span className="page-link">...</span></li>);
            }
            // Show the current page and the two pages before and after it
            else if (i >= currentTokenPage - 1 && i <= currentTokenPage + 1) {
                pages.push(
                    <li key={i} className={`page-item ${currentTokenPage === i ? 'active' : ''}`}>
                        <a onClick={() => setCurrentTokenPage(i)} className="page-link" href="#">
                            {i}
                        </a>
                    </li>
                );
            }
            // Show ... when there is a gap
            else if (i === totalTokenPages - 1 && currentTokenPage < totalTokenPages - 2) {
                pages.push(<li key="end-ellipsis" className="page-item disabled"><span className="page-link">...</span></li>);
            }
        }
        return pages;
    };



    const get_influencer_tweet = async (timeWindow: any, page: any, token: any, keyword: any, influencer: any) => {
        setAllTweetloader(true)
        setAllInfluencerTweet([])

        const walletIdParam = isConnected ? `&wallet_id=${address}` : '';
        const tokenWatchlistParam = isTokenWatchlistChecked ? '&token_watchlist=True' : '&token_watchlist=False';

        const url = `${process.env.REACT_APP_API_URL}detail/tweets-summary?time_window=${timeWindow}&page=${page}&wallet_id=${walletIdParam}${tokenWatchlistParam}`;

        const requestBody = {
            influencer: influencer ? influencer : null,
            token: token ? token.join(',') : null,
            keyword: keyword ? keyword.join(',') : null,
        };

        try {
            const response = await fetch(url, {
                method: 'POST',
                mode: 'cors',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    // Include any other headers if needed
                }),
                body: JSON.stringify({ ...requestBody }),
            });
            if (response.status === 200) {
                const data = await response.json();
                if (data['data'].length > 0) {
                    setAllInfluencerTweet(data['data']);
                } else {
                    setAllInfluencerTweet([])
                }

                setTotalPages(data['paging']['total_pages']);
                setCurrentPage(data['paging']['current_page']);
                setAllTweetloader(false)
                // setCurrentTweetPage(1);
                // fetchTweets(); // Fetch initial batch of tweets
            } else {
                setAllInfluencerTweet([])
                setAllTweetloader(false)

            }
        } catch (e) {
            console.log(e);
            setAllTweetloader(false)
            setAllInfluencerTweet([])
        }
    };



    const get_influencer_summary = async (timeWindow: any, page: any, influencer: any) => {
        setAllTokenTweetloader(true)
        const influencerParam = influencer ? `&influencer=${influencer}` : '';
        const url = `${process.env.REACT_APP_API_URL}influencer/summary?time_window=${timeWindow}&page=${page}${influencerParam}`;

        try {
            const response = await fetch(url, {
                method: "GET",
                mode: "cors",
                headers: new Headers({ "content-type": "application/json" })
            });
            if (response.status === 200) {
                const data = await response.json();
                setAllTokenTweets(data['data']['most_spoken_tokens']);
                setTotalTokenPages(data['paging']['total_pages']);
                setCurrentTokenPage(data['paging']['current_page']);
                setUserName(data['data']['user_name'])
                setUserHandle(data['data']['user_username'])
                setUserProfileImage(data['data']['user_profile_image_url'])
                setFollowersCount(data['data']['user_public_metrics']['followers_count'])
                setAllTokenTweetloader(false)
            } else {
                setAllTokenTweetloader(false)
            }
        } catch (e) {
            console.log(e);
            setAllTokenTweetloader(false)
        }
    };

    const get_all_token_filter = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "home/filters", {
                method: "GET",
                mode: "cors",
                headers: new Headers({ "content-type": "application/json" })
            });
            if (response.status === 200) {
                const data = await response.json();
                console.log(data.filters)
                const categoriesWithTokens = transformTokensToCategories(data['filters']['tokens']);
                setFilters(data.filters);
                setTokenFilterNew(data['filters']['tokens'])
                setTokenFilter(categoriesWithTokens);
                setKeywordFilter(data['filters']['keywords']);
            } else {
                console.log(response.status);
            }
        } catch (e: any) {
            console.log(e);
        }
    }


    const fetchTweets = async () => {
        if (loadingInfluencerTweets) return;
        if(allInfluencerTweet.length < 0){
            setTweets([])
            return
        }
        setLoadingInfluencerTweets(true);
        console.log(allInfluencerTweet)

        const tweetIds = allInfluencerTweet.map(tweet => tweet.tweet_id)

        console.log('Fetching tweet IDs:', tweetIds);

        const url = `${process.env.REACT_APP_API_URL}search/tweet?wallet_id=${address}`;
        const payload = { tweet_ids: tweetIds };

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });

            if (response.ok) {
                const data = await response.json();
                console.log(data)
                // Convert the object into an array and then map and update the tweets
                const updatedTweets = Object.values(data.tweets).map((tweet: any) => {
                    if (!tweet) return null; // Skip if tweet is falsy
                    return {
                        ...tweet.tweet,
                        publishable: tweet.publishable,
                        complete_text: tweet.tweet.complete_text,
                        user_profile_image_url: tweet.tweet.user_profile_image_url,
                        user_username: tweet.tweet.user_username,
                    };
                }).filter(Boolean); // Remove any null entries

                console.log(updatedTweets);
                setTweets(updatedTweets);

            } else {
                console.error('Failed to fetch tweet details', response.status);
                setTweets([]);
            }
        } catch (error) {
            console.error('Error fetching tweet details', error);
            setTweets([]);
        } finally {
            setLoadingInfluencerTweets(false);
        }
    };



    useEffect(() => {
        fetchTweets();
    }, [allInfluencerTweet]);

    // useEffect(() => {
    //     if (currentTweetPage > 1) {
    //         fetchTweets();
    //     }
    // }, [currentTweetPage, allInfluencerTweet]);

    useEffect(() => {
        get_influencer_summary(activeAllTweetsTimeWindow, currentTokenPage, influencer);
    }, [activeAllTweetsTimeWindow, currentTokenPage, influencer]);

    useEffect(() => {
        get_influencer_tweet(allInfluencersTimeWindow, currentPage, filteredTokens, filteredKeywords, influencer);
    }, [allInfluencersTimeWindow, currentPage, filteredTokens, filteredKeywords, influencer, isTokenWatchlistChecked]);



    useEffect(() => {
        get_all_token_filter();
    }, []);


    useEffect(() => {
        const handleScroll = () => {
            const sectionElement = document.getElementById('second-section');
            const filterCard = document.querySelector('.filter-t-w-card'); // Adjust the selector as needed
            if (sectionElement && filterCard) {
                const sectionTop = sectionElement.offsetTop;
                // Use window.scrollY instead of window.pageYOffset
                const isPositionFixed = window.scrollY > sectionTop - 20; // Adjust the 20 as needed for offset

                if (isPositionFixed) {
                    filterCard.classList.add('fixed-filter');
                } else {
                    filterCard.classList.remove('fixed-filter');
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);



    const back_to_home = () => {
        // navigate(-1);
        navigate("/");
    }

    const open_token_detail = (token: any) => {
        // navigate("/token-detail/" + token);
        window.open("/token-detail/" + token, "_blank")
    }

    const open_tweet_link = (tweet_id: string) => {
        window.open("https://twitter.com/CryptoNikyous/status/" + tweet_id, "_blank")
    }

    const toggleLinkPopup = (rowId: any) => {
        if (visibleLinkPopup === rowId) {
            setVisibleLinkPopup(null); // Close the popup if it's already open
        } else {
            setVisibleLinkPopup(rowId); // Open the new popup
        }
    };

    const back_generate_tweet = (token: any) => {
        navigate("/generate-tweet/" + token);
    }

    const checkIfExistsOnWatchList = (tokenId: string): string | null => {
        if (watchlistToken?.length > 0) {
            const item = watchlistToken.find(item => item.data === tokenId);
            return item ? item.watchlist_id : null;
        } else {
            return null
        }

    }

    const handleWatchlistToggle = async (tokenId: any) => {
        // Check if the token is currently in the watchlist
        const isInWatchlist = checkIfExistsOnWatchList(tokenId);
        if (user?.wallet_id) {
            if (isInWatchlist) {
                removeFromWatchlist('Token', isInWatchlist, user?.wallet_id)
            } else {
                addToWatchlist('Token', tokenId, user?.wallet_id)
            }
        }


        // Optionally, refresh the watchlist data to reflect the update
    };

    const handleTokenCheckboxChange = (e: any) => {
        setIsTokenWatchlistChecked(e.target.checked);
    };

    function formatNumber(num: any) {
        num = Number(num)
        if (num >= 1000000) {
            return (num / 1000000).toFixed(1) + 'M'; // For millions
        } else if (num >= 1000) {
            return (num / 1000).toFixed(1) + 'K'; // For thousands
        } else {
            return num; // For numbers less than 1000
        }
    }

    const convertToLocalTime = (isoDate: string | undefined) => {
        if (!isoDate) {
            return 'Invalid date';
        }

        try {
            // Add 'Z' at the end of the ISO date to indicate UTC time
            const date = parseISO(isoDate + 'Z');
            return format(date, "h:mm a · MMM d, yyyy");
        } catch (error) {
            console.error("Error parsing date:", error);
            return 'Invalid date';
        }
    };

    return (
        <div className="container-fluid">
            <section className="first-section section-padding position-relative">
                <div className="container-fluid">
                    <Row>
                        <Col sm={12}>
                            <div className='back-btn' onClick={back_to_home}>
                                <Icon.ArrowLeft size={20}></Icon.ArrowLeft>  Back
                            </div>
                        </Col>
                        <Col className='mt-4' sm={12}>
                            {userName &&
                                <Card className="t-w-card">
                                    <Card.Body>
                                        <div className='d-flex'>
                                            <div className='d-flex align-items-center w-100' >
                                                <img className='influ-pro-img' src={userProfileImage} />
                                                <div className='mx-3 w-50'>
                                                    <div className='influencer-name'>
                                                        {userName}
                                                    </div>
                                                    <div className='influencer-handle'>
                                                        @{userHandle}
                                                    </div>
                                                </div>
                                                <div className='w-25'>
                                                    <div className='followers-count'>
                                                        {formatNumber(followersCount)}
                                                    </div>
                                                    <div className='followers-text'>
                                                        Followers
                                                    </div>
                                                </div>
                                                <div className='w-25'>
                                                    <a href={'https://twitter.com/' + userHandle} target='_blank'><img src={twiiter_icon} /></a>
                                                    {/* <img className="mx-2" src={influ_web_icon} />
                                                <img className='mx-1' src={influ_flag_icon} /> */}
                                                </div>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <div className='d-flex mt-5'>
                                <div className='me-auto'>
                                    <div className="s-heading">
                                        Most Spoken Tokens
                                    </div>

                                </div>
                                <div className='time-window-container d-flex w-25'>
                                    <div onClick={() => setActiveAllTweetsTimeWindow('15min')} className={`time-window ${activeAllTweetsTimeWindow == '15min' ? 'active' : ''}`}>
                                        15 min
                                    </div>
                                    <div onClick={() => setActiveAllTweetsTimeWindow('1hr')} className={`time-window ${activeAllTweetsTimeWindow == '1hr' ? 'active' : ''}`}>
                                        1h
                                    </div>
                                    <div onClick={() => setActiveAllTweetsTimeWindow('4hr')} className={`time-window ${activeAllTweetsTimeWindow == '4hr' ? 'active' : ''}`}>
                                        4h
                                    </div>
                                    <div onClick={() => setActiveAllTweetsTimeWindow('12hr')} className={`time-window ${activeAllTweetsTimeWindow == '12hr' ? 'active' : ''}`}>
                                        12h
                                    </div>
                                    <div onClick={() => setActiveAllTweetsTimeWindow('1day')} className={`time-window ${activeAllTweetsTimeWindow == '1day' ? 'active' : ''}`}>
                                        1d
                                    </div>
                                    <div onClick={() => setActiveAllTweetsTimeWindow('1week')} className={`time-window ${activeAllTweetsTimeWindow == '1week' ? 'active' : ''}`}>
                                        7d
                                    </div>
                                    <div onClick={() => setActiveAllTweetsTimeWindow('1month')} className={`time-window ${activeAllTweetsTimeWindow == '1month' ? 'active' : ''}`}>
                                        30d
                                    </div>
                                </div>

                            </div>
                        </Col>

                    </Row>
                </div>
            </section>
            <section className='mt-4'>
                <div className="container-fluid">
                    <Row>
                        <Col sm={12}>
                            {allTokenTweetloader &&
                                <Spinner animation="border" role="status" variant="light" >
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            }
                            {!allTokenTweetloader &&
                                <>
                                    <div className='atw-table-container'>
                                        <Table className='atw-table'>
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th></th>
                                                    <th>Tokens</th>
                                                    <th>Tweet By</th>
                                                    <th>Tweet Link</th>
                                                    <th>Tweet By Others</th>
                                                    <th>Links</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {allTokenTweets.length > 0 ? (
                                                    allTokenTweets.map((tokenTweets, index) => (
                                                        <tr key={index}>
                                                            <td width={'2%'}>{index + 1}</td>
                                                            <td width={'3%'}>
                                                                <img
                                                                    src={checkIfExistsOnWatchList(tokenTweets['id']) ? star_fill_img : star_table_img}
                                                                    className="cursor-pointer"
                                                                    onClick={() => handleWatchlistToggle(tokenTweets['id'])}
                                                                    alt="Watchlist Toggle"
                                                                />
                                                            </td>
                                                            <td width={'20%'} className='cursor-pointer' onClick={() => open_token_detail(tokenTweets['id'])}>
                                                                <div className='d-flex align-items-center'>
                                                                    <div>
                                                                        <img src={tokenTweets['image_url'] ? tokenTweets['image_url'] : no_img} className="ticker-img" />
                                                                    </div>
                                                                    <div className="ms-1 ticker-name">{tokenTweets['token']}</div>
                                                                    {/* <div className="ms-1 ticker-symbol">BTC</div> */}
                                                                </div>
                                                            </td>
                                                            <td width={'10%'}>{tokenTweets['from_influencer']}</td>
                                                            <td width={'45%'}>
                                                                <Avatars tweets={tokenTweets['tweets']} />

                                                            </td>

                                                            <td width={'10%'}>{tokenTweets['from_all']}</td>
                                                            <td width={'10%'}>
                                                                <div className="position-relative"> {/* Add this wrapper */}
                                                                    <img className='table-link-icon' onClick={() => toggleLinkPopup(index)} src={link_icon} />
                                                                    {visibleLinkPopup === index &&
                                                                        <div className="popup">
                                                                            {tokenTweets['dexscreen_url'] != '' &&
                                                                                <a href={tokenTweets['dexscreen_url']} target='_blank'><img className="me-2 tw-social-icons cursor-pointer" src={icon_1} /></a>
                                                                            }
                                                                            {tokenTweets['token_sniffer_url'] != '' &&
                                                                                <a href={tokenTweets['token_sniffer_url']} target='_blank'><img className="me-2 tw-social-icons cursor-pointer" src={icon_2} /></a>
                                                                            }
                                                                        </div>}
                                                                </div>
                                                            </td>

                                                        </tr>
                                                    ))
                                                ) : (
                                                    // Render this row if allTokenTweets is empty
                                                    <tr>
                                                        <td colSpan={7} style={{ textAlign: 'center' }}>No data available</td>
                                                    </tr>
                                                )}

                                            </tbody>
                                        </Table>
                                    </div>

                                    <div>

                                        {totalTokenPages > 1 &&
                                            <nav aria-label="Page navigation" className='d-flex justify-content-end'>
                                                <ul className="pagination">
                                                    {currentTokenPage > 1 && (
                                                        <li className="page-item">
                                                            <a className="page-link" onClick={() => setCurrentTokenPage(currentTokenPage - 1)} href="#" aria-label="Previous">
                                                                <span aria-hidden="true">Previous</span>
                                                            </a>
                                                        </li>
                                                    )}
                                                    {renderTokenPageNumbers()}
                                                    {currentTokenPage < totalTokenPages && (
                                                        <li className="page-item">
                                                            <a className="page-link" onClick={() => setCurrentTokenPage(currentTokenPage + 1)} href="#" aria-label="Next">
                                                                <span aria-hidden="true">Next</span>
                                                            </a>
                                                        </li>
                                                    )}
                                                </ul>
                                            </nav>
                                        }
                                    </div>
                                </>
                            }

                        </Col>

                    </Row>
                </div>
            </section>
            <section className="first-section section-padding position-relative mt-5">
                <div className="container-fluid">
                    <Row>
                        <Col sm={12}>
                            <div className='d-flex'>
                                <div className='me-auto'>
                                    <div className="s-heading">
                                        Influencer’s Tweet
                                    </div>
                                </div>
                            </div>
                        </Col>

                    </Row>
                </div>
            </section>
            <section className="section-padding position-relative mt-4" id="second-section">

                <div className="container-fluid">
                    <Row>
                        <Col sm={8} className='mt-3'>

                            {allTweetloader &&
                                <Spinner animation="border" role="status" variant="light" >
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            }
                            {/* Render your tweet components */}

                            {!allTweetloader &&
                                <>
                                    {tweets.map((tweet) => (
                                        <div data-theme="dark" className='influencer-tweet-feed'>
                                            {tweet.publishable ? (
                                                <Tweet key={tweet.id} id={tweet.id} />
                                            ) : (
                                                <div className="not-found-tweet-container">
                                                    <div className="d-flex align-items-center">
                                                        <div>
                                                            <img src={tweet.user_profile_picture_url} className="t-d-user-img" alt="User" />
                                                        </div>
                                                        <div>

                                                            <div className="ms-1 ticker-name">{tweet.user_name}</div>
                                                            <div className="ms-1 ticker-symbol">@{tweet.username}</div>
                                                        </div>
                                                    </div>
                                                    <div className="tweet-complete-text">{tweet.complete_text}</div>
                                                    <div className='token-detail-timestamp'>
                                                        {
                                                            convertToLocalTime(tweet.created_at)
                                                        }
                                                    </div>
                                                    <Button className='go-to-tweet-btn mt-2' variant="secondary" onClick={() => open_tweet_link(tweet.id)}>
                                                        Go To Tweet
                                                    </Button>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </>
                            }
                        </Col>

                        <Col sm={4} >
                            <Card className="t-w-card filter-t-w-card">
                                <Card.Body>
                                    <div>
                                        <div className="filter-label">
                                            Time Window
                                        </div>
                                        <div className='time-window-container d-flex'>
                                            <div onClick={() => setAllInfluencersTimeWindow('15min')} className={`time-window ${allInfluencersTimeWindow == '15min' ? 'active' : ''}`}>
                                                15 min
                                            </div>
                                            <div onClick={() => setAllInfluencersTimeWindow('1hr')} className={`time-window ${allInfluencersTimeWindow == '1hr' ? 'active' : ''}`}>
                                                1h
                                            </div>
                                            <div onClick={() => setAllInfluencersTimeWindow('4hr')} className={`time-window ${allInfluencersTimeWindow == '4hr' ? 'active' : ''}`}>
                                                4h
                                            </div>
                                            <div onClick={() => setAllInfluencersTimeWindow('12hr')} className={`time-window ${allInfluencersTimeWindow == '12hr' ? 'active' : ''}`}>
                                                12h
                                            </div>
                                            <div onClick={() => setAllInfluencersTimeWindow('1day')} className={`time-window ${allInfluencersTimeWindow == '1day' ? 'active' : ''}`}>
                                                1d
                                            </div>
                                            <div onClick={() => setAllInfluencersTimeWindow('1week')} className={`time-window ${allInfluencersTimeWindow == '1week' ? 'active' : ''}`}>
                                                7d
                                            </div>
                                            <div onClick={() => setAllInfluencersTimeWindow('1month')} className={`time-window ${allInfluencersTimeWindow == '1month' ? 'active' : ''}`}>
                                                30d
                                            </div>

                                        </div>
                                    </div>
                                    <div className='mt-3'>
                                        <div className="filter-label">
                                            Filter by Token
                                        </div>
                                        <TokenFilter tokens={tokenFilterNew} onSelectedItemsChange={handleTokenSelectionChange} />
                                        {/* <TokenFilter tokens={tokenFilter} onSelectedItemsChange={handleTokenSelectionChange} /> */}

                                    </div>
                                    <div className='mt-3'>
                                        <div className="filter-label">
                                            Filter by Keywords
                                        </div>

                                        <KeywordFilter keywords={keywordFilter} onSelectionChange={handleKeywordSelectionChange} />

                                    </div>
                                    <div className='mt-3'>
                                        <div className="d-flex align-items-center h-100">
                                            <Form.Check
                                                type="checkbox"
                                                label="Token Watchlist"
                                                className="watchlist-checkbox"
                                                checked={isTokenWatchlistChecked}
                                                onChange={handleTokenCheckboxChange}
                                                // Disable checkbox if not connected, providing visual feedback as well
                                                disabled={!isConnected}
                                            />
                                        </div>
                                    </div>
                                    {totalPages > 1 &&
                                        <div className='mt-3'>
                                            <div className="filter-label">
                                                Pagination
                                            </div>
                                            <div>
                                                <nav aria-label="Page navigation" className='d-flex mt-3'>
                                                    <ul className="pagination">
                                                        {currentPage > 1 && (
                                                            <li className="page-item">
                                                                <a className="page-link" onClick={() => setCurrentPage(currentPage - 1)} href="#" aria-label="Previous">
                                                                    <span aria-hidden="true">Previous</span>
                                                                </a>
                                                            </li>
                                                        )}
                                                        {renderPageNumbers()}
                                                        {currentPage < totalPages && (
                                                            <li className="page-item">
                                                                <a className="page-link" onClick={() => setCurrentPage(currentPage + 1)} href="#" aria-label="Next">
                                                                    <span aria-hidden="true">Next</span>
                                                                </a>
                                                            </li>
                                                        )}
                                                    </ul>
                                                </nav>
                                            </div>
                                        </div>
                                    }
                                </Card.Body>
                            </Card>
                        </Col>

                    </Row>
                </div>

            </section >
        </div >
    )
}

export default InfluencerDetail;