// components/header.tsx
import React, { useEffect, useState, useRef } from 'react';
import { Navbar, Container, FormControl, DropdownButton, Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import * as Icon from 'react-bootstrap-icons';
import { useWeb3Modal } from '@web3modal/wagmi1/react'
import { useAccount } from 'wagmi'
import { useUser } from '../../components/UserContext';
import NotificationPermissionModal from '../../components/NotificationPermissionModal'
import toast, { Toaster } from 'react-hot-toast';
import { requestPermission, onMessageListener } from '../../firebase/firebase';
import no_img from "../../assets/images/no-image-icon.png"
import './header.css'
import Input from 'react-select/dist/declarations/src/components/Input';

interface SidebarCompProps {
    collapsed: boolean;
}

interface TokenResult {
    type: 'token';
    token_symbol: string | undefined;
    token_name: string;
    url: string;
    id: string;
    token_address: string | undefined;
    image_url: string;
}

interface InfluencerResult {
    type: 'influencer';
    handle: string;
    name: string;
    url: string;
    id: string;
    profile_image_url: string;
}

type SearchResult = TokenResult | InfluencerResult;

const HeaderBot: React.FC<SidebarCompProps> = ({ collapsed }) => {
    const hasNewNotifications = true; // Set this based on your logic
    const { open } = useWeb3Modal()
    const { address, isConnected, isConnecting, isDisconnected } = useAccount()
    const { user, setUser, setWatchlistToken, setWatchlistInfluencer } = useUser();
    const [token, setToken] = useState("");
    const [notification, setNotification] = useState({ title: '', body: '' });
    const [showModal, setShowModal] = useState(false);

    const [searchQuery, setSearchQuery] = useState('');
    const [filteredResults, setFilteredResults] = useState<SearchResult[]>([]);
    const [loading, setLoading] = useState(false);
    const [notFound, setNotFound] = useState<boolean>(false);
    const navigate = useNavigate();
    const searchBarRef = useRef<HTMLDivElement>(null);

    const get_user = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "users/?wallet_id=" + address, {
                method: "GET",
                mode: "cors",
                headers: new Headers({ "content-type": "application/json" })
            });
            if (response.status === 200) {
                const data = await response.json();
                console.log(data['data']);
                setUser(data['data']);

            } else {
                console.log(response.status);
            }
        } catch (e: any) {
            console.log(e);
        }
    }


    // Example API call function
    const sendTokenToServer = async (token: any, walletAddress: any) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/users/token?wallet_id=${walletAddress}&fcm_token=${token}`);

            if (response.ok) {
                console.log('Token and wallet address sent to server successfully.');
            } else {
                console.error('Failed to send token and wallet address to server.');
            }
        } catch (error) {
            console.error('Error sending token and wallet address to server:', error);
        }
    }


    const fetchWatchlist = async (type: string) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}watchlist/?wallet_id=${address}&type=${type}`);
            if (!response.ok) throw new Error('Failed to fetch the watchlist');

            const watchlistData = await response.json();
            console.log(watchlistData)
            if (type == 'Influencer') {
                setWatchlistInfluencer(watchlistData['data'])
            } else {
                setWatchlistToken(watchlistData['data'])
            }

        } catch (error) {
            console.error("Error fetching watchlist:", error);
        }
    };


    useEffect(() => {
        const fetchData = async () => {
            if (isConnected) {
                console.log("connected");

                await get_user(); // Assuming get_user returns a promise
                await fetchWatchlist('Token'); // These calls are assumed to be async as well
                await fetchWatchlist('Influencer');
                console.log(user)
            } else {
                setUser(null);
                setWatchlistInfluencer([])
                setWatchlistToken([])
            }
        };

        fetchData();
    }, [isConnected]); // Assuming fetchWatchlist, setUser, and isConnected are stable or properly handled within the dependency array

    // useEffect(() => {
    //     const handleToken = async () => {
    //         if (isConnected) {
    //             const token = await requestPermission();
    //             if (token) {
    //                 await sendTokenToServer(token, address);
    //             }

    //         }
    //     };

    //     handleToken();
    // }, [isConnected, address]);


    useEffect(() => {
        // Only consider showing the modal if the user is connected and the notification permission is default
        if (isConnected && Notification.permission === "default") {
            setShowModal(true);
        }
    }, [isConnected]);

    const handleEnableNotifications = async () => {
        const token = await requestPermission();
        if (token) {
            await sendTokenToServer(token, address);
        }
        setShowModal(false); // Hide modal after handling permission
    };


    const handleSearch = async (query: any) => {
        setSearchQuery(query);

        if (query.length > 0) {
            setLoading(true);
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}search/universal?wallet_id=${address}&query=${query}&max_results=50`, {
                    method: 'GET',
                    headers: {
                        'Cookie': 'csrftoken=THWyvh3Mhe1B4h3HSNjcQN3zgVBkv61L'
                    }
                });
                const data = await response.json();
                console.log(data)
                const tokenResults = data.data.tokens.map((token: any) => ({
                    type: 'token',
                    token_symbol: token.token_symbol,
                    token_name: token.token_name,
                    token_address: token.token_address,
                    url: token.dexscreen_url,
                    id: token.id,
                    image_url: token.image_url
                }));
                const influencerResults = data.data.influencer.map((influencer: any) => ({
                    type: 'influencer',
                    handle: influencer.username,
                    name: influencer.name,
                    url: influencer.url,
                    id: influencer.id,
                    profile_image_url: influencer.profile_image_url
                }));
                const results = [...tokenResults, ...influencerResults];

                setFilteredResults(results);
                if (results.length === 0) {
                    setNotFound(true);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                setFilteredResults([]);
                setNotFound(true);
            } finally {
                setLoading(false);
            }
        } else {
            setFilteredResults([]);
            setNotFound(false);
        }
    };

    const tokens = filteredResults.filter(result => result.type === 'token') as TokenResult[];
    const influencers = filteredResults.filter(result => result.type === 'influencer') as InfluencerResult[];

    const handleSelect = (result: SearchResult) => {
        if (result.type === 'token') {
            window.open("/token-detail/" + result.id, "_blank");
            // navigate(`/token-detail/${result.id}`);
        } else {
            // navigate(`/influencer-detail/${result.handle}`);
            window.open("/influencer-detail/" + result.id, "_blank");
        }
        setSearchQuery(result.type === 'token' ? (result.token_symbol || result.token_name) : result.handle);
        setFilteredResults([]);
        setNotFound(false);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (searchBarRef.current && !searchBarRef.current.contains(event.target as Node)) {
            setFilteredResults([]);
            setNotFound(false);
        }
    };

    const clearSearch = () => {
        setSearchQuery('');
        setFilteredResults([]);
        setNotFound(false);
    };


    function formatTokenAddress(address: any) {
        if (address) {
            const start = address.substring(0, 6); // Takes first 6 characters
            const end = address.substring(address.length - 4); // Takes last 4 characters
            return `${start}....${end}`;
        }

    }


    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    return (
        <Navbar fixed="top" >
            <Toaster />
            <NotificationPermissionModal
                show={showModal}
                onEnable={handleEnableNotifications}
                onCancel={() => setShowModal(false)}
            />
            <Container>
                {/* Left side (logo) */}

              
                <div className='back-to-make-my-tweet' onClick={() => navigate('/')}>Back to MakemyTweet</div>
                

                <Navbar.Collapse className="justify-content-end">
                    {/* <Navbar.Text>
                        <div className='bell-icon-container'>
                            <Bell color="white" className='bell-icon' />
                            {hasNewNotifications && <div className='notification-dot'></div>}
                        </div>
                    </Navbar.Text> */}
                    <Navbar.Text className='header-menu-item'>
                        {/* <Button  onClick={() => open()} variant="outline-light">Your Button</Button> */}
                        <w3m-button />

                    </Navbar.Text>
                    {user &&
                        <Navbar.Text className='header-menu-item'>
                            <div className='credit-header'>Credit : {user?.credit.toFixed(2)} MATIC</div>
                        </Navbar.Text>
                    }
                </Navbar.Collapse>

            </Container>
        </Navbar>
    );
};

export default HeaderBot;
