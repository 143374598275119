import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { KeywordFilterProps } from './filterTypes';
import './Filter.css';

const KeywordFilter: React.ForwardRefRenderFunction<{ resetFilters: () => void }, KeywordFilterProps> = ({ keywords, onSelectionChange }, ref) => {
    const [search, setSearch] = useState('');
    const [showDropdown, setShowDropdown] = useState(false); // Control dropdown visibility
    const [selectedKeywords, setSelectedKeywords] = useState<string[]>([]);
    const dropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // Show dropdown if there's a search term or input is focused
        setShowDropdown(search.length > 0 || showDropdown);
    }, [search, showDropdown]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            // Check if the dropdownRef is current and if the event target is not within dropdownRef.current
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setShowDropdown(false); // Hide the dropdown
            }
        };

        // Add the event listener to the document to detect all clicks
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            // Cleanup the event listener from the document when the component unmounts or before the effect runs again
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useImperativeHandle(ref, () => ({
        resetFilters
    }));

    const handleSelectionChange = (keyword: string) => {
        const updatedSelection = selectedKeywords.includes(keyword)
            ? selectedKeywords.filter(item => item !== keyword)
            : [...selectedKeywords, keyword];
        setSelectedKeywords(updatedSelection);
        onSelectionChange(updatedSelection);
    };

    const resetFilters = () => {
        setSelectedKeywords([]);
        setSearch('');
        onSelectionChange([]);
    };

    const handleDeselectKeyword = (keyword: string) => {
        const updatedSelection = selectedKeywords.filter(item => item !== keyword);
        setSelectedKeywords(updatedSelection);
        onSelectionChange(updatedSelection);
    };

    const renderSelectedKeywords = () => {
        return selectedKeywords.map(keyword => (
            <div key={keyword} className="selected-keyword-item">
                {keyword}
                <span className="deselect-icon" onClick={() => handleDeselectKeyword(keyword)}>x</span>
            </div>
        ));
    };

    const selectAllKeywords = () => {
        const allKeywordValues = keywords;
        setSelectedKeywords(allKeywordValues);
        onSelectionChange(allKeywordValues); // Update based on how you handle selection changes
    };

    const deselectAllKeywords = () => {
        setSelectedKeywords([]);
        onSelectionChange([]); // Update based on how you handle selection changes
    };

    // Always show all keywords when the dropdown is visible
    const filteredKeywords = showDropdown ? keywords.filter(keyword => keyword.toLowerCase().includes(search.toLowerCase())) : [];

    return (
        <div ref={dropdownRef} className="filter-dropdown-container">
            <div className="search-input-container">
                <input
                    type="text"
                    placeholder="Search keywords..."
                    value={search}
                    onChange={(e) => { setSearch(e.target.value); setShowDropdown(true); }}
                    onFocus={() => setShowDropdown(true)} // Show dropdown on focus
                    className="filter-search-input"
                />
                {search && (
                    <div
                        className="clear-search-button"
                        onClick={() => setSearch('')}
                    >
                        &#x2715; {/* This is a simple "x" character, replace with your icon if preferred */}
                    </div>
                )}
            </div>
            {
                showDropdown && (
                    <div className="filter-dropdown-menu">
                        <div className="select-deselect-buttons">
                            <button className='select-all-btn' onClick={() => selectAllKeywords()}>Select All</button>
                            <button className='de-select-all-btn' onClick={() => deselectAllKeywords()}>Deselect All</button>
                        </div>
                        {filteredKeywords.length > 0 ? filteredKeywords.map((keyword, index) => (
                            <div key={index} className="checkbox-container">
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={selectedKeywords.includes(keyword)}
                                        onChange={() => handleSelectionChange(keyword)}
                                        className="token-checkbox"
                                    />
                                    {keyword}
                                </label>
                            </div>
                        )) : <div className='filter-no-result-found'>No keywords found</div>}
                    </div>
                )
            }
            <div className='selected-keywords-display'>
                {selectedKeywords.length > 0 ? renderSelectedKeywords() : 'No keywords selected'}
            </div>
            {selectedKeywords.length > 0 &&
                <div className="reset-button-container text-center mt-2">
                    <button onClick={resetFilters} className="reset-filter-button">Reset Filters</button>
                </div>
            }
        </div>
    );
};

export default forwardRef(KeywordFilter);
