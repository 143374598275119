import { useState, useRef, useEffect } from 'react';
import { Row, Col, Spinner, Form } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import { Tweet } from 'react-tweet';
import toast, { Toaster } from 'react-hot-toast';
import { useAccount } from 'wagmi'
import TokenFilter from '../components/TokenFilter';
import InfluencerFilter from '../components/InfluencerFilter';
import KeywordFilter from '../components/KeywordFilter';
import { transformInfluencersToCategories } from '../components/utils';
import { Influencer, InfluencerCategory } from '../components/filterTypes';
import './live-feed.css';

interface Feed {
    status_id: string;
    tweetDetail: {
        complete_text: string;
        tweet_id: string;
        publishable: boolean;
        user_profile_picture_url: string;
        user_name: string;
        username: string;
        created_at: string;
    }
    // ... other properties, if they exist
}

const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return isMobile;
};



interface Token {
    label: string;
    value: string;
}

interface Filters {
    tokens: Token[];
    keywords: string[];
    influencer: Influencer[];
}


export const LiveFeed = () => {
    const isMobile = useIsMobile();
    const [liveFeed, setLiveFeed] = useState<Feed[]>([]);
    const [isWatchlistChecked, setIsWatchlistChecked] = useState(false);
    const { address, isConnected } = useAccount()
    const [influencerFilter, setInfluencerFilter] = useState<InfluencerCategory[]>([]);
    const [influencerFilterWithoutCategory, setInfluencerFilterWithoutCategory] = useState<Influencer[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMoreItems, setHasMoreItems] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedInfluencerOption, setSelectedInfluencerOption] = useState<any | null>(null);
    const [hoveredInfluencerOption, setHoveredInfluencerOption] = useState<any | null>(null);
    const [isInfluencerOpen, setIsInfluencerOpen] = useState(false);
    const [lastFeedRefreshTime, setLastFeedRefreshTime] = useState<number | null>(null);
    const [showRefreshButton, setShowRefreshButton] = useState(false);
    const observer = useRef<IntersectionObserver | null>(null);


    //Filter
    const [filters, setFilters] = useState<Filters>({ tokens: [], keywords: [], influencer: [] });
    const [selectedInfluencers, setSelectedInfluencers] = useState<string[]>([]);


    const handleSelectedItemsChange = (selectedItems: string[]) => {
        console.log("handle select")
        setSelectedInfluencers(selectedItems);
        setLiveFeed([]);
        setCurrentPage(1); // Reset pagination to 1 when a new influencer is selected
        setHasMoreItems(true); // Reset hasMoreItems to true
    };



    const get_live_feed = async (influencer: any, page: number) => {
        setIsLoading(true);

        try {

            const watchlistParam = isWatchlistChecked ? `&influencer_watchlist=True` : ''; // Add this line
            // Include wallet_id in the API call if isConnected is true
            const walletIdParam = isConnected ? `&wallet_id=${address}` : '';
            const url = `${process.env.REACT_APP_API_URL}live/feed?items=30&page=${page}${watchlistParam}${walletIdParam}`;
            const requestBody = {
                influencer: influencer ? influencer.join(',') : null
            };
            const response = await fetch(url, {
                method: 'POST',
                mode: 'cors',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    // Include any other headers if needed
                }),
                body: JSON.stringify({ ...requestBody }),
            });

            if (response.status === 200) {
                const data = await response.json();

                // Fetch tweet details using tweet IDs
                const tweetIds = data['data'].map((feed: any) => feed.status_id);
                const tweetDetails = await fetchTweets(tweetIds);

                // Combine the live feed data with the tweet details
                const combinedData = data['data']
                    .filter((feed: any) => tweetDetails.some((tweet: any) => tweet.id === feed.status_id))
                    .map((feed: any) => {
                        const tweetDetail = tweetDetails.find((tweet: any) => tweet.id === feed.status_id);
                        return { ...feed, tweetDetail };
                    });


                // setLiveFeed((prevFeed) => (page === 1 ? data['data'] : [...prevFeed, ...data['data']]));
                setLiveFeed((prevFeed) => (page === 1 ? combinedData : [...prevFeed, ...combinedData]));
                console.log(combinedData)
                setHasMoreItems(data['has_more_items']);
                setCurrentPage(page + 1);
                setLastFeedRefreshTime(Date.now());

            } else {
                console.log(response.status);
            }
        } catch (e: any) {
            console.log(e);
        } finally {
            setIsLoading(false);

        }
    };

    const fetchTweets = async (tweetIds: string[]) => {

        const url = `${process.env.REACT_APP_API_URL}search/tweet?wallet_id=${address}`;
        const payload = { tweet_ids: tweetIds };

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });

            if (response.ok) {
                const data = await response.json();
                const updatedTweets = Object.values(data.tweets).map((tweet: any) => {
                    if (!tweet) return null;
                    return {
                        ...tweet.tweet,
                        publishable: tweet.publishable,
                        complete_text: tweet.tweet.complete_text,
                        user_profile_image_url: tweet.tweet.user_profile_image_url,
                        user_username: tweet.tweet.user_username,
                    };
                }).filter(Boolean);

                return updatedTweets;
            } else {
                console.error('Failed to fetch tweet details', response.status);
                return [];
            }
        } catch (error) {
            console.error('Error fetching tweet details', error);
            return [];
        } finally {

        }
    };





    const handleInfluencerSelect = (option: any) => {
        setSelectedInfluencerOption(option);
        setIsInfluencerOpen(false);
        setLiveFeed([]);
        setCurrentPage(1); // Reset pagination to 1 when a new influencer is selected
        setHasMoreItems(true); // Reset hasMoreItems to true
        console.log("here")
        // Ensure the initial API call is made after resetting state
        get_live_feed(option, 1);

    };

    const get_all_token_filter = async (timeWindow: any) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}home/filters?time_window=${timeWindow}`, {
                method: 'GET',
                mode: 'cors',
                headers: new Headers({ 'content-type': 'application/json' }),
            });

            if (response.status === 200) {
                const data = await response.json();
                setInfluencerFilterWithoutCategory(data['filters']['influencer'])
                const categoriesWithInfluencers = transformInfluencersToCategories(data['filters']['influencer']);
                // Set the state with the formatted data
                setInfluencerFilter(categoriesWithInfluencers);
            } else {
                console.log(response.status);
            }
        } catch (e: any) {
            console.log(e);
        }
    };

    const lazyLoadObserverCallback: IntersectionObserverCallback = (entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting && hasMoreItems && !isLoading) {
                get_live_feed(selectedInfluencerOption, currentPage);
            }
        });
    };


    const clear_data = async () => {
        await setLiveFeed([]);
        await setCurrentPage(1); // Reset pagination to 1 when a new influencer is selected
        await setHasMoreItems(true); // Reset hasMoreItems to true
        await setShowRefreshButton(false)
    }
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setShowRefreshButton(true);
        }, 60000);

        return () => {
            clearTimeout(timeoutId);
        };
    }, [lastFeedRefreshTime]);

    useEffect(() => {
        observer.current = new IntersectionObserver(lazyLoadObserverCallback, { threshold: 0.5 });
        return () => {
            if (observer.current) {
                observer.current.disconnect();
            }
        };
    }, [lazyLoadObserverCallback]);

    useEffect(() => {
        get_live_feed('', currentPage);
        get_all_token_filter('1month');
    }, []);

    useEffect(() => {
        // Check if an influencer option is selected and it's not 'All' before fetching live feed
        if (selectedInfluencers !== null) {
            setLiveFeed([]);
            setCurrentPage(1);
            setHasMoreItems(true); // Reset currentPage to 1 when a new influencer is selected
            get_live_feed(selectedInfluencers, 1);
        } else {
            clear_data()
            console.log("here")
            get_live_feed('', 1);
        }
    }, [selectedInfluencers, isWatchlistChecked]);

    useEffect(() => {
        if (observer.current) {
            observer.current.disconnect();
        }
        observer.current = new IntersectionObserver(lazyLoadObserverCallback, { threshold: 0.5 });
        if (liveFeed.length > 0) {
            const target = document.querySelector('#lazy-load-trigger');
            if (target) {
                observer.current.observe(target);
            }
        }
        return () => {
            if (observer.current) {
                observer.current.disconnect();
            }
        };
    }, [liveFeed, lazyLoadObserverCallback]);

    // Handle checkbox change
    const handleCheckboxChange = (e: any) => {
        if (!isConnected) {
            // Optionally: Provide feedback to the user
            toast.error('Please connect your account to use this feature.', {
                style: {
                    background: 'rgba(0, 29, 255, 0.20)',
                    color: '#fff'
                }
            });
            return; // Prevent checkbox from being toggled
        }
        setIsWatchlistChecked(e.target.checked);
    };

    // Effect to reset checkbox if the user disconnects
    useEffect(() => {
        if (!isConnected) {
            setIsWatchlistChecked(false); // Reset checkbox when user is not connected
        }
    }, [isConnected]);

    const debounce = (func: (...args: any[]) => void, delay: number) => {
        let timeoutId: NodeJS.Timeout;
        return (...args: any[]) => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                func(...args);
            }, delay);
        };
    };

    return (
        <div className="container-fluid">
            <Toaster />
            <section className="first-section section-padding position-relative">
                <div className="container-fluid">
                    <Row>
                        <Col sm={4}>
                            <div className="feed-filter">
                                <div className="filter-label">Filter by Influencer</div>
                                <InfluencerFilter influencers={influencerFilter} influencersWithOutCategory={influencerFilterWithoutCategory} onSelectedItemsChange={handleSelectedItemsChange} />

                                <div className={isMobile ? 'mt-3 d-flex align-items-center h-100' : 'mt-5 d-flex align-items-center h-100'}>
                                    <Form.Check
                                        type="checkbox"
                                        label="Watchlist"
                                        className="watchlist-checkbox"
                                        checked={isWatchlistChecked}
                                        onChange={handleCheckboxChange}
                                        // Disable checkbox if not connected, providing visual feedback as well
                                        disabled={!isConnected}
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col sm={5} className="centered-content">
                            {showRefreshButton && (
                                <div className='latest-feed-loader' onClick={() => { setShowRefreshButton(false); get_live_feed(selectedInfluencerOption, 1) }}>
                                    View Latest Feeds
                                </div>
                            )}
                            <div className="text-center">
                                <div className="s-heading">Live Feed</div>
                            </div>
                            <hr></hr>
                            {liveFeed.map((feed) => (
                                <div data-theme="dark" className='influencer-tweet-feed' key={feed.status_id}>
                                    {feed.tweetDetail?.publishable ? (
                                        <Tweet id={feed.status_id} />
                                    ) : (
                                        <div className="not-found-tweet-container">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <img src={feed.tweetDetail?.user_profile_picture_url} className="t-d-user-img" alt="User" />
                                                </div>
                                                <div>
                                                    <div className="ms-1 ticker-name">{feed.tweetDetail?.user_name}</div>
                                                    <div className="ms-1 ticker-symbol">@{feed.tweetDetail?.username}</div>
                                                </div>
                                            </div>
                                            <div className="tweet-complete-text">{feed.tweetDetail?.complete_text}</div>
                                            <div className='token-detail-timestamp'>
                                                {new Date(feed.tweetDetail?.created_at).toLocaleString()}
                                            </div>
                                            <button className='go-to-tweet-btn mt-2' onClick={() => window.open(`https://twitter.com/username/status/${feed.status_id}`, '_blank')}>
                                                Go To Tweet
                                            </button>
                                        </div>
                                    )}
                                </div>
                            ))}
                            <div id="lazy-load-trigger" style={{ height: '10px', visibility: 'hidden' }} />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>

                            {isLoading && (
                                <div className="mx-auto">
                                    <Spinner animation="border" role="status" variant="light">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                </div>
                            )}
                        </Col>
                    </Row>
                </div>
            </section>
        </div>
    );
};

export default LiveFeed;
