
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from 'firebase/messaging';


const firebaseConfig = {
  apiKey: "AIzaSyCzIWTKs8ABSNy5zJ0x4Tkw9hF6sh1YwmM",
  authDomain: "make-my-tweet.firebaseapp.com",
  projectId: "make-my-tweet",
  storageBucket: "make-my-tweet.appspot.com",
  messagingSenderId: "859428787451",
  appId: "1:859428787451:web:a38489cd8bd5f94e1ccc5e",
  measurementId: "G-NPN5WWY6KK"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);


// Function to request notification permission and get the FCM token
export const requestPermission = async () => {
  console.log("Requesting user permission...");

  // Check if browser supports notifications
  if (!('Notification' in window)) {
    console.log("This browser does not support notifications.");
    return null;
  }

  try {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      console.log("Notification permission granted.");

      // Obtain the FCM token
      const currentToken = await getToken(messaging, { vapidKey: "BJP1XaudxN9JtObc2GgQ7WE08gxsAMaqnVVf0D-NiuLA1LsyC0HVKgcBD-kOSTNvxhgyhP6amTuuA1lpyHYh_z4" });
      if (currentToken) {
        console.log('FCM Token:', currentToken);
        return currentToken; // Return the FCM token
      } else {
        console.log('No FCM token available. Unable to fetch token.');
        return null;
      }
    } else {
      console.log("User denied notification permission.");
      return null;
    }
  } catch (error) {
    console.error("Error during notification permission request:", error);
    return null;
  }
};


// export const onMessageListener = () =>
//   new Promise((resolve) => {
//     onMessage(messaging, (payload) => {
//       console.log("Message received: ", payload);
//       resolve(payload);
//     });
//   });



// Continuous listener setup for incoming messages
export const onMessageListener = (callback) => {
  return onMessage(messaging, (payload) => {
    console.log("Message received: ", payload);
    playNotificationSound();
    callback(payload);
  });
};

const playNotificationSound = () => {
  console.log("play notification")
  const audio = new Audio('/assets/audio/notification-sound.mp3');
  audio.play().catch(error => console.error('Error playing the notification sound:', error));
};