import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { FixedSizeList as List } from 'react-window';
import { TokenFilterPropsNew, Token } from './filterTypes'; // Adjust Token type as per your actual type
import './Filter.css';

const TokenFilter: React.FC<TokenFilterPropsNew> = ({ tokens, onSelectedItemsChange }) => {
  const [selectedTokens, setSelectedTokens] = useState<Set<string>>(new Set());
  const [searchTerm, setSearchTerm] = useState('');
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const searchRef = useRef<number>();

  const resetFilter = () => {
    setSelectedTokens(new Set());
    setSearchTerm('');
    onSelectedItemsChange([]);
  };

  const filteredTokens = useMemo(() => {
    const filtered = tokens.filter(token =>
      token.token.toLowerCase().includes(searchTerm.toLowerCase()) ||
      token.symbol.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Remove duplicates by symbol
    const uniqueTokens = Array.from(new Set(filtered.map(token => token.symbol)));
    return tokens.filter(token => uniqueTokens.includes(token.symbol));
  }, [searchTerm, tokens]);

  const handleTokenSelection = useCallback((tokenSymbol: string, isSelected: boolean) => {
    setSelectedTokens(prevSelectedTokens => {
      const updatedSelectedTokens = new Set(prevSelectedTokens);

      if (isSelected) {
        updatedSelectedTokens.add(tokenSymbol);
      } else {
        updatedSelectedTokens.delete(tokenSymbol);
      }

      onSelectedItemsChange(Array.from(updatedSelectedTokens));
      return updatedSelectedTokens;
    });
  }, [onSelectedItemsChange]);

  const selectAllTokens = () => {
    const allTokenSymbols = tokens.map(token => token.id);
    setSelectedTokens(new Set(allTokenSymbols));
    onSelectedItemsChange(allTokenSymbols);
  };

  const deselectAllTokens = () => {
    setSelectedTokens(new Set());
    onSelectedItemsChange([]);
    setSearchTerm('');
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsDropdownVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);
    setIsDropdownVisible(true);

    // Clear the debounce timeout if it exists
    if (searchRef.current) {
      clearTimeout(searchRef.current);
    }

    // Set a new debounce timeout
    searchRef.current = window.setTimeout(() => {
      setSearchTerm(value);
    }, 300); // Debounce timeout of 300ms
  };

  // const renderSelectedItems = () => {
  //   if (selectedTokens.size > 0) {
  //     return Array.from(selectedTokens).map(token => (
  //       <div key={token} className="selected-token">
  //         {token}
  //         <span className="deselect-icon" onClick={() => handleTokenSelection(token.id, false)}>x</span>
  //       </div>
  //     ));
  //   } else {
  //     return "No token selected";
  //   }
  // };


  const renderSelectedItems = () => {
    if (selectedTokens.size > 0) {
      return filteredTokens.filter(token => selectedTokens.has(token.id)).map(token => (
        <div key={token.id} className="selected-token">
          {token.symbol} - {token.token} {/* Assuming each token object has an 'id' and a 'name' */}
          <span className="deselect-icon" onClick={() => handleTokenSelection(token.id, false)}>x</span>
        </div>
      ));
    } else {
      return "No token selected";
    }
  };

  // Define the type for Row component props
  type RowProps = {
    index: number;
    style: React.CSSProperties;
  };

  // Row component
  const Row: React.FC<RowProps> = ({ index, style }) => {
    const token: Token = filteredTokens[index]; // Adjust Token type as per your actual type

    return (
      <div style={style} className="token-item">
        <label className="token-label">
          <input
            type="checkbox"
            checked={selectedTokens.has(token.id)}
            onChange={(e) => handleTokenSelection(token.id, e.target.checked)}
            className="token-checkbox"
          />
          {token.symbol} - {token.token}
        </label>
      </div>
    );
  };

  return (
    <div ref={dropdownRef} className='filter-dropdown-container'>
      <div className="search-input-container">
        <input
          type="text"
          placeholder="Search tokens..."
          value={searchTerm}
          onChange={handleSearchChange}
          onFocus={() => setIsDropdownVisible(true)}
          className="filter-search-input"
          style={{ paddingRight: '30px' }}
        />
        {searchTerm && (
          <div
            className="clear-search-button"
            onClick={() => setSearchTerm('')}
          >
            ✕
          </div>
        )}
      </div>
      {isDropdownVisible && (
        <div className="filter-dropdown-menu-1">
          <div className="select-deselect-buttons">
            <button className='select-all-btn' onClick={selectAllTokens}>Select All</button>
            <button className='deselect-all-btn' onClick={deselectAllTokens}>Deselect All</button>
          </div>
          {filteredTokens.length > 0 ? (
            <List
              height={300}
              itemCount={filteredTokens.length}
              itemSize={35}
              className='filter-dropdown-list'
              width={'100%'}
            >
              {Row}
            </List>
          ) : (
            <div className="filter-no-result-found">No tokens found</div>
          )}
        </div>
      )}
      <div className="selected-items-display text-center">
        {renderSelectedItems()}
      </div>
      <div className='mx-auto text-center'>
        {selectedTokens.size > 0 && (
          <button onClick={resetFilter} className="mt-2 reset-filter-button">Reset Filters</button>
        )}
      </div>
    </div>
  );
};

export default TokenFilter;
