import TelegramLoginButton from 'telegram-login-button'
import { useAccount } from 'wagmi'
import { useUser } from './UserContext';

export const TelegramOAuth = () => {

const { address, isConnected } = useAccount()
const { setUser } = useUser();
  const handleSignUserIn = async(user) => {
    const { id } = user;
    console.log(id);
    // Connect the user id without your current wallet. 
     // Assuming 'wallet_id' is available in this context, otherwise you'll need to pass it here.
     const wallet_id = address;
     const telegram_id = id;
 
     try {
       const response = await fetch(`${process.env.REACT_APP_API_URL}users/telegram?wallet_id=${wallet_id}&telegram_id=${telegram_id}`, {
         method: 'GET', // Or 'GET', depending on how your backend expects to receive this request
         headers: {
           'Content-Type': 'application/json',
           // Include other headers as required by your backend
         },
         
       });
 
       if (!response.ok) {
         throw new Error('Network response was not ok');
       }
 
       const data = await response.json();
       console.log('Successfully saved Telegram ID:', data);
       setUser(data['data'])
       // Handle success response
     } catch (error) {
       console.error('Error saving Telegram ID:', error);
       // Handle errors
     }
  }
  return (
      <TelegramLoginButton
        dataOnauth={handleSignUserIn}
        botName="MakeMyTweetBot"
      />  
  );
};
