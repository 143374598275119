import React, { useState, useRef, useEffect } from 'react';
import { Row, Col, Card, Form, Table, Spinner, OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import toast, { Toaster } from 'react-hot-toast';
import { useAccount } from 'wagmi'
import * as Icon from 'react-bootstrap-icons';
import Select, { ActionMeta, MultiValue } from 'react-select';
import Swal from 'sweetalert2';

interface Tasks {
    wallet_id: string;
    title: string;
    categoryId: string;
    postPerDay: number;
    botIds: string[];
    customerGroupIds: string[];
    includeCustomerTagIds: string[];
    excludeCustomerTagIds: string[];
    type: string;
    templateContents: string[];
    images: string[];
    keywords: string[];
    created_on: string;
    id: string;
}


interface FormData {
    task_type: string;
    task_type_id: string;
    category_id: string;
    influencer_groups: string[];
    number_of_posts: number;
    bot_ids: string[];
    campaign_name: string;
}



interface TaskTypeFormData {
    name: string;
    greetings: string[];
    task_name: string;
    task_type: string;
    keywords: string[];
}


interface SelectTaskTypeOption {
    value: string;
    label: string;
}

interface SelectCategoryOption {
    value: string;
    label: string;
}


interface SelectBotOption {
    value: string;
    label: string;
}


interface SelectUserGroupOption {
    value: string;
    label: string;
}

interface SelectInfluencerOption {
    value: string;
    label: string;
}


const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return isMobile;
};



export const BotTask = () => {

    const { address, isConnected } = useAccount()
    const isMobile = useIsMobile();
    const [allTaskloader, setAllTaskloader] = useState(false);
    const [allTask, setAllTask] = useState<Tasks[]>([]);
    const [expandedTasks, setExpandedTasks] = useState<{ [key: number]: boolean }>({}); // Track expanded state for each task
    const maxTemplateContentLength = 1;
    const [showTaskCreateModal, setShowTaskCreateModal] = useState(false);
    const [taskType, setSetTaskType] = useState('');
    const [createTaskType, setCreateTaskType] = useState('');
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState<FormData>({
        task_type: '',
        task_type_id: '',
        campaign_name: '',
        category_id: '',
        influencer_groups: [],
        number_of_posts: 0,
        bot_ids: []
    });

    const [defaultGreeting, setDefaultGreeting] = useState(''); // Default greeting state
    const [taskTypeFormData, setTaskTypeFormData] = useState<TaskTypeFormData>({
        name: '',
        greetings: [],
        task_name: '',
        task_type: '',
        keywords: []
    });

    const [userGroupName, setUserGroupName] = useState('');
    const [categoryName, setCategoryName] = useState('');
    const [showAddTaskTypeForm, setShowAddTaskTypeForm] = useState(true);
    const [showAddInfluencerForm, setShowAddInfluencerForm] = useState(true);
    const [showAddCategoryForm, setShowAddCategoryForm] = useState(false);
    const [showAddUserTagForm, setShowAddUserTagForm] = useState(false);
    const [optionsTaskType, setOptionsTaskType] = useState<SelectTaskTypeOption[]>([]);
    const [optionsCategory, setOptionsCategory] = useState<SelectCategoryOption[]>([]);
    const [optionsBot, setOptionsBot] = useState<SelectBotOption[]>([]);
    const [optionsUserGroup, setOptionsUserGroup] = useState<SelectUserGroupOption[]>([]);
    const [optionsInfluencers, setOptionsInfluencers] = useState<SelectInfluencerOption[]>([]);



    // Toggle the expansion for a specific task
    const toggleTemplateContentsContent = (index: number) => {
        setExpandedTasks(prevState => ({
            ...prevState,
            [index]: !prevState[index], // Toggle the specific task's expanded state
        }));
    };


    const get_all_tasks = async () => {
        setAllTaskloader(true);
        setAllTask([]);
        const walletIdParam = isConnected ? `&wallet_id=${address}` : '';

        const url = `${process.env.REACT_APP_API_URL}shill/tasks?wallet_id=${walletIdParam}`;

        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    // Include any other headers if needed
                }),
            });

            if (response.ok) {
                const data = await response.json();
                setAllTask(data['data']);
            } else {
                setAllTask([]);
            }
        } catch (e) {
            console.log(e);
            setAllTaskloader(false);
            setAllTask([]);
        } finally {
            setAllTaskloader(false);
        }
    };

    const get_all_token_filter = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "home/filters?time_window=1month", {
                method: "GET",
                mode: "cors",
                headers: new Headers({ "content-type": "application/json" })
            });
            if (response.status === 200) {
                const data = await response.json();
                // setUserGroups(data['data'])
                const formattedOptions = data['filters']['influencer'].map((influencer: any) => {
                    return {
                        value: influencer.user_username,
                        label: influencer.user_username,
                    };
                });
                setOptionsInfluencers(formattedOptions)
            } else {
                console.log(response.status);
            }
        } catch (e: any) {
            console.log(e);
        }
    }

    const get_task_type = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "/shill/task-types?wallet_id=" + address, {
                method: "GET",
                mode: "cors",
                headers: new Headers({ "content-type": "application/json" })
            });
            if (response.status === 200) {
                const data = await response.json();
                // setUserGroups(data['data'])
                const formattedOptions = data['data'].map((task_type: any) => {

                    return {
                        value: task_type.id,
                        label: task_type.task_name,
                    };
                });
                // Prepend the "empty field" option
                setOptionsTaskType([{ value: '', label: 'Select a task type' }, ...formattedOptions]);
            } else {
                console.log(response.status);
            }
        } catch (e: any) {
            console.log(e);
        }
    }

    const get_category = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "shill/categories?wallet_id=" + address, {
                method: "GET",
                mode: "cors",
                headers: new Headers({ "content-type": "application/json" })
            });
            if (response.status === 200) {
                const data = await response.json();
                // setUserGroups(data['data'])
                const formattedOptions = data['data'].map((category: any) => {

                    return {
                        value: category.id,
                        label: category.name,
                    };
                });
                // Prepend the "empty field" option
                setOptionsCategory([{ value: '', label: 'Select a category' }, ...formattedOptions]);
            } else {
                console.log(response.status);
            }
        } catch (e: any) {
            console.log(e);
        }
    }

    const get_user_groups = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "/shill/user-groups?wallet_id=" + address, {
                method: "GET",
                mode: "cors",
                headers: new Headers({ "content-type": "application/json" })
            });
            if (response.status === 200) {
                const data = await response.json();
                // setUserGroups(data['data'])
                const formattedOptions = data['data'].map((user_group: any) => {

                    return {
                        value: user_group.id,
                        label: user_group.name,
                    };
                });
                // Prepend the "empty field" option
                setOptionsUserGroup([{ value: '', label: 'Select a user group' }, ...formattedOptions]);
            } else {
                console.log(response.status);
            }
        } catch (e: any) {
            console.log(e);
        }
    }

    const get_bots = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "shill/bots?wallet_id=" + address, {
                method: "GET",
                mode: "cors",
                headers: new Headers({ "content-type": "application/json" })
            });
            if (response.status === 200) {
                const data = await response.json();
                // setUserGroups(data['data'])
                const formattedOptions = data['data'].map((bot: any) => {

                    return {
                        value: bot.id,
                        label: bot.id,
                    };
                });
                // Prepend the "empty field" option
                setOptionsBot([{ value: '', label: 'Select a bot' }, ...formattedOptions]);
            } else {
                console.log(response.status);
            }
        } catch (e: any) {
            console.log(e);
        }
    }

    const handleNewInfluencerFormChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { id, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [id]: value
        }));
    };


    const edit_influencer = () => {
    }

    const delete_influencer = () => {
    }


    const showAlertWithInputs = () => {

        Swal.fire({
            title: 'Enter the details to generate content',
            html: `
            <input type="text" id="field1" class="swal2-input" placeholder="Content">
            <input type="text" id="field2" class="swal2-input" placeholder="Number of messages">
          `,
            confirmButtonText: 'Generate',
            focusConfirm: false, // Prevent focus on confirm button initially
            preConfirm: () => {
                const field1Element = document.getElementById('field1') as HTMLInputElement;
                const field2Element = document.getElementById('field2') as HTMLInputElement;

                if (!field1Element || !field2Element || !field1Element.value || !field2Element.value) {
                    Swal.showValidationMessage('Please enter both fields');
                    return false;
                }

                const field1 = field1Element.value;
                const field2 = field2Element.value;
                return { field1, field2 };
            }
        }).then((result) => {
            if (result.isConfirmed) {
                setShowTaskCreateModal(true);
                handleApiCall(result.value.field1, result.value.field2);
            }
        });
    };

    const handleApiCall = async (field1: any, field2: any) => {
        // Here, call your API using fetch or axios with the input values
        try {
            const url = `${process.env.REACT_APP_API_URL}/shill/generate?wallet_id=${address}`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    content: field1,
                    number_of_messages: field2,
                }),
            });

            const data = await response.json();

            if (response.ok) {
                setDefaultGreeting(data['data']['content'][0]); // Use the first greeting in the default textbox
                setTaskTypeFormData((prevState) => ({
                    ...prevState,
                    greetings: data['data']['content'].slice(1) // Store additional greetings for extra textboxes
                }));
                Swal.fire('Success', 'Content generated successfully!', 'success');
            } else {
                Swal.fire('Error', data.message || 'Something went wrong', 'error');
            }
        } catch (error) {
            Swal.fire('Error', 'Failed to connect to the API', 'error');
        }
    };

    // Handler to update a specific greeting
    const handleGreetingChange = (index: number, value: string) => {
        const updatedGreetings = [...taskTypeFormData.greetings];
        updatedGreetings[index] = value;
        setTaskTypeFormData((prevState) => ({
            ...prevState,
            greetings: updatedGreetings
        }));
    };

    // Handler to delete a specific greeting
    const handleDeleteGreeting = (index: number) => {
        const updatedGreetings = taskTypeFormData.greetings.filter((_, i) => i !== index);
        setTaskTypeFormData((prevState) => ({
            ...prevState,
            greetings: updatedGreetings
        }));
    };

    // Handler for the default greeting textbox
    const handleDefaultGreetingChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setDefaultGreeting(e.target.value);
    };

    const addNewTaskType = () => {

        // Reset the taskTypeFormData to its initial state
        setTaskTypeFormData({
            name: '',
            greetings: [], // Reset greetings array
            task_name: '',
            task_type: '',
            keywords: []
        });

        // Reset the default greeting as well
        setDefaultGreeting('');

        setShowAddInfluencerForm(false)
        setShowAddUserTagForm(false)
        setShowAddCategoryForm(false)
        setShowAddTaskTypeForm(true)
    }

    const Show = () => {
    }

    const addNewCategory = () => {
        setShowAddInfluencerForm(false)
        setShowAddTaskTypeForm(false)
        setShowAddCategoryForm(true)
    }

    const addNewUserTag = () => {
        setShowAddInfluencerForm(false)
        setShowAddCategoryForm(false)
        setShowAddUserTagForm(true)
    }

    const handleCreateNewTaskClick = (type: any) => {
        setSetTaskType(type)
        setLoading(false)
        setFormData({
            task_type: '',
            task_type_id: '',
            campaign_name: '',
            category_id: '',
            influencer_groups: [],
            number_of_posts: 0,
            bot_ids: []
        })
        setShowAddTaskTypeForm(false);
        setShowAddUserTagForm(false);
        setShowAddInfluencerForm(true);
        setShowTaskCreateModal(true);
    }

    const handleCreateTask = () => {
        if (formData) {
            setLoading(true);
            const url = `${process.env.REACT_APP_API_URL}shill/tasks?wallet_id=${address}`;

            // Conditionally add campaign_name based on task_type
            const payload = {
                task_type: formData.task_type,
                task_type_id: formData.task_type_id,
                category_id: formData.category_id,
                influencer_groups: formData.influencer_groups,
                number_of_posts: formData.number_of_posts,
                bot_ids: formData.bot_ids,
                ...(formData.task_type === 'shill-your-bag' && { campaign_name: formData.campaign_name }),
            };

            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    return response.json();
                })
                .then((data) => {
                    console.log('Success:', data);
                    setLoading(false);
                    setShowTaskCreateModal(false);
                    toast.success('Task created successfully', {
                        style: {
                            background: 'rgba(0, 29, 255, 0.20)',
                            color: '#fff',
                        },
                    });
                    get_all_tasks();
                })
                .catch((error) => {
                    console.error('Error:', error);
                    setLoading(false);
                    if (error.message.includes('500')) {
                        toast.error('Internal Server Error (500): Failed to create task', {
                            style: {
                                background: 'rgba(255, 0, 0, 0.20)',
                                color: '#fff',
                            },
                        });
                    } else {
                        toast.error('Failed to create task', {
                            style: {
                                background: 'rgba(255, 0, 0, 0.20)',
                                color: '#fff',
                            },
                        });
                    }
                });
        }
    };


    const handleCreateTaskType = () => {

        if (taskTypeFormData) {
            setLoading(true);
            const url = `${process.env.REACT_APP_API_URL}shill/task-types?wallet_id=${address}`;
            const payload = {
                ...taskTypeFormData
            };

            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    return response.json();
                })
                .then((data) => {
                    console.log('Success:', data);
                    setLoading(false);
                    setShowAddTaskTypeForm(false)
                    setShowAddInfluencerForm(true)
                    toast.success('Task Type created successfully', {
                        style: {
                            background: 'rgba(0, 29, 255, 0.20)',
                            color: '#fff',
                        },
                    });
                    get_task_type();
                })
                .catch((error) => {
                    console.error('Error:', error);
                    setLoading(false);
                    if (error.message.includes('500')) {
                        toast.error('Internal Server Error (500): Failed to create user group', {
                            style: {
                                background: 'rgba(255, 0, 0, 0.20)',
                                color: '#fff',
                            },
                        });
                    } else {
                        toast.error('Failed to create user group', {
                            style: {
                                background: 'rgba(255, 0, 0, 0.20)',
                                color: '#fff',
                            },
                        });
                    }
                });
        }

    }

    const handleCreateCategory = () => {
        if (categoryName) {
            setLoading(true);
            const url = `${process.env.REACT_APP_API_URL}shill/categories?wallet_id=${address}`;
            const payload = {
                "name": categoryName
            };

            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    return response.json();
                })
                .then((data) => {
                    console.log('Success:', data);
                    setLoading(false);
                    setShowAddTaskTypeForm(false)
                    setShowAddCategoryForm(false)
                    setShowAddInfluencerForm(true)
                    toast.success('Category created successfully', {
                        style: {
                            background: 'rgba(0, 29, 255, 0.20)',
                            color: '#fff',
                        },
                    });
                    get_category();
                })
                .catch((error) => {
                    console.error('Error:', error);
                    setLoading(false);
                    if (error.message.includes('500')) {
                        toast.error('Internal Server Error (500): Failed to create category', {
                            style: {
                                background: 'rgba(255, 0, 0, 0.20)',
                                color: '#fff',
                            },
                        });
                    } else {
                        toast.error('Failed to create category', {
                            style: {
                                background: 'rgba(255, 0, 0, 0.20)',
                                color: '#fff',
                            },
                        });
                    }
                });
        }
    }


    const handleTaskIdChange = (selectedOption: any) => {
        setFormData((prevState) => ({
            ...prevState,
            task_type_id: selectedOption ? selectedOption.value : '',
            task_type: selectedOption ? selectedOption.label : '',
        }));
    };

    const handleTaskFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleCategoryChange = (selectedOptions: any) => {
        // Map over the selected options to get the array of values
        const selectedValues = selectedOptions ? selectedOptions.map((option: any) => option.value) : [];

        setFormData((prevState) => ({
            ...prevState,
            category_id: selectedValues, // Update formData with the array of selected values
        }));
    };


    const handleBotChange = (selectedOptions: any) => {
        // Map over the selected options to get the array of values
        const selectedValues = selectedOptions ? selectedOptions.map((option: any) => option.value) : [];

        setFormData((prevState) => ({
            ...prevState,
            bot_ids: selectedValues, // Update formData with the array of selected values
        }));
    };

    const handleUserGroupsChange = (selectedOptions: MultiValue<{ value: string, label: string }>) => {
        const catIds = selectedOptions ? selectedOptions.map(option => option.value) : [];
        setFormData(prevState => ({
            ...prevState,
            influencer_groups: catIds, // Update form data with selected group IDs
        }));
    };

    const handleTaskTypeFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setTaskTypeFormData({
            ...taskTypeFormData,
            [name]: value
        });
    };

    useEffect(() => {
        get_all_tasks()
        get_task_type()
        get_category()
        get_user_groups()
        get_bots()
        get_all_token_filter()
    }, []);

    return (
        <>
            <div className="container-fluid">
                <Toaster />
                <section className="section-padding position-relative mt-4">
                    <div className="container-fluid">
                        <Row>
                            <Col sm={12}>
                                <div className='me-auto'>
                                    <div className="s-heading">
                                        Influencer List
                                    </div>

                                </div>
                            </Col>
                            <Col sm={3} className="mt-3">
                                <div className='ctw-btn mt-2' onClick={() => handleCreateNewTaskClick('greeting-bot')}>
                                    Create New Task - Greeting Bot
                                </div>
                            </Col>
                            <Col sm={3} className="mt-3">
                                <div className='ctw-btn mt-2' onClick={() => handleCreateNewTaskClick('shill-your-bag')}>
                                    Create New Task - Shill Your Bag
                                </div>
                            </Col>
                            <Col sm={12} className='mt-3'>

                                {allTaskloader &&
                                    <Spinner animation="border" role="status" variant="light" >
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                }
                                {!allTaskloader &&
                                    <>
                                        <div className={isMobile ? 'atw-table-container table-responsive-md' : 'atw-table-container'}>
                                            <Table className='atw-table'>
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Wallet Id</th>
                                                        <th>Title</th>
                                                        <th>Category Id</th>
                                                        <th>Post Per Day</th>
                                                        <th>Type</th>
                                                        <th>TemplateContents</th>
                                                        <th>keywords</th>
                                                        <th>Created On</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {allTask.length > 0 ? (
                                                        allTask.map((task, index) => (
                                                            <tr key={index} className={allTask.length === 1 ? 'custom-single-row-height' : ''}>
                                                                <td width={'2%'}>
                                                                    <div className='d-flex align-items-center gap-2'>
                                                                        {index + 1}
                                                                    </div>
                                                                </td>
                                                                <td width={'30%'} className='cursor-pointer'>
                                                                    {task['wallet_id']}
                                                                </td>
                                                                <td width={'10%'}>{task['title']}</td>
                                                                <td width={'18%'}>
                                                                    {task['categoryId']}
                                                                </td>
                                                                <td width={'10%'}>
                                                                    {task['postPerDay']}
                                                                </td>
                                                                <td>
                                                                    {
                                                                        task['type']
                                                                    }
                                                                </td>

                                                                <td>

                                                                    {
                                                                        task['templateContents'].length > maxTemplateContentLength && !expandedTasks[index]
                                                                            ? (
                                                                                <>
                                                                                    {task['templateContents'].slice(0, maxTemplateContentLength)}...
                                                                                    <span
                                                                                        onClick={() => toggleTemplateContentsContent(index)}
                                                                                        style={{ color: 'blue', cursor: 'pointer' }}
                                                                                    >
                                                                                        Read More
                                                                                    </span>
                                                                                </>
                                                                            )
                                                                            : (
                                                                                <>
                                                                                    {task['templateContents']}
                                                                                    {
                                                                                        task['templateContents'].length > maxTemplateContentLength && (
                                                                                            <span
                                                                                                onClick={() => toggleTemplateContentsContent(index)}
                                                                                                style={{ color: 'blue', cursor: 'pointer' }}
                                                                                            >
                                                                                                {expandedTasks[index] ? 'Read Less' : ''}
                                                                                            </span>
                                                                                        )
                                                                                    }
                                                                                </>
                                                                            )
                                                                    }
                                                                </td>


                                                                <td>
                                                                    {
                                                                        task['keywords']
                                                                    }
                                                                </td>
                                                                <td width={'10%'}>
                                                                    {task['created_on']}
                                                                </td>

                                                            </tr>
                                                        ))
                                                    ) : (
                                                        // Render this row if allTokenTweets is empty
                                                        <tr>
                                                            <td colSpan={7} style={{ textAlign: 'center' }}>No data available</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </Table>
                                        </div>





                                        <Modal aria-modal="true" className='tweet-modal' show={showTaskCreateModal} onHide={() => setShowTaskCreateModal(false)} centered>
                                            <Modal.Header className="justify-content-between">
                                                <div className='update-modal-heading'>
                                                    {showAddInfluencerForm &&
                                                        "Create New Task - Greeting Bot"
                                                    }
                                                    {showAddTaskTypeForm &&
                                                        "Create New Task Type"
                                                    }
                                                    {showAddUserTagForm &&
                                                        "Create New User Tag"
                                                    }
                                                </div>
                                                <Icon.XCircle size={30} className='modal-close-icon' onClick={() => setShowTaskCreateModal(false)}></Icon.XCircle>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <div className="row">
                                                    {showAddInfluencerForm &&
                                                        <div className="col-md-12">


                                                            <div className='d-flex align-items-center mt-2'>
                                                                <label className="token-description-label">Task Type:</label>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-sm btn-secondary ms-2 mt-3 mb-2"
                                                                    onClick={() => addNewTaskType()}
                                                                >
                                                                    Add New Type
                                                                </button>
                                                            </div>

                                                            <Select
                                                                id="task_type_id"
                                                                name="task_type_id"
                                                                options={optionsTaskType}
                                                                value={optionsTaskType.find(option => option.value === formData.task_type_id) || null} // Find the selected value
                                                                onChange={handleTaskIdChange}
                                                                className="ticker-text-box"
                                                                classNamePrefix="admin-select"
                                                            />

                                                            <div className='d-flex align-items-center mt-2'>
                                                                <label className='token-description-label mt-2' htmlFor="password">Category :</label>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-sm btn-secondary ms-2 mt-3 mb-2"
                                                                    onClick={() => addNewCategory()}
                                                                >
                                                                    Add New Category
                                                                </button>
                                                            </div>
                                                            <Select
                                                                id="category_id"
                                                                name="category_id"
                                                                options={optionsCategory}
                                                                value={
                                                                    Array.isArray(formData.category_id)
                                                                        ? optionsCategory.filter(option => formData.category_id.includes(option.value))
                                                                        : null
                                                                }
                                                                onChange={handleCategoryChange}
                                                                isMulti
                                                                className="ticker-text-box"
                                                                classNamePrefix="admin-select"
                                                            />


                                                            <label className='token-description-label mt-3' htmlFor="mail">Influencer Groups:</label>

                                                            <Select
                                                                id="user_tag"
                                                                name="user_tag"
                                                                options={optionsUserGroup}
                                                                isMulti
                                                                value={optionsUserGroup.filter(option => formData.influencer_groups.includes(option.value)) || null}
                                                                onChange={handleUserGroupsChange}
                                                                onFocus={() => {
                                                                    // Clear the selected values on focus
                                                                    handleUserGroupsChange([]);
                                                                }}
                                                                className="ticker-text-box"
                                                                classNamePrefix="admin-select"
                                                            />


                                                            <label className="token-description-label mt-3">Bots:</label>
                                                            <Select
                                                                id="bot_ids"
                                                                name="bot_ids"
                                                                options={optionsBot}
                                                                value={
                                                                    Array.isArray(formData.bot_ids)
                                                                        ? optionsBot.filter(option => formData.bot_ids.includes(option.value))
                                                                        : null
                                                                }
                                                                onChange={handleBotChange}
                                                                isMulti
                                                                className="ticker-text-box"
                                                                classNamePrefix="admin-select"
                                                            />

                                                            <label className="token-description-label mt-3">Number Of Post:</label>
                                                            <input
                                                                id="number_of_posts"
                                                                name="number_of_posts"
                                                                className="form-control mt-2 ticker-text-box"
                                                                value={formData.number_of_posts}
                                                                onChange={handleTaskFormChange}
                                                            />

                                                            {taskType == 'shill-your-bag' &&
                                                                <>

                                                                    <label className="token-description-label mt-3">Campaign Name:</label>
                                                                    <input
                                                                        id="campaign_name"
                                                                        name="campaign_name"
                                                                        className="form-control mt-2 ticker-text-box"
                                                                        value={formData.campaign_name}
                                                                        onChange={handleTaskFormChange}
                                                                    />
                                                                </>
                                                            }


                                                        </div>
                                                    }
                                                    {showAddTaskTypeForm &&
                                                        <div className="col-md-12">
                                                            <label className="token-description-label">Name:</label>
                                                            <input
                                                                id="name"
                                                                name="name"
                                                                className="form-control mt-2 ticker-text-box"
                                                                value={taskTypeFormData.name}
                                                                onChange={handleTaskTypeFormChange}
                                                            />


                                                            <div className='d-flex align-items-center mt-2'>
                                                                <label className="token-description-label">Greetings:</label>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-sm btn-secondary ms-2 mt-3 mb-2"
                                                                    onClick={() => { setShowTaskCreateModal(false); showAlertWithInputs(); }}
                                                                >
                                                                    Generate Content
                                                                </button>
                                                            </div>

                                                            {/* Default greeting input - shown on load */}
                                                            <textarea
                                                                id="defaultGreeting"
                                                                className="form-control token-description-text-area mt-3"
                                                                value={defaultGreeting}
                                                                onChange={handleDefaultGreetingChange}
                                                                placeholder="Enter default greeting"
                                                                style={{ height: '100px' }}
                                                            />

                                                            {taskTypeFormData.greetings.length > 0 && taskTypeFormData.greetings.map((greeting, index) => (
                                                                <div key={index} className="d-flex mt-3">
                                                                    <textarea
                                                                        className="form-control token-description-text-area"
                                                                        value={greeting}
                                                                        onChange={(e) => handleGreetingChange(index, e.target.value)}
                                                                        style={{ height: '100px' }}
                                                                    />
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-danger btn-sm ms-2"
                                                                        onClick={() => handleDeleteGreeting(index)}
                                                                    >
                                                                        Delete
                                                                    </button>
                                                                </div>
                                                            ))}


                                                            <label className="token-description-label mt-3">Task name:</label>
                                                            <input
                                                                id="name"
                                                                className="form-control mt-2 ticker-text-box"
                                                                name="task_name"
                                                                value={taskTypeFormData.task_name}
                                                                onChange={handleTaskTypeFormChange}
                                                            />

                                                            <label className="token-description-label mt-3">Task Type:</label>
                                                            <input
                                                                id="name"
                                                                className="form-control mt-2 ticker-text-box"
                                                                name="task_type"
                                                                value={taskTypeFormData.task_type}
                                                                onChange={handleTaskTypeFormChange}
                                                            />

                                                            <label className="token-description-label mt-3">Keyword:</label>
                                                            <input
                                                                id="name"
                                                                className="form-control mt-2 ticker-text-box"
                                                                name="keywords"
                                                                value={taskTypeFormData.keywords}
                                                                onChange={handleTaskTypeFormChange}
                                                            />
                                                        </div>
                                                    }
                                                    {showAddCategoryForm &&
                                                        <div className="col-md-12">
                                                            <label className="token-description-label">Category Name:</label>
                                                            <input
                                                                id="name"
                                                                className="form-control mt-2 ticker-text-box"
                                                                value={categoryName}
                                                                onChange={(event) => setCategoryName(event.target.value)}
                                                            />
                                                        </div>
                                                    }
                                                </div>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                {showAddInfluencerForm &&
                                                    <>
                                                        <button className="btn btn-primary" onClick={handleCreateTask} disabled={loading}>
                                                            {loading ? <Spinner animation="border" size="sm" /> : 'Create Task'}
                                                        </button>
                                                        <button className="btn btn-secondary" onClick={() => setShowTaskCreateModal(false)}>Close</button>
                                                    </>
                                                }
                                                {showAddTaskTypeForm &&
                                                    <>
                                                        <button className="btn btn-primary" onClick={handleCreateTaskType} disabled={loading}>
                                                            {loading ? <Spinner animation="border" size="sm" /> : 'Create Task Type'}
                                                        </button>
                                                        <button className="btn btn-secondary" onClick={() => { setShowAddTaskTypeForm(false); setShowAddInfluencerForm(true); }}>Back</button>
                                                    </>
                                                }
                                                {showAddCategoryForm &&
                                                    <>
                                                        <button className="btn btn-primary" onClick={handleCreateCategory} disabled={loading}>
                                                            {loading ? <Spinner animation="border" size="sm" /> : 'Create Category'}
                                                        </button>
                                                        <button className="btn btn-secondary" onClick={() => { setShowAddCategoryForm(false); setShowAddInfluencerForm(true); }}>Back</button>
                                                    </>
                                                }


                                            </Modal.Footer>
                                        </Modal>

                                    </>}
                            </Col>
                        </Row>
                    </div>
                </section >
            </div >
        </>
    )
}

export default BotTask;