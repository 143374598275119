import { useState, useRef, useEffect } from 'react';
import { Row, Col, Card, Form, Table, Spinner } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import * as Icon from 'react-bootstrap-icons';
import { HelioCheckout } from '@heliofi/checkout-react'
import { useAccount } from 'wagmi'
import { useUser } from '../components/UserContext';
import toast, { Toaster } from 'react-hot-toast';
import { TelegramOAuth } from '../components/TelegramOAuth';

import profile_img from "../assets/images/man.png"
import './profile.css'


type DepositType = {
    deposited_on: { $date: string } | undefined; // Explicitly allowing `undefined` values
    deposit_amount: number;
    transaction_hash: string;
};

const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return isMobile;
};

export const Profile = () => {
    const isMobile = useIsMobile();
    const { address, isConnected } = useAccount()
    const [credit, setCredit] = useState(0)
    const [depositAmount, setDepositAmount] = useState('0.001')
    const [depositHistory, setDepositHistory] = useState<DepositType[]>([]);
    const { user, setUser } = useUser();

    const navigate = useNavigate();
    const back_to_home = () => {
        navigate(-1);
    }

    const handleDepositChange = (event: any) => {
        // Update state with the new value from the input
        // Use parseFloat or parseInt depending on the desired type (float or integer)
        const newValue = event.target.value;
        setDepositAmount(newValue);
    };

    const helioConfig = {
        paylinkId: "65b8bcd863ab732c23038858",
        theme: {
          themeMode: "dark",
          colors: {
            primaryButtonBackground: "#001DFF",
            primaryButtonText: "#FFFFFF",
            primaryButtonBackgroundHover: "#0000FF", // Add this line with your desired hover color
          },
        },
        network: "test",
        amount: depositAmount,
        customTexts: {
            mainButtonTitle: "Pay Now",
        },
        onSuccess: (event: any) => {
            console.log(event)
            paymentSuccess(event)
        },
        onError: (event: any) => {
            console.log(event)
            toast.error(event, {
                style: {
                    background: 'rgba(0, 29, 255, 0.20)',
                    color: '#fff'
                }
            });
        },
        onPending: (event: any) => console.log(event),
        onCancel: () => console.log("Cancelled payment"),
        onStartPayment: () => console.log("Starting payment"),
    } as const

    const paymentSuccess = async (e: any) => {
        console.log(e);
        let order_details = {
            deposit_amount: Number(depositAmount),
            currency: "MATIC",
            transaction_hash: e['data']['transactionSignature']
        };
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "deposit/?wallet_id=" + address, {
                method: "POST",
                mode: "cors",
                headers: new Headers({ "content-type": "application/json" }),
                body: JSON.stringify(order_details),
            });
            if (response.status === 200) {
                const data = await response.json();
                console.log(data);
                get_user()
                toast.success('Deposited Successfully', {
                    style: {
                        background: 'rgba(0, 29, 255, 0.20)',
                        color: '#fff'
                    }
                });


            } else {
                console.log(response.status);
                toast.error('Deposit Unsuccessful', {
                    style: {
                        background: 'rgba(0, 29, 255, 0.20)',
                        color: '#fff'
                    }
                });
            }
        } catch (e: any) {
            console.log(e);
            toast.error(e, {
                style: {
                    background: 'rgba(0, 29, 255, 0.20)',
                    color: '#fff'
                }
            });
        }
    };

    const get_user = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "users/?wallet_id=" + address, {
                method: "GET",
                mode: "cors",
                headers: new Headers({ "content-type": "application/json" })
            });
            if (response.status === 200) {
                const data = await response.json();
                console.log(data['data']);
                setUser(data['data']);

            } else {
                console.log(response.status);
            }
        } catch (e: any) {
            console.log(e);
        }
    }


    function formatDate(dateInput: any) {
        // Check if dateInput is an object with a $date property
        const dateString = typeof dateInput === 'object' && dateInput !== null ? dateInput.$date : dateInput;

        if (!dateString) return '';

        const date = new Date(dateString);
        return new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: 'short',
            day: '2-digit',
        }).format(date);
    }

    function formatTokenAddress(address: any) {
        if (address) {
            const start = address.substring(0, 6); // Takes first 6 characters
            const end = address.substring(address.length - 4); // Takes last 4 characters
            return `${start}....${end}`;
        }

    }

    const add_or_remove_telegram = async (telegram_id: any) => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "users/telegram?wallet_id=" + address + "&telegram_id=" + telegram_id, {
                method: "GET",
                mode: "cors",
                headers: new Headers({ "content-type": "application/json" })
            });
            if (response.status === 200) {
                const data = await response.json();
                console.log(data['data']);
                setUser(data['data']);

            } else {
                console.log(response.status);
            }
        } catch (e: any) {
            console.log(e);
        }
    }

    useEffect(() => {
        console.log(user);
        if (user !== null) {
            setCredit(user.credit);

            // Check if user.deposit exists and is an array before mapping
            if (user.deposit && Array.isArray(user.deposit)) {
                const transformedDeposits = user.deposit.map(deposit => ({
                    ...deposit,
                    // Ensure deposit.deposited_on exists or provide a fallback value
                    deposited_on: deposit.deposited_on ? { $date: deposit.deposited_on } : undefined,
                }));
                setDepositHistory(transformedDeposits);
            } else {
                // Handle the case where user.deposit is null or undefined
                // For example, you might want to set an empty array or a default value
                setDepositHistory([]);
            }
        }
    }, [user]);


    return (
        <div className="container-fluid">
            <Toaster />
            <section className="first-section section-padding position-relative">
                <div className="container-fluid">
                    <Row>
                        <Col sm={12}>
                            <div className='back-btn' onClick={back_to_home}>
                                <Icon.ArrowLeft size={20}></Icon.ArrowLeft>  Back
                            </div>
                        </Col>
                    </Row>
                    <Row className='mt-5'>

                        <Col sm={4}>
                            <div className="s-heading">
                                Personal Details
                            </div>
                            <Card className="t-w-card mt-4">
                                <Card.Body>
                                    <div className='d-flex'>
                                        <img className='mx-auto' src={profile_img} />
                                    </div>
                                    <Row className='mt-3'>
                                        <Col sm={6} className='text-center'>

                                            <div className="filter-label">
                                                Wallet ID
                                            </div>
                                            <div className='profile-text'>
                                                {formatTokenAddress(address)}
                                            </div>
                                        </Col>
                                        <Col sm={6} className='text-center'>
                                            <div className="filter-label">
                                                Date Joined
                                            </div>
                                            <div className='profile-text'>
                                                {formatDate(user?.joined_on || '')}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className='mt-3'>
                                        {user?.telegram_id && user?.telegram_id != null && user?.telegram_id != 'null' &&
                                            <Col sm={6} className='text-center'>
                                                <div className="filter-label">
                                                    Telegram ID
                                                </div>
                                                <div className='profile-text'>
                                                    {user?.telegram_id}
                                                </div>
                                                <div className='remove-telegram' onClick={() => add_or_remove_telegram(null)}>
                                                    Remove
                                                </div>
                                            </Col>
                                        }
                                        {(!user?.telegram_id || user?.telegram_id == null || user?.telegram_id == 'null') &&
                                            <Col sm={6} className='text-center'>
                                                <div className="filter-label">
                                                    Telegram ID
                                                </div>
                                                <div className='profile-text'>
                                                    <TelegramOAuth />                                                   
                                                </div>

                                            </Col>
                                        }
                                        <Col sm={6} className='text-center mx-auto'>
                                            <div className="filter-label">
                                                Credit
                                            </div>
                                            <div className='profile-text'>
                                                {credit.toFixed(2)} MATIC
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>

                            <div className="mt-4 s-heading">
                                Deposit to account
                            </div>

                            <Card className="t-w-card mt-4">
                                <Card.Body>
                                    {isConnected &&
                                        <Row>

                                            <Col sm={12}>
                                                <div className="filter-label">
                                                    Deposit amount
                                                </div>
                                                <div className='mt-3 mb-3'>
                                                    <input className='deposit-input' type='number' value={depositAmount} onChange={handleDepositChange} />
                                                </div>
                                                <div className='d-flex'>
                                                    <div className='pre-amt' onClick={() => setDepositAmount('0.001')}>
                                                        0.001
                                                    </div>
                                                    <div className='pre-amt' onClick={() => setDepositAmount('0.005')}>
                                                        0.005
                                                    </div>
                                                    <div className='pre-amt' onClick={() => setDepositAmount('0.01')}>
                                                        0.01
                                                    </div>
                                                    <div className='pre-amt' onClick={() => setDepositAmount('0.05')}>
                                                        0.05
                                                    </div>
                                                </div>
                                                <div className='mt-3 justify-content-center'>
                                                    <HelioCheckout config={helioConfig} />
                                                </div>

                                            </Col>
                                        </Row>
                                    }
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm={8}>
                            <div className={isMobile ? 's-heading mt-3' : 's-heading'}>
                                Deposit History
                            </div>
                            <Card className="t-w-card mt-4">
                                <Card.Body>
                                    <div className={isMobile ? 'atw-table-container table-responsive-md' : 'atw-table-container'}>
                                        <Table className='atw-table'>
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Deposit Amount</th>
                                                    <th> Deposited On</th>
                                                    <th> Transaction Hash</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    depositHistory.length > 0 ? (
                                                        depositHistory.map((deposit, index) => (
                                                            <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>{deposit.deposit_amount}</td>
                                                                <td>{formatDate(deposit?.deposited_on?.$date ?? '')}</td>
                                                                <td>{deposit.transaction_hash}</td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td colSpan={4}>No deposits found.</td>
                                                        </tr>
                                                    )
                                                }

                                            </tbody>
                                        </Table>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </section >
        </div >

    )
}

export default Profile;