import React, { useState, useEffect } from 'react';
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { Link, useNavigate } from 'react-router-dom';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useUser } from '../../components/UserContext';

import toast, { Toaster } from 'react-hot-toast';

import dashboard_icon from '../../assets/icons/dashboard.png';
import influencers_icon from '../../assets/icons/influencers-icon.png';
import summary_icon from '../../assets/icons/summary-icon.png';
import token_icon from '../../assets/icons/token-icon.png';
import watchlist_icon from '../../assets/icons/watchlist-icon.png';
import menu_icon from '../../assets/icons/menu-icon.png';
import * as Icon from 'react-bootstrap-icons';
import './sidebar.css';

interface SidebarCompProps {
    collapsed: boolean;
    toggleSidebar: () => void; // Assuming no arguments are passed to this function
    activeItem: string;
    setActiveItem: (item: string) => void;
}

const SidebarBotComp: React.FC<SidebarCompProps> = ({ collapsed, toggleSidebar, activeItem, setActiveItem }) => {
    const navigate = useNavigate();
    const { user } = useUser();
    const [showTooltip, setShowTooltip] = useState(false);


    const handleItemClick = (item: string, nav_link: string) => {
        console.log(user)
        if (user) {
            setActiveItem(item);
            navigate(nav_link)
        } else {
            toast.error('Please connect your wallat', {
                style: {
                    background: 'rgba(0, 29, 255, 0.20)',
                    color: '#fff'
                }
            });
        }
    };

    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            setShowTooltip(width >= 1281 && width <= 1500);
        };

        window.addEventListener('resize', handleResize);

        // Check on initial render
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const renderTooltip = (props: any, text: any) => (
        <Tooltip id="button-tooltip" {...props}>
            {text}
        </Tooltip>
    );


    return (
        <div className="sidebar-container">
            <Toaster />
            <div onClick={toggleSidebar} className={`collapse-menu ${collapsed ? 'menu-closed' : 'menu-opned'}`}>
                <img src={menu_icon} />
            </div>
            <Sidebar className="sidebar" collapsed={collapsed} width='300px'>

                <div className="sidebar-heading">
                    {collapsed ? 'MmT' : 'MMTBotAgent'}
                </div>
                {!collapsed &&
                    <div className='mobile-wallet'>
                        <div>
                            <w3m-button />
                        </div>

                        <div>
                            {user &&
                                <div className='credit-header'>Credit : {user?.credit.toFixed(2)} MATIC</div>
                            }
                        </div>
                    </div>
                }
                <Menu>
                    {user?.admin &&
                        <MenuItem
                            active={activeItem === 'dashboard'}
                            onClick={() => handleItemClick('dashboard', "/bot-agent/dashboard")}
                            icon={<img className="sidebar-icons" src={dashboard_icon} alt="Dashboard" />}
                        >
                            <span className="sidebar-label">Dashboard</span>
                        </MenuItem>
                    }
                    {user?.admin &&
                        <MenuItem
                            active={activeItem === 'bot-list'}
                            onClick={() => handleItemClick('bot-list', '/bot-agent/bot-list')}
                            icon={<img className="sidebar-icons" src={influencers_icon} alt="Bot List" />}
                        >
                            <span className="sidebar-label">Bot List</span>

                        </MenuItem>
                    }
                    {user?.admin &&
                        <MenuItem
                            active={activeItem === 'Influencers'}
                            onClick={() => handleItemClick('Influencers', '/bot-agent/influencers')}
                            icon={<img className="sidebar-icons" src={summary_icon} alt="Influencersy" />}
                        >
                            <span className="sidebar-label">Influencers</span>
                        </MenuItem>
                    }
                    {user?.admin &&
                        <MenuItem
                            active={activeItem === 'tasks'}
                            onClick={() => handleItemClick('tasks', '/bot-agent/tasks')}
                            icon={<img className="sidebar-icons" src={token_icon} alt="Tasks" />}
                        >
                            <span className="sidebar-label">Tasks</span>
                        </MenuItem>
                    }
                    {user?.admin &&
                        <MenuItem
                            active={activeItem === 'oauth'}
                            onClick={() => handleItemClick('oauth', '/bot-agent/oauth')}
                            icon={<img className="sidebar-icons" src={token_icon} alt="oauth" />}
                        >
                            <span className="sidebar-label">MMT - Oauth</span>
                        </MenuItem>
                    }
                      {user?.admin &&
                        <MenuItem
                            active={activeItem === 'mmt-tasks'}
                            onClick={() => handleItemClick('mmt-tasks', '/bot-agent/mmt-tasks')}
                            icon={<img className="sidebar-icons" src={token_icon} alt="Tasks" />}
                        >
                            <span className="sidebar-label">MMT - Tasks</span>
                        </MenuItem>
                    }
                </Menu>
            </Sidebar>
        </div>
    );
};

export default SidebarBotComp;
