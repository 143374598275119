// utils.ts
import { Influencer, InfluencerCategory,  Token, TokenCategory  } from './filterTypes';

export const transformInfluencersToCategories = (influencers: Influencer[]): InfluencerCategory[] => {
  const categories: Record<string, Influencer[]> = {};

  influencers.forEach((influencer) => {
    if (categories[influencer.category]) {
      categories[influencer.category].push(influencer);
    } else {
      categories[influencer.category] = [influencer];
    }
  });

  return Object.keys(categories).map((categoryName, index) => ({
    id: `category-${index}`,
    name: categoryName,
    subItems: categories[categoryName],
  }));
};



export const transformTokensToCategories = (tokens: Token[]): TokenCategory[] => {
  const categories: Record<string, Token[]> = {};
  tokens.forEach((token) => {
    if (categories[token.mmt_categories]) {
      categories[token.mmt_categories].push(token);
    } else {
      categories[token.mmt_categories] = [token];
    }
  });

  return Object.keys(categories).map((categoryName, index) => ({
    id: `category-${index}`,
    name: categoryName,
    subItems: categories[categoryName],
  }));
};



export const transformTokensToEcosystem = (tokens: Token[]): TokenCategory[] => {
  const categories: Record<string, Token[]> = {};
  tokens.forEach((token) => {
    if (categories[token.ecosystem]) {
      categories[token.ecosystem].push(token);
    } else {
      categories[token.ecosystem] = [token];
    }
  });

  return Object.keys(categories).map((categoryName, index) => ({
    id: `category-${index}`,
    name: categoryName,
    subItems: categories[categoryName],
  }));
};