// AppRoutes.tsx
import React, { FC, useState, useEffect } from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap';
import Header from './components/header';
import Footer from './components/footer';
import SidebarComp from './components/sidebar';
import Home from './pages/home';
import SubscriptionDashboard from './pages/account';
import TokenDetail from "./pages/token-detail";
import AllInfluencers from "./pages/all-influencers";
import InfluencerDetail from "./pages/influencer-detail";
import DailySummary from "./pages/daily-summary";
import LiveFeed from "./pages/live-feed";
import MyFeed from "./pages/my-feed";
import GenerateTweet from "./pages/generate-tweet";
import Profile from "./pages/profile";
import NewToken from "./pages/new-token";
import Watchlist from "./pages/watchlist"
import { DashboardKPI } from "./pages/dashboard-kpi";
import { UserProvider } from './components/UserContext';
import { useAccount } from 'wagmi';
import Notification from './components/Notification'

//Admin UI
import TickerList from "./pages/admin-ticker-list";
import TweetList from "./pages/admin-tweet-list"
import InfluencerList from "./pages/admin-influencer-list"

// Import new project components
import DashboardBot from './bot-agent/pages/dashboard';
import BotList from './bot-agent/pages/bot-list';
import Oauth from './bot-agent/pages/oauth';
import MMTTask from './bot-agent/pages/mmt-task';
import BotInfluencers from './bot-agent/pages/bot-influencers';
import BotTask from './bot-agent/pages/bot-task';
import HeaderBot from './bot-agent/components/header';
import SidebarBotComp from './bot-agent/components/sidebar';

// Define the props for ProtectedElement, explicitly typing 'children'
interface ProtectedElementProps {
    children: React.ReactNode;
}

const ProtectedElement: React.FC<ProtectedElementProps> = ({ children }) => {
    const { isConnected } = useAccount();
    return isConnected ? <>{children}</> : <Navigate to="/" replace />;
};


const AppRoutes: FC = () => {
    const { isConnected } = useAccount();
    const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
    const [activeItem, setActiveItem] = useState<string>('dashboard');
    const location = useLocation();

    const isBotAgent = location.pathname.includes("/bot-agent");

    useEffect(() => {
        if (!isConnected) {
            setActiveItem('dashboard')
        }
    }, [isConnected]);

    useEffect(() => {
        // Determine the active sidebar item based on the current path
        const path = location.pathname;
        // Set the activeItem based on path, you might need custom logic here
        if (path === '/') {
            setActiveItem('dashboard');
        } else if (path.startsWith('/all-influencers')) {
            setActiveItem('influencers');
        } else if (path.startsWith('/daily-summary')) {
            setActiveItem('summary');
        } else if (path.startsWith('/live-feed')) {
            setActiveItem('live-feed');
        } else if (path.startsWith('/profile')) {
            setActiveItem('profile');
        }
        // Add more conditions as needed for other sidebar items
    }, [location]);

    const toggleSidebar = () => {
        setSidebarCollapsed(!sidebarCollapsed);
    };



    return (
        <UserProvider>
            <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                {/* Conditionally render headers */}
                {isBotAgent ? <HeaderBot collapsed={sidebarCollapsed} /> : <Header collapsed={sidebarCollapsed} />}
                {/* <Header collapsed={sidebarCollapsed} /> */}
                <Notification />
                <div style={{ display: 'flex', flexGrow: 1, overflow: 'hidden' }}>
                    <Container fluid style={{ flexDirection: 'row', padding: 0, margin: 0, minHeight: 0 }}>
                        <Row style={{ flex: '1', margin: 0 }}>
                            <Col md={sidebarCollapsed ? 1 : 2} style={{ padding: 0 }}>
                                {/* Conditionally render sidebars */}
                                {isBotAgent ? (
                                    <SidebarBotComp collapsed={sidebarCollapsed} toggleSidebar={toggleSidebar} activeItem={activeItem} setActiveItem={setActiveItem} />
                                ) : (
                                    <SidebarComp collapsed={sidebarCollapsed} toggleSidebar={toggleSidebar} activeItem={activeItem} setActiveItem={setActiveItem} />
                                )}

                                {/* <SidebarComp collapsed={sidebarCollapsed} toggleSidebar={toggleSidebar} activeItem={activeItem} setActiveItem={setActiveItem} /> */}
                            </Col>
                            <Col md={sidebarCollapsed ? 11 : 10} style={{ padding: 0 }} className="page-container">
                                <Routes>
                                    <Route path="/" element={<Home />} />
                                    <Route path="/all-influencers" element={<ProtectedElement><AllInfluencers /></ProtectedElement>} />
                                    <Route path="/token-detail/:token" element={<ProtectedElement><TokenDetail /></ProtectedElement>} />
                                    <Route path="/daily-summary" element={<ProtectedElement><DailySummary /></ProtectedElement>} />
                                    <Route path="/live-feed" element={<ProtectedElement><LiveFeed /></ProtectedElement>} />
                                    <Route path="/new-token" element={<ProtectedElement><NewToken /></ProtectedElement>} />
                                    <Route path="/my-feed" element={<ProtectedElement><MyFeed /></ProtectedElement>} />
                                    <Route path="/account" element={<ProtectedElement><SubscriptionDashboard /></ProtectedElement>} />
                                    <Route path="/generate-tweet/:token" element={<ProtectedElement><GenerateTweet /></ProtectedElement>} />
                                    <Route path="/influencer-detail/:influencer" element={<ProtectedElement><InfluencerDetail /></ProtectedElement>} />
                                    <Route path="/profile" element={<ProtectedElement><Profile /></ProtectedElement>} />
                                    <Route path="/watch-list" element={<ProtectedElement><Watchlist /></ProtectedElement>} />
                                    <Route path="/ticker-list" element={<ProtectedElement><TickerList /></ProtectedElement>} />
                                    <Route path="/tweet-list" element={<ProtectedElement><TweetList /></ProtectedElement>} />
                                    <Route path="/influencer-list" element={<ProtectedElement><InfluencerList /></ProtectedElement>} />
                                    <Route path="/kpi" element={<ProtectedElement><DashboardKPI /></ProtectedElement>} />
                                    {/* Add more protected routes as needed */}


                                    {/* New Project Routes */}                                  
                                    <Route path="/bot-agent/dashboard" element={<ProtectedElement><DashboardBot /> </ProtectedElement> } />
                                    <Route path="/bot-agent/bot-list" element={<ProtectedElement><BotList /> </ProtectedElement> } />
                                    <Route path="/bot-agent/influencers" element={<ProtectedElement><BotInfluencers /> </ProtectedElement> } />
                                    <Route path="/bot-agent/tasks" element={<ProtectedElement><BotTask /> </ProtectedElement> } />
                                    <Route path="/bot-agent/oauth" element={<ProtectedElement><Oauth /> </ProtectedElement> } />
                                    <Route path="/bot-agent/mmt-tasks" element={<ProtectedElement><MMTTask /> </ProtectedElement> } />
                                    {/* Add more new project routes */}
                                </Routes>
                            </Col>
                        </Row>
                    </Container>
                </div>
                {/* <Footer /> */}
            </div>
        </UserProvider>
    );
};

export { AppRoutes };
