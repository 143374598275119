import React, { useState, useRef, useEffect } from 'react';
import { Modal, Spinner, Button } from 'react-bootstrap';
import { useAccount } from 'wagmi'
import Select, { ActionMeta, MultiValue } from 'react-select';
import * as Icon from 'react-bootstrap-icons';
import { toast } from 'react-hot-toast';

interface SelectCategoryOption {
    value: string;
    label: string;
}

interface SelectTaskTypeOption {
    value: string;
    label: string;
}


interface SelectBotOption {
    value: string;
    label: string;
}

interface SelectUserGroupOption {
    value: string;
    label: string;
}

interface SelectInfluencerOption {
    value: string;
    label: string;
}

interface FormData {
    task_type: string;
    task_type_id: string;
    category_id: string;
    influencer_groups: string[];
    bot_ids: string[];
    campaign_name: string;
}



const TaskCreateModal: React.FC = () => {
    const { address, isConnected } = useAccount()
    const [showModal, setShowModal] = useState(true);
    const [loading, setLoading] = useState(false);
    const [optionsCategory, setOptionsCategory] = useState<SelectCategoryOption[]>([]);
    const [optionsTaskType, setOptionsTaskType] = useState<SelectTaskTypeOption[]>([]);
    const [optionsBot, setOptionsBot] = useState<SelectBotOption[]>([]);
    const [optionsUserGroup, setOptionsUserGroup] = useState<SelectUserGroupOption[]>([]);
    const [optionsInfluencers, setOptionsInfluencers] = useState<SelectInfluencerOption[]>([]);
    const [formData, setFormData] = useState<FormData>({
        task_type: '',
        task_type_id: '',
        campaign_name: '',
        category_id: '',
        influencer_groups: [],
        bot_ids: [],
    });

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSelectChange = (field: string, selectedOption: any) => {
        setFormData((prevData) => ({
            ...prevData,
            [field]: Array.isArray(selectedOption)
                ? selectedOption.map((option) => option.value)
                : selectedOption?.value,
        }));
    };

    const handleCategoryChange = (selectedOptions: any) => {
        // Map over the selected options to get the array of values
        const selectedValues = selectedOptions ? selectedOptions.map((option: any) => option.value) : [];

        setFormData((prevState) => ({
            ...prevState,
            category_id: selectedValues, // Update formData with the array of selected values
        }));
    };

    const handleTaskIdChange = (selectedOption: any) => {
        setFormData((prevState) => ({
            ...prevState,
            task_type_id: selectedOption ? selectedOption.value : '',
            task_type: selectedOption ? selectedOption.label : '',
        }));
    };

    const handleUserGroupsChange = (selectedOptions: MultiValue<{ value: string, label: string }>) => {
        const catIds = selectedOptions ? selectedOptions.map(option => option.value) : [];
        setFormData(prevState => ({
            ...prevState,
            influencer_groups: catIds, // Update form data with selected group IDs
        }));
    };

    const handleBotChange = (selectedOptions: any) => {
        // Map over the selected options to get the array of values
        const selectedValues = selectedOptions ? selectedOptions.map((option: any) => option.value) : [];

        setFormData((prevState) => ({
            ...prevState,
            bot_ids: selectedValues, // Update formData with the array of selected values
        }));
    };

    const handleSubmit = () => {
        setLoading(true);
        fetch(`${process.env.REACT_APP_API_URL}/shill/tasks?wallet_id=${address}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then((data) => {
                console.log('Success:', data);
                toast.success('Task created successfully!');

            })
            .catch((error) => {
                console.error('Error:', error);
                toast.error('Failed to create task.');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const get_category = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "shill/categories?wallet_id=" + address, {
                method: "GET",
                mode: "cors",
                headers: new Headers({ "content-type": "application/json" })
            });
            if (response.status === 200) {
                const data = await response.json();
                // setUserGroups(data['data'])
                const formattedOptions = data['data'].map((category: any) => {

                    return {
                        value: category.id,
                        label: category.name,
                    };
                });
                // Prepend the "empty field" option
                setOptionsCategory([{ value: '', label: 'Select a category' }, ...formattedOptions]);
            } else {
                console.log(response.status);
            }
        } catch (e: any) {
            console.log(e);
        }
    }

    const get_bots = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "oauth/list?wallet_id=" + address, {
                method: "GET",
                mode: "cors",
                headers: new Headers({ "content-type": "application/json" })
            });
            if (response.status === 200) {
                const data = await response.json();
                // setUserGroups(data['data'])
                const formattedOptions = data['data'].map((bot: any) => {

                    return {
                        value: bot.id,
                        label: bot.name,
                    };
                });
                // Prepend the "empty field" option
                setOptionsBot([{ value: '', label: 'Select a bot' }, ...formattedOptions]);
            } else {
                console.log(response.status);
            }
        } catch (e: any) {
            console.log(e);
        }
    }

    const get_task_type = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "/shill/task-types?wallet_id=" + address, {
                method: "GET",
                mode: "cors",
                headers: new Headers({ "content-type": "application/json" })
            });
            if (response.status === 200) {
                const data = await response.json();
                // setUserGroups(data['data'])
                const formattedOptions = data['data'].map((task_type: any) => {

                    return {
                        value: task_type.id,
                        label: task_type.task_name,
                    };
                });
                // Prepend the "empty field" option
                setOptionsTaskType([{ value: '', label: 'Select a task type' }, ...formattedOptions]);
            } else {
                console.log(response.status);
            }
        } catch (e: any) {
            console.log(e);
        }
    }

    const get_user_groups = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "/shill/user-groups?wallet_id=" + address, {
                method: "GET",
                mode: "cors",
                headers: new Headers({ "content-type": "application/json" })
            });
            if (response.status === 200) {
                const data = await response.json();
                // setUserGroups(data['data'])
                const formattedOptions = data['data'].map((user_group: any) => {

                    return {
                        value: user_group.id,
                        label: user_group.name,
                    };
                });
                // Prepend the "empty field" option
                setOptionsUserGroup([{ value: '', label: 'Select a user group' }, ...formattedOptions]);
            } else {
                console.log(response.status);
            }
        } catch (e: any) {
            console.log(e);
        }
    }

    const get_all_token_filter = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "home/filters?time_window=1month", {
                method: "GET",
                mode: "cors",
                headers: new Headers({ "content-type": "application/json" })
            });
            if (response.status === 200) {
                const data = await response.json();
                // setUserGroups(data['data'])
                const formattedOptions = data['filters']['influencer'].map((influencer: any) => {
                    return {
                        value: influencer.user_id,
                        label: influencer.user_username,
                    };
                });
                setOptionsInfluencers(formattedOptions)
            } else {
                console.log(response.status);
            }
        } catch (e: any) {
            console.log(e);
        }
    }

    useEffect(() => {
        // get_all_tasks()
        get_task_type()
        get_category()
        get_user_groups()
        get_bots()
        get_all_token_filter()
    }, []);

    return (
        <Modal className='tweet-modal' show={showModal} onHide={() => setShowModal(false)} centered>
            <Modal.Header className="justify-content-between">

                <div className='update-modal-heading'>
                    Create New Task

                </div>
                <Icon.XCircle size={30} onClick={() => setShowModal(false)} />
            </Modal.Header>
            <Modal.Body>
                <div className="form-group">
                    <label className="token-description-label">Task Type:</label>
                    <Select
                        id="task_type_id"
                        name="task_type_id"
                        options={optionsTaskType}
                        value={optionsTaskType.find(option => option.value === formData.task_type_id) || null} // Find the selected value
                        onChange={handleTaskIdChange}
                        className="ticker-text-box"
                        classNamePrefix="admin-select"
                    />

                </div>

                <div className="form-group mt-3">
                    <label className="token-description-label">Campaign Name</label>
                    <input
                        type="text"
                        className="form-control mt-2 ticker-text-box"
                        name="campaign_name"
                        value={formData.campaign_name}
                        onChange={handleInputChange}
                    />
                </div>
                <div className='d-flex align-items-center mt-2'>
                    <label className='token-description-label mt-2' htmlFor="password">Category :</label>

                </div>
                <Select
                    id="category_id"
                    name="category_id"
                    options={optionsCategory}
                    value={
                        Array.isArray(formData.category_id)
                            ? optionsCategory.filter(option => formData.category_id.includes(option.value))
                            : null
                    }
                    onChange={handleCategoryChange}
                    isMulti
                    className="ticker-text-box"
                    classNamePrefix="admin-select"
                />
                <div className="form-group mt-3">
                    <label className="token-description-label mt-3">Influencer: </label>
                    <Select
                        id="influencer_groups"
                        name="influencer_groups"
                        options={optionsInfluencers}
                        isMulti
                        value={optionsInfluencers.filter(option => formData.influencer_groups.includes(option.value)) || null}
                        onChange={handleUserGroupsChange}
                        onFocus={() => {
                            // Clear the selected values on focus
                            handleUserGroupsChange([]);
                        }}
                        className="ticker-text-box"
                        classNamePrefix="admin-select"
                    />

                    {/* <Select
                        id="user_tag"
                        name="user_tag"
                        options={optionsUserGroup}
                        isMulti
                        value={optionsUserGroup.filter(option => formData.influencer_groups.includes(option.value)) || null}
                        onChange={handleUserGroupsChange}
                        onFocus={() => {
                            // Clear the selected values on focus
                            handleUserGroupsChange([]);
                        }}
                        className="ticker-text-box"
                        classNamePrefix="admin-select"
                    /> */}
                </div>
                <div className="form-group mt-3">
                    <label className="token-description-label mt-3">Bots:</label>
                    <Select
                        id="bot_ids"
                        name="bot_ids"
                        options={optionsBot}
                        value={
                            Array.isArray(formData.bot_ids)
                                ? optionsBot.filter(option => formData.bot_ids.includes(option.value))
                                : null
                        }
                        onChange={handleBotChange}
                        isMulti
                        className="ticker-text-box"
                        classNamePrefix="admin-select"
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowModal(false)}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSubmit} disabled={loading}>
                    {loading ? <Spinner animation="border" size="sm" /> : 'Create Task'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default TaskCreateModal;
