import { useState, useRef, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Row, Col, Card, Tab, Tabs, Spinner, Table, Modal, Button } from "react-bootstrap";
import * as Icon from 'react-bootstrap-icons';
import { useUser } from '../components/UserContext';
import { Tweet } from 'react-tweet';
import { useAccount } from 'wagmi'
import bit_coin_symbol_img from "../assets/images/bit-coin-symbol.png"
import copy_icon_img from "../assets/images/copy-symbol.png"
import share_icon_img from "../assets/images/quill_share.png"
import default_crypto_img from "../assets/images/default-crypto-img.png"
import dexscreen_img from "../assets/icons/icon-1.png"
import coingecko_img from "../assets/icons/CoinGecko Logo 1.png"
import TypingEffect from '../components/TypingEffect';
import toast, { Toaster } from 'react-hot-toast';
import Select, { MultiValue } from 'react-select';
import { format, parseISO } from 'date-fns';
import no_img from "../assets/images/no-image-icon.png"

import './generate-tweet.css'
interface TokenOption {
    token: string;
    symbol: string;
    id:string;
    // ... other properties, if they exist
}

interface TweetHistory {
    tweet: string;
    user: string;
    time_window: string;
    ticker: string;
    tone: string;
    tweet_type: string;
    created_at: string;
    id: string;
    amount_deducted: number;
}

interface Tweets {
    tweet_id: string;
}

interface TweetSentimentsData {
    category: string;
    count: number;
}

interface CreateTweetPageProps {
    tweetType: string;
    onGeneratedTweet: (generatedTweet: string) => void; // Callback function prop
    onTweetDetailsFetched: (tweetDetails: any) => void; // New callback prop
}

const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return isMobile;
};

interface Token {
    label: string;
    value: string;
}

interface TokenDetails {
    token_name: string;
    image_url?: string;
    cryptocurrency?: string;
    token_symbol?: string;
    coin_id: string;
    categories?: string[];
    total_tweets: number;
    description: string;
    id: string;
    token_address: string;
    website: string;
    telegram_channel_identifier: string;
    twitter_screen_name: string;
    dexscreen_url: string;
    coingecko_url: string;
    chain_id: string;
    verified: boolean;
    fetch_status: boolean;
    assert_platform_id: string;
}

const CreateTweetPage: React.FC<CreateTweetPageProps> = ({ tweetType, onGeneratedTweet, onTweetDetailsFetched }) => {
    const isMobile = useIsMobile();
    const { token } = useParams();
    const { user } = useUser();
    const [generateTweetsTimeWindow, setGenerateTweetsTimeWindow] = useState(process.env.REACT_APP_DEFAULT_TIME_WINDOW ? process.env.REACT_APP_DEFAULT_TIME_WINDOW : '4hr')
    const [generatedTweet, setGeneratedTweet] = useState('')
    const { address, isConnected } = useAccount()
    const [tokenFilter, setTokenFilter] = useState<TokenOption[]>([]);
    const [sentimentTweets, setSentimentTweets] = useState<Tweets[]>([]);
    const [selectedTweetIds, setSelectedTweetIds] = useState<string[]>([]);
    const [selectedSentiment, setSelectedSentiment] = useState<string[]>(['Bullish']);  // Or use [] for no initial selection
    const [tweetSentiments, setTweetSentiments] = useState<TweetSentimentsData[]>([]);
    const [loader, setLoader] = useState(false);
    const [tokenDetails, setTokenDetails] = useState<TokenDetails>();
    const [tweetSummaryloader, settweetSummaryLoader] = useState(false);
    const [tweetSelectionModalOpen, setTweetSelectionModalOpen] = useState(false);

    const [showError, setShowError] = useState(false);
    const [apiError, setApiError] = useState(false);
    const [errorDetails, setErrorDetails] = useState('')

    // Filter By Token
    const [selectedOption, setSelectedOption] = useState<any | null>(null);
    const [hoveredOption, setHoveredOption] = useState<any | null>(null);
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    // Assuming `tokenFilter` is your array of options
    const filteredOptions = tokenFilter.filter(option =>
        option.token.toLowerCase().includes(searchTerm.toLowerCase()) ||
        option.symbol.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Use a Set to ensure uniqueness
    const uniqueFilteredOptions = Array.from(new Set(filteredOptions.map(option => option.token)))
        .map(token => filteredOptions.find(option => option.token === token));

    const clearSearch = () => {
        setSearchTerm('');
    };

    const handleTokenSelect = (option: any, id: any) => {
        console.log(option)
        setSelectedOption(option);
        setIsOpen(false);
        const { startDate, endDate } = calculateDateRange(generateTweetsTimeWindow);
        setSelectedOption(id)
        get_token_details(id)
        // setGenerateTweetsTimeWindow(selectedTimeframe);
        get_tweet_summary(id, startDate, endDate);
        // get_all_token_tweet(activeAllTweetsTimeWindow, currentPage, selectedOption, selectedKeywordsOption);
        // onSelect(option);
    };

    const useOutsideClick = (ref: any, callback: any) => {
        useEffect(() => {
            const handleClickOutside = (event: any) => {
                if (ref.current && !ref.current.contains(event.target)) {
                    callback();
                }
            };
            document.addEventListener('mousedown', handleClickOutside);

            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [ref, callback]);
    };

    const tokenDropdownRef = useRef(null);



    useOutsideClick(tokenDropdownRef, () => setIsOpen(false));




    const generate_tweet = async (time_window: any, tone: any, ticker: any, tweetType: any) => {
        setSelectedSentiment([]);
        setSentimentTweets([])
        // Check if no tweet IDs are selected
        if (selectedTweetIds.length === 0) {
            setErrorDetails('Please select at least one tweet.')
            setShowError(true)
            return; // Stop the function execution if no tweets are selected
        }
        let tweetCost;

        // Determine tweet cost based on tweetType
        if (tweetType === 'Single Tweet') {
            tweetCost = process.env.REACT_APP_SINGLE_TWEET_COST;
        } else if (tweetType === 'Long Tweet') {
            tweetCost = process.env.REACT_APP_LONG_TWEET_COST;
        } else {
            tweetCost = process.env.REACT_APP_THREADED_TWEET_COST;
        }
        console.log(tweetType, tweetCost);

        // Check user credit before proceeding
        if (user && (Number(user?.credit) <= Number(tweetCost))) {
            toast.error('Insufficient credit to generate a tweet', {
                style: {
                    background: 'rgba(0, 29, 255, 0.20)',
                    color: '#fff'
                }
            });
            return; // Early return to avoid making the API call
        }

        // Prepare the requestBody
        const requestBody = {
            tweets: selectedTweetIds.join(',') // Convert array of tweet IDs to a comma-separated string
        };

        try {
            setLoader(true);
            setGeneratedTweet('');
            const timeWindowParam = time_window ? `&time_window=${time_window}` : '';
            const toneParam = tone ? `&tone=${tone}` : '';
            const tickerParam = ticker ? `&ticker=${ticker}` : '';
            const tweetTypeParam = tweetType ? `&tweet_type=${tweetType}` : '';
            const wallatIdParam = `&wallet_id=${user?.wallet_id}`;

            const response = await fetch(`${process.env.REACT_APP_API_URL}generate/tweet?${timeWindowParam}${toneParam}${tickerParam}${tweetTypeParam}${wallatIdParam}`, {
                method: "POST",
                mode: "cors",
                headers: new Headers({ "Content-Type": "application/json" }),
                body: JSON.stringify(requestBody) // Sending requestBody as JSON string
            });
            setTweetSelectionModalOpen(false)
            // Handle response
            if (response.status === 200) {
                const data = await response.json();
                console.log(data);
                setGeneratedTweet(data['generated_tweet']);
                onGeneratedTweet(data['generated_tweet']);

            } else if (response.status === 500) {
                const data = await response.json();
                if (data.detail === "TimeoutException") {
                    setGeneratedTweet('TimeoutException: Please try again later.');
                    onGeneratedTweet('TimeoutException: Please try again later.');
                    console.log('TimeoutException: Please try again later.');
                    setApiError(true)
                } else {
                    setGeneratedTweet('Internal Server Error. Please try again later.');
                    onGeneratedTweet('Internal Server Error. Please try again later.');
                    console.log('Internal Server Error. Please try again later.');
                    setApiError(true)
                }

            } else {
                console.log(`Error: ${response.status}`);
                setApiError(true)
            }
        } catch (e) {
            setTweetSelectionModalOpen(false)
            setApiError(true)
            console.error(e);
        } finally {
            setTweetSelectionModalOpen(false)
            setLoader(false);
        }
    }



    const get_all_token_filter = async (timeWindow: any) => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "home/filters?time_window=" + timeWindow, {
                method: "GET",
                mode: "cors",
                headers: new Headers({ "content-type": "application/json" })
            });
            if (response.status === 200) {
                const data = await response.json();
                setTokenFilter(data['filters']['tokens']);
            } else {
                console.log(response.status);
            }
        } catch (e: any) {
            console.log(e);
        }
    }



    const handleTimeframeChange = (selectedTimeframe: string) => {
        const { startDate, endDate } = calculateDateRange(selectedTimeframe);
        console.log("handle timeframe")
        // setGenerateTweetsTimeWindow(selectedTimeframe);
        get_tweet_summary(token, startDate, endDate);
    };

    const calculateDateRange = (timeframe: string) => {
        const today = new Date();
        let startDate;
        let endDate = today.toISOString().split('T')[0]; // Get today's date

        switch (timeframe) {
            // case '15min':
            //     startDate = new Date(today.getTime() - 15 * 60 * 1000); // 15 minutes ago
            //     break;
            // case '1hr':
            //     startDate = new Date(today.getTime() - 60 * 60 * 1000); // 1 hour ago
            //     break;
            // case '4hr':
            //     startDate = new Date(today.getTime() - 4 * 60 * 60 * 1000); // 4 hours ago
            //     break;
            // case '12hr':
            //     startDate = new Date(today.getTime() - 12 * 60 * 60 * 1000); // 12 hours ago
            //     break;
            // case '1day':
            //     startDate = new Date(today.getTime() - 24 * 60 * 60 * 1000); // 24 hours ago
            //     break;
            case '1week':
                startDate = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000); // 7 days ago
                break;
            case '1month':
                startDate = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate()); // 1 month ago
                break;
            default:
                startDate = new Date(today.getTime() - 24 * 60 * 60 * 1000); // Default to yesterday 
        }
        console.log(startDate)
        return { startDate: startDate.toISOString().split('T')[0], endDate };
    };


    const get_tweet_summary = async (token: any, start_date: any, end_date: any) => {
        settweetSummaryLoader(true)
        const tokenParam = token ? `&token=${token}` : '';
        const startDateParam = start_date ? `&start_date=${start_date}` : '';
        const endDateParam = end_date ? `&end_date=${end_date}` : '';
        const walletIdParam = isConnected ? `&wallet_id=${address}` : '';
        const url = `${process.env.REACT_APP_API_URL}generate/summary?${tokenParam}${startDateParam}${endDateParam}${walletIdParam}`;

        try {
            const response = await fetch(url, {
                method: "GET",
                mode: "cors",
                headers: new Headers({ "content-type": "application/json" })
            });
            if (response.status === 200) {
                const data = await response.json();
                setTweetSentiments(data['data'])
                onTweetDetailsFetched(data['token']);
                settweetSummaryLoader(false)
                setSelectedSentiment([]);
                setSentimentTweets([])
            } else {
                console.log(response.status);
                settweetSummaryLoader(false)
            }
        } catch (e) {
            console.log(e);
            settweetSummaryLoader(false)
        }
    };


    const get_token_details = async (token: any) => {
        const tokenParam = token ? `&token=${token}` : '';
        const url = `${process.env.REACT_APP_API_URL}detail/info?${tokenParam}`;

        try {
            const response = await fetch(url, {
                method: "GET",
                mode: "cors",
                headers: new Headers({ "content-type": "application/json" })
            });
            if (response.status === 200) {
                const data = await response.json();
                setTokenDetails(data['data'])
            } else {
                console.log(response.status);
            }
        } catch (e) {
            console.log(e);
        }
    };


    const handleTweetSentimentClick = () => {
        // Toggle the modal's visibility
        setTweetSelectionModalOpen(!tweetSelectionModalOpen);

    };

    // const handleSentimentChange = (event: any, sentiment: any) => {
    //     setSelectedSentiment(sentiment.category);
    //     setShowError(false)
    //     setSentimentTweets(sentiment['tweets'])
    //     handleTweetSentimentClick()
    //     setSelectedSentiment(event.target.value);
    // };

    const handleSentimentChange = (event: React.ChangeEvent<HTMLInputElement>, sentiment: any) => {
        if (event.target.checked) {
            // Add sentiment if checked
            setSelectedSentiment(prev => [...prev, sentiment.category]);
            setSentimentTweets(prev => [...prev, ...sentiment['tweets']]);
        } else {
            // Remove sentiment if unchecked
            setSelectedSentiment(prev => prev.filter(s => s !== sentiment.category));
            setSentimentTweets(prev => prev.filter(t => !sentiment['tweets'].includes(t)));
        }
        setShowError(false);
        // handleTweetSentimentClick();  // Assuming you still need to call this
    };



    const handleTweetSelectionChange = (e: any, tweetId: any) => {
        if (e.target.checked) {
            // Add the tweet ID to the selectedTweetIds array if it's not already present
            setSelectedTweetIds(prev => [...prev, tweetId]);
        } else {
            // Remove the tweet ID from the selectedTweetIds array if it was unchecked
            setSelectedTweetIds(prev => prev.filter(id => id !== tweetId));
        }
    };



    useEffect(() => {
        setSelectedOption(token)
        get_token_details(token)
    }, [token]);

    useEffect(() => {
        get_all_token_filter(generateTweetsTimeWindow);
        handleTimeframeChange(generateTweetsTimeWindow)
    }, [generateTweetsTimeWindow]);

    return (
        <>
            <Toaster />
            <div className={isMobile ? '' : 'd-flex'} >
                <div className="d-flex align-items-center me-auto cursor-pointer">
                    <div>
                        <img src={tokenDetails?.image_url ? tokenDetails?.image_url : no_img} className="ticker-img" />
                    </div>
                    <div className="ms-2">
                        <div className="ticker-name">{tokenDetails?.token_name}</div>
                        <div className="ticker-symbol">{tokenDetails?.token_symbol}</div>
                    </div>

                </div>
                <div className=''>

                    <div className="custom-dropdown-g-tw mt-3" ref={tokenDropdownRef}>
                        <div className="selected-option" onClick={() => setIsOpen(!isOpen)}>
                            <span>{tokenDetails?.token_name ? tokenDetails?.token_name + ' - ' + tokenDetails?.token_symbol : 'Change Token'}</span>
                            <Icon.ChevronDown size={20} className='float-end' />
                        </div>

                        {isOpen && (
                            <div className="options">

                                <div className="search-container">
                                    <input
                                        type="text"
                                        className="dropdown-search-input"
                                        value={searchTerm}
                                        onChange={e => setSearchTerm(e.target.value)}
                                        placeholder="Search..."
                                    />
                                    {searchTerm && (
                                        <button onClick={clearSearch} className="clear-button">X</button>
                                    )}
                                </div>

                                {uniqueFilteredOptions.map((option, index) => (
                                    option && (
                                        <div
                                            key={`${option.token}-${index}`}
                                            className={`option ${hoveredOption === option['token'] ? 'hovered' : ''}`}
                                            onClick={() => handleTokenSelect(option['token'], option['id'])}
                                            onMouseEnter={() => setHoveredOption(option['token'])}
                                            onMouseLeave={() => setHoveredOption(null)}
                                        >
                                            {option['token']} - {option['symbol']}
                                        </div>
                                    )
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>


            <Row>
                <Col sm={12} className=''>
                    <div className="timeframe-label mt-4">
                        Timeframe
                    </div>
                    <div className='time-window-container d-flex mt-4'>
                        <div onClick={() => { setGenerateTweetsTimeWindow('15min'); }} className={`time-window-g-tw ${generateTweetsTimeWindow == '15min' ? 'active' : ''}`}>
                            15 min
                        </div>
                        <div onClick={() => { setGenerateTweetsTimeWindow('1hr'); }} className={`time-window-g-tw ${generateTweetsTimeWindow == '1hr' ? 'active' : ''}`}>
                            1h
                        </div>
                        <div onClick={() => { setGenerateTweetsTimeWindow('4hr'); }} className={`time-window-g-tw ${generateTweetsTimeWindow == '4hr' ? 'active' : ''}`}>
                            4h
                        </div>
                        <div onClick={() => { setGenerateTweetsTimeWindow('12hr'); }} className={`time-window-g-tw ${generateTweetsTimeWindow == '12hr' ? 'active' : ''}`}>
                            12h
                        </div>
                        <div onClick={() => { setGenerateTweetsTimeWindow('1day'); }} className={`time-window-g-tw ${generateTweetsTimeWindow == '1day' ? 'active' : ''}`}>
                            1d
                        </div>
                        <div onClick={() => { setGenerateTweetsTimeWindow('1week'); }} className={`time-window-g-tw ${generateTweetsTimeWindow == '1week' ? 'active' : ''}`}>
                            7d
                        </div>
                        <div onClick={() => { setGenerateTweetsTimeWindow('1month'); }} className={`time-window-g-tw ${generateTweetsTimeWindow == '1month' ? 'active' : ''}`}>
                            30d
                        </div>
                    </div>
                </Col>

            </Row>
            <Row>
                <Col sm={12} className=''>
                    <div className="timeframe-label mt-4">
                        Tweet Sentiments
                    </div>
                </Col>
                <Col sm={12} className=''>
                    {tweetSummaryloader &&
                        <div className="gen-tw-loader">
                            <Spinner animation="border" role="status" variant="light" >
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </div>
                    }
                    {!tweetSummaryloader &&
                        <div className="tw-sentiments-form-container">
                            <form className='tw-sentiments-form'>
                                {tweetSentiments.map((sentiment, index) => (
                                    <label key={index}>
                                        <input
                                            type="checkbox"
                                            name="sentiment"
                                            value={sentiment.category}
                                            checked={selectedSentiment.includes(sentiment.category)}
                                            onChange={(e) => handleSentimentChange(e, sentiment)}
                                        />
                                        <span className='sentiment-category'>{sentiment.category}</span>
                                        <span className='sentiment-count'>{sentiment.count} Tweets</span>
                                    </label>
                                ))}
                            </form>
                        </div>
                    }
                    {sentimentTweets.length > 0 &&
                        <div className='ctw-btn' onClick={handleTweetSentimentClick}>
                            Choose Tweets
                        </div>
                    }
                    <Modal dialogClassName="gen-tweet-modal" className='tweet-modal' show={tweetSelectionModalOpen} onHide={() => setTweetSelectionModalOpen(false)} backdrop="static" keyboard={false} centered>
                        <Modal.Header>
                            <div className='generate-tweet-select-header-text'>
                                Select the tweets
                                {selectedTweetIds.length > 0 &&
                                    <span className='selcted-tweet-count'>
                                        ({selectedTweetIds.length + ' tweet selected'})
                                    </span>
                                }
                            </div>
                            {showError && <div className='gen-tweet-error-detail'>{errorDetails}</div>}
                        </Modal.Header>
                        <Modal.Body>
                            <div data-theme="dark" className='generate-tweet-select-container'>
                                {sentimentTweets.map((tweets) => (
                                    <div key={tweets.tweet_id} className="tweet-selection-item">
                                        <div className="tweet-selection-checkbox">
                                            <input
                                                type="checkbox"
                                                id={`tweet-select-${tweets.tweet_id}`}
                                                hidden
                                                checked={selectedTweetIds.includes(tweets.tweet_id)}
                                                onChange={(e) => handleTweetSelectionChange(e, tweets.tweet_id)}
                                            />
                                            <label htmlFor={`tweet-select-${tweets.tweet_id}`}></label>
                                        </div>
                                        <Tweet id={tweets.tweet_id} />
                                    </div>
                                ))}
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            {!loader &&
                                <>

                                    <div className="gen-tw-btn" onClick={() => generate_tweet(generateTweetsTimeWindow, selectedSentiment, tokenDetails?.id, tweetType)}>
                                        Generate Tweet

                                    </div>
                                    <Button className='gen-tw-close-button' variant="secondary" onClick={() => { setTweetSelectionModalOpen(false); setSelectedSentiment([]); setSentimentTweets([]) }}>
                                        Close
                                    </Button>
                                </>
                            }
                            {loader &&
                                <div className="gen-tw-loader">
                                    <Spinner animation="border" role="status" variant="light" >
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                </div>
                            }

                        </Modal.Footer>
                    </Modal>

                </Col>
                {/* <Col sm={12} className=''>
                    <div className='d-flex align-items-center justify-content-end' >
                        <div>
                            <img alt="dexscreen-icon" width={35} src={dexscreen_img} className="me-2 t-d-crypto-web-img" /> 
                             https://dexscreener.com/bsc/0xd6af37e75d62684dea3490ec5684feb1e50f006a 
                        </div>
                        <div>
                            <img alt="coingecko-icon" width={30} src={coingecko_img} className="t-d-crypto-web-img" />
                            https://dexscreener.com/bsc/0xd6af37e75d62684dea3490ec5684feb1e50f006a 
                        </div>
                    </div>
                </Col> */}
            </Row>
            {/* <Row>
                <Col sm={12} className=''>
                    {!loader &&
                        <div className="gen-tw-btn mt-4" onClick={() => generate_tweet(generateTweetsTimeWindow, selectedSentiment, selectedOption, tweetType)}>
                            Generate Tweet

                        </div>
                    }
                    {loader &&
                        <div className="gen-tw-btn mt-4">
                            <Spinner animation="border" role="status" variant="light" >
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </div>
                    }
                </Col>
            </Row> */}
        </>
    )
};


interface TweetContentProps {
    content: string; // Explicitly type the 'content' prop as a string
}

const TweetContent: React.FC<TweetContentProps> = ({ content }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () => setIsExpanded(!isExpanded);

    // Assuming a line is roughly 50 characters, adjust as needed
    const shouldShowReadMore = content.length > 150; // Adjust based on your needs
    const displayContent = shouldShowReadMore && !isExpanded ? `${content.substring(0, 150)}...` : content;

    return (
        <div>
            {displayContent}
            {shouldShowReadMore && (
                <button onClick={toggleExpand} className="read-more-less-btn">
                    {isExpanded ? 'Read Less' : 'Read More'}
                </button>
            )}
        </div>
    );
};


export const GenerateTweet = () => {
    const isMobile = useIsMobile();
    const { user } = useUser();
    const navigate = useNavigate();
    const { token } = useParams();
    const [tweetHistory, setTweetHistory] = useState<TweetHistory[]>([]);
    const [key, setKey] = useState('single-tweet');
    const [parentGeneratedTweet, setParentGeneratedTweet] = useState<string>('');
    const [tweetDetails, setTweetDetails] = useState<any>(null);
    const [metrics, setMetrics] = useState<string[]>([]);
    const [addedMetrics, setAddedMetrics] = useState<string[]>([]);
    // Callback function to receive generatedTweet from child
    const handleGeneratedTweet = (generatedTweet: string) => {
        console.log(generatedTweet)
        setParentGeneratedTweet(generatedTweet);
        get_tweet_history();
    };

    const handleCopyClick = () => {
        const textToCopy = `${parentGeneratedTweet} ${metrics.join(' ')}`;
        navigator.clipboard.writeText(textToCopy)
            .then(() => {
                console.log('Text successfully copied to clipboard');
                // You can add a notification or any other feedback to the user here
            })
            .catch((err) => {
                console.error('Unable to copy text to clipboard', err);
            });
    };

    const handleShareClick = () => {
        const tweetText = encodeURIComponent(`${parentGeneratedTweet} ${metrics.join(' ')}`);
        const tweetUrl = `https://twitter.com/intent/tweet?text=${tweetText}`;

        window.open(tweetUrl, '_blank');
    };

    const get_tweet_history = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "history/generated-tweets?wallet_id=" + user?.wallet_id, {
                method: "GET",
                mode: "cors",
                headers: new Headers({ "content-type": "application/json" })
            });
            if (response.status === 200) {
                const data = await response.json();
                setTweetHistory(data['data']);
            } else {
                console.log(response.status);
            }
        } catch (e: any) {
            console.log(e);
        }
    }

    const addDexscreenUrl = () => {
        if (tweetDetails && tweetDetails['dexscreen_url']) {
            setParentGeneratedTweet(prev => `${prev} ${tweetDetails['dexscreen_url']}`);
        }
    };

    const addCoingeckoUrl = () => {
        if (tweetDetails && tweetDetails['coingecko_url']) {
            setParentGeneratedTweet(prev => `${prev} ${tweetDetails['coingecko_url']}`);
        }
    };

    const addTokenSnifferUrl = () => {
        if (tweetDetails && tweetDetails['token_sniffer_url']) {
            setParentGeneratedTweet(prev => `${prev} ${tweetDetails['token_sniffer_url']}`);
        }
    };

    const addTokenAddress = () => {
        if (tweetDetails && tweetDetails['token_address']) {
            setParentGeneratedTweet(prev => `${prev} ${tweetDetails['token_address']}`);
        }
    };

    const addTokenWebpage = () => {
        if (tweetDetails && tweetDetails['links'] && tweetDetails['links']['homepage']) {
            setParentGeneratedTweet(prev => `${prev} ${tweetDetails['links']['homepage']}`);
        }
    };

    const addTokenTwitter = () => {
        if (tweetDetails && tweetDetails['links'] && tweetDetails['links']['twitter_screen_name']) {
            setParentGeneratedTweet(prev => `${prev} ${'https://twitter.com/' + tweetDetails['links']['twitter_screen_name']}`);
        }
    };


    const addMetric = (metric: string) => {
        setMetrics(prevMetrics => [...prevMetrics, metric]);
        setAddedMetrics(prevAddedMetrics => [...prevAddedMetrics, metric]);
    };

    const removeMetric = (index: number) => {
        setMetrics(prevMetrics => prevMetrics.filter((_, i) => i !== index));
        setAddedMetrics(prevAddedMetrics => prevAddedMetrics.filter((_, i) => i !== index));
    };

    function formatDate(dateInput: any) {
        // Check if dateInput is an object with a $date property
        const dateString = typeof dateInput === 'object' && dateInput !== null ? dateInput.$date : dateInput;

        if (!dateString) return '';

        const date = new Date(dateString);
        return new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: 'short',
            day: '2-digit',
        }).format(date);
    }

    const convertToLocalTime = (isoDate: string) => {
        // Add 'Z' at the end of the ISO date to indicate UTC time
        isoDate = isoDate + 'Z';
        const date = parseISO(isoDate);
        return format(date, "h:mm a · MMM d, yyyy");
    };



    const handleTweetDetailsFetched = (details: any) => {
        setTweetDetails(details);
    };

    useEffect(() => {
        setParentGeneratedTweet('')
    }, [key]);

    useEffect(() => {
        get_tweet_history()
    }, []);

    const open_token_detail = (token: any) => {
        navigate("/token-detail/" + token);
    }

    const back_to_home = () => {
        navigate(-1);
    }
    return (
        <div className="container-fluid">
            <section className="first-section section-padding position-relative">
                <div className="container-fluid">
                    <Row>
                        <Col sm={12}>
                            <div className='back-btn' onClick={back_to_home}>
                                <Icon.ArrowLeft size={20}></Icon.ArrowLeft>  Back
                            </div>
                        </Col>
                        <Col className='mt-4' sm={12}>
                            <div className='d-flex'>
                                <div className='me-auto'>
                                    <div className="s-heading">
                                        Daily Tweets Summary
                                    </div>

                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className='mt-4'>
                        <div className={isMobile ? 'generate-tweet-container' : 'generate-tweet-container d-flex'}>
                            <div className='generate-tweet-col-left'>

                                <Tabs
                                    activeKey={key}
                                    onSelect={(k: any) => setKey(k)}
                                    id="uncontrolled-tab-example"
                                    className="mb-3 generate-tweet-tabs"
                                >
                                    <Tab eventKey="single-tweet" title="Single Tweet">

                                        <CreateTweetPage onGeneratedTweet={handleGeneratedTweet} onTweetDetailsFetched={handleTweetDetailsFetched} tweetType={'Single Tweet'} />


                                    </Tab>
                                    <Tab eventKey="long-tweet" title="Long Tweet">
                                        <CreateTweetPage onGeneratedTweet={handleGeneratedTweet} onTweetDetailsFetched={handleTweetDetailsFetched} tweetType={'Long Tweet'} />
                                    </Tab>
                                    <Tab eventKey="tweet-thread" title="Tweet Thread">
                                        <CreateTweetPage onGeneratedTweet={handleGeneratedTweet} onTweetDetailsFetched={handleTweetDetailsFetched} tweetType={'Thread Tweet'} />
                                    </Tab>
                                </Tabs>
                            </div>
                            <div className='generate-tweet-col-right'>
                                <div className='generated-tweet-text-container'>
                                    {parentGeneratedTweet == '' &&
                                        <div className='generate-tweet-placeholder'>
                                            Choose Timeframe and Tweet Sentiment to Generate Tweet
                                        </div>
                                    }
                                    {parentGeneratedTweet != '' &&
                                        <div className='generated-tweet-text-box'>
                                            <TypingEffect message={parentGeneratedTweet} tweetType={key} />
                                            <div className='metrics'>
                                                {metrics.map((metric, index) => (
                                                    <div key={index} className="metric">
                                                        {metric}
                                                        <Icon.XCircle className='metric-close-icon' onClick={() => removeMetric(index)}>Remove</Icon.XCircle>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className='d-flex mt-3'>
                                                <div>
                                                    <img className='cursor-pointer' src={copy_icon_img} onClick={handleCopyClick}
                                                        alt='Copy to Clipboard' />
                                                </div>
                                                <div className='share-tweet-btn ml-5 cursor-pointer' onClick={handleShareClick}>
                                                    <img src={share_icon_img} /> Share as Tweet
                                                </div>
                                            </div>
                                            <div className='d-flex mt-3'>
                                                {tweetDetails['dexscreen_url'] && !addedMetrics.includes(tweetDetails['dexscreen_url']) && (
                                                    <div className='share-tweet-btn cursor-pointer' onClick={() => addMetric(tweetDetails['dexscreen_url'])}>
                                                        Dexscreen URL
                                                    </div>
                                                )}
                                                {tweetDetails['coingecko_url'] && !addedMetrics.includes(tweetDetails['coingecko_url']) && (
                                                    <div className='share-tweet-btn cursor-pointer' onClick={() => addMetric(tweetDetails['coingecko_url'])}>
                                                        Coingecko URL
                                                    </div>
                                                )}
                                                {tweetDetails['token_sniffer_url'] && !addedMetrics.includes(tweetDetails['token_sniffer_url']) && (
                                                    <div className='share-tweet-btn cursor-pointer' onClick={() => addMetric(tweetDetails['token_sniffer_url'])}>
                                                        Token Sniffer URL
                                                    </div>
                                                )}
                                                {tweetDetails['token_address'] && !addedMetrics.includes(tweetDetails['token_address']) && (
                                                    <div className='share-tweet-btn cursor-pointer' onClick={() => addMetric(tweetDetails['token_address'])}>
                                                        Token Address
                                                    </div>
                                                )}
                                                {tweetDetails['links']['homepage'] && !addedMetrics.includes(tweetDetails['links']['homepage']) && (
                                                    <div className='share-tweet-btn cursor-pointer' onClick={() => addMetric(tweetDetails['links']['homepage'])}>
                                                        Webpage
                                                    </div>
                                                )}
                                                {tweetDetails['links']['twitter_screen_name'] && !addedMetrics.includes('https://twitter.com/' + tweetDetails['links']['twitter_screen_name']) && (
                                                    <div className='share-tweet-btn cursor-pointer' onClick={() => addMetric('https://twitter.com/' + tweetDetails['links']['twitter_screen_name'])}>
                                                        Twitter
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </Row>
                    <Row className='mt-5'>
                        <Col sm={12} className='d-flex'>
                            <div className="s-heading me-auto">
                                History
                            </div>
                        </Col>
                    </Row>
                    <Row className='mt-4'>
                        <Col sm={12}>
                            <div className={isMobile ? 'atw-table-container table-responsive-md' : 'atw-table-container'}>
                                <Table className='atw-table'>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Token</th>
                                            <th>Date</th>
                                            <th>Tweet Type</th>
                                            <th>Tweet Sentiment</th>
                                            <th>Tweet Content</th>
                                            <th>Cost</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tweetHistory.length > 0 ? (
                                            [...tweetHistory].reverse().map((tweets, index) => (
                                                <tr key={index}>

                                                    <td width={'5%'}>
                                                        {index + 1}
                                                    </td>
                                                    <td width={'15%'} className='cursor-pointer' onClick={() => open_token_detail(tweets.ticker)}>
                                                        <div className='d-flex align-items-center'>
                                                            {/* <div>
                                                                <img src={default_crypto_img} className="t-d-user-img" />
                                                            </div> */}
                                                            <div>
                                                                <div className="ms-1 ticker-name">{tweets.ticker}</div>
                                                                {/* <div className="ms-1 ticker-symbol">@{tokenTweets['user_username']}</div> */}
                                                            </div>
                                                        </div>


                                                    </td>

                                                    <td width={'15%'}>
                                                        {convertToLocalTime(tweets.created_at)}
                                                    </td>
                                                    <td width={'15%'}>
                                                        {tweets.tweet_type}
                                                    </td>
                                                    <td width={'20%'}>
                                                        {tweets.tone}
                                                    </td>
                                                    <td width={'20%'}>
                                                        <TweetContent content={tweets.tweet} />
                                                    </td>
                                                    <td width={'10%'}>
                                                        {tweets?.amount_deducted ? '$ ' + tweets?.amount_deducted : ''}
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            // Render this row if allTokenTweets is empty
                                            <tr>
                                                <td colSpan={7} style={{ textAlign: 'center' }}>No data available</td>
                                            </tr>
                                        )}

                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>
        </div>

    )
}

export default GenerateTweet;