import React, { useState, useRef, useEffect } from 'react';
import { Row, Col, Card, Form, Table, Spinner, OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import TaskCreateModal from './TaskCreateModal';
import toast, { Toaster } from 'react-hot-toast';
import { useAccount } from 'wagmi'

interface SelectTaskTypeOption {
    value: string;
    label: string;
}

interface TaskTypeFormData {
    name: string;
    greetings: string[];
    task_name: string;
    task_type: string;
    keywords: string[];
}

interface Tasks {
    wallet_id: string;
    title: string;
    categoryId: string;
    postPerDay: number;
    botIds: string[];
    customerGroupIds: string[];
    includeCustomerTagIds: string[];
    excludeCustomerTagIds: string[];
    type: string;
    templateContents: string[];
    images: string[];
    keywords: string[];
    created_on: string;
    id: string;
}


const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return isMobile;
};



const MMTTask: React.FC = () => {
    const { address, isConnected } = useAccount()
    const isMobile = useIsMobile();
    const [showTaskCreateModal, setShowTaskCreateModal] = useState(false);
    const [allTaskloader, setAllTaskloader] = useState(false);
    const [allTask, setAllTask] = useState<Tasks[]>([]);
    const [expandedTasks, setExpandedTasks] = useState<{ [key: number]: boolean }>({}); // Track expanded state for each task
    const maxTemplateContentLength = 1;
    const [taskTypeFormData, setTaskTypeFormData] = useState<TaskTypeFormData>({
        name: '',
        greetings: [],
        task_name: '',
        task_type: '',
        keywords: []
    });

    const handleCreateNewTaskClick = () => {
        setShowTaskCreateModal(true);
    };

    // Toggle the expansion for a specific task
    const toggleTemplateContentsContent = (index: number) => {
        setExpandedTasks(prevState => ({
            ...prevState,
            [index]: !prevState[index], // Toggle the specific task's expanded state
        }));
    };


    const get_all_tasks = async () => {
        setAllTaskloader(true);
        setAllTask([]);
        const walletIdParam = isConnected ? `&wallet_id=${address}` : '';

        const url = `${process.env.REACT_APP_API_URL}shill/tasks?wallet_id=${walletIdParam}`;

        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    // Include any other headers if needed
                }),
            });

            if (response.ok) {
                const data = await response.json();
                setAllTask(data['data'].reverse());
            } else {
                setAllTask([]);
            }
        } catch (e) {
            console.log(e);
            setAllTaskloader(false);
            setAllTask([]);
        } finally {
            setAllTaskloader(false);
        }
    };



    const get_task_type = async () => {
        // Implementation for fetching task types
    };



    const handleCreateTaskType = () => {
        // Implementation for creating a task type
    };

    const addNewTaskType = () => {
        // Implementation for adding a new task type
    };


    useEffect(() => {
        get_all_tasks()
    }, []);

    return (
        <div className="container-fluid">
            <Toaster />
            <section className="section-padding position-relative mt-4">
                <div className="container-fluid">

                    <Row>
                        <Col sm={12}>
                            <div className='me-auto'>
                                <div className="s-heading">
                                    Task List
                                </div>

                            </div>
                        </Col>
                        <Col sm={3} className="mt-3">
                            <div className='ctw-btn mt-2' onClick={() => handleCreateNewTaskClick()}>
                                Create New Task - Intelligent Replies
                            </div>
                        </Col>
                        <Col sm={12} className='mt-3'>

                            {allTaskloader &&
                                <Spinner animation="border" role="status" variant="light" >
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            }
                            {!allTaskloader &&
                                <>
                                    <div className={isMobile ? 'atw-table-container table-responsive-md' : 'atw-table-container'}>
                                        <Table className='atw-table'>
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Wallet Id</th>
                                                    <th>Title</th>
                                                    <th>Category Id</th>
                                                    <th>Post Per Day</th>
                                                    <th>Type</th>
                                                    <th>TemplateContents</th>
                                                    <th>keywords</th>
                                                    <th>Created On</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {allTask.length > 0 ? (
                                                    allTask.map((task, index) => (
                                                        <tr key={index} className={allTask.length === 1 ? 'custom-single-row-height' : ''}>
                                                            <td width={'2%'}>
                                                                <div className='d-flex align-items-center gap-2'>
                                                                    {index + 1}
                                                                </div>
                                                            </td>
                                                            <td width={'30%'} className='cursor-pointer'>
                                                                {task['wallet_id']}
                                                            </td>
                                                            <td width={'10%'}>{task['title']}</td>
                                                            <td width={'18%'}>
                                                                {task['categoryId']}
                                                            </td>
                                                            <td width={'10%'}>
                                                                {task['postPerDay']}
                                                            </td>
                                                            <td>
                                                                {
                                                                    task['type']
                                                                }
                                                            </td>

                                                            <td>

                                                                {
                                                                    task['templateContents'].length > maxTemplateContentLength && !expandedTasks[index]
                                                                        ? (
                                                                            <>
                                                                                {task['templateContents'].slice(0, maxTemplateContentLength)}...
                                                                                <span
                                                                                    onClick={() => toggleTemplateContentsContent(index)}
                                                                                    style={{ color: 'blue', cursor: 'pointer' }}
                                                                                >
                                                                                    Read More
                                                                                </span>
                                                                            </>
                                                                        )
                                                                        : (
                                                                            <>
                                                                                {task['templateContents']}
                                                                                {
                                                                                    task['templateContents'].length > maxTemplateContentLength && (
                                                                                        <span
                                                                                            onClick={() => toggleTemplateContentsContent(index)}
                                                                                            style={{ color: 'blue', cursor: 'pointer' }}
                                                                                        >
                                                                                            {expandedTasks[index] ? 'Read Less' : ''}
                                                                                        </span>
                                                                                    )
                                                                                }
                                                                            </>
                                                                        )
                                                                }
                                                            </td>


                                                            <td>
                                                                {
                                                                    task['keywords']
                                                                }
                                                            </td>
                                                            <td width={'10%'}>
                                                                {task['created_on']}
                                                            </td>

                                                        </tr>
                                                    ))
                                                ) : (
                                                    // Render this row if allTokenTweets is empty
                                                    <tr>
                                                        <td colSpan={7} style={{ textAlign: 'center' }}>No data available</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </Table>
                                    </div>                                 

                                </>}
                        </Col>
                    </Row>
                    {showTaskCreateModal &&
                        <TaskCreateModal></TaskCreateModal>
                    }
                </div>
            </section>
        </div>
    );
};

export default MMTTask;
