import { useState, useRef, useEffect } from 'react';
import { Row, Col, Card, Form, Table, Spinner } from "react-bootstrap";
import './my-feed.css'

export const MyFeed = () => {
    return (
        <div className="container-fluid">
            <section className="first-section section-padding position-relative">
                <div className="container-fluid">
                    <Row>
                        <Col sm={12}>
                            <div className='d-flex'>
                                <div className='me-auto'>
                                    <div className="s-heading">
                                        My Feed
                                    </div>

                                </div>
                            </div>
                            
                        </Col>
                    </Row>
                </div>
            </section>
        </div>
    )
}

export default MyFeed;